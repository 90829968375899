import {
  DirectoryDownloadConfigResponseBody,
  Path,
} from '@core/@models/DirectoryDownloadConfigModel';
import {
  DonwnloadConfigActions,
  DownloadConfigReducer,
} from '@reducers/DownloadConfigReducer';
import React, { useReducer } from 'react';

export type Dispatch = (action: DonwnloadConfigActions) => void;
export interface DownloadConfigContextData {
  data: DirectoryDownloadConfigResponseBody;
  deletedData: Path[];
  path: string;
  loading: boolean;
}
export type DownloadConfigContextProps = {
  state: DownloadConfigContextData;
  dispatch: Dispatch;
};

export const DownloadConfigContext = React.createContext<
  DownloadConfigContextProps | undefined
>(undefined);

export const useDownloadConfigContext = (): DownloadConfigContextProps => {
  const downloadConfigContext = React.useContext(DownloadConfigContext);
  if (!downloadConfigContext) {
    throw new Error(
      'useDownloadConfigContext must be used within the DownloadConfigContext.Provider'
    );
  }
  return downloadConfigContext;
};
const initailState = {
  data: {
    loginName: '',
    configName: '',
    roleId: undefined,
    roleName: '',
    localPath: '',
    paths: [],
  },
  deletedData: [],
  path: '',
  loading: true,
};

export const DownloadConfigProver: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(DownloadConfigReducer, initailState);
  const value = { state, dispatch };

  return (
    <DownloadConfigContext.Provider value={value}>
      {children}
    </DownloadConfigContext.Provider>
  );
};
