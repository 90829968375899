import { DownloadConfigContextData } from '@contexts/DownloadConfigProverder';
import {
  DirectoryDownloadConfig,
  DirectoryDownloadConfigResponseBody,
  Path,
} from '@core/@models/DirectoryDownloadConfigModel';

import { ActionMap } from './PermissionReducer';
export enum Types {
  AddPath = 'ADD_PATH',
  Delete = 'DELETE_PATH',
  InitialData = 'INITIAL_DATA',
  AddForm = 'ADD_FORM',
  SelectPath = 'SELECT_PATH',
  SetLoading = 'SET_LOADING',
}

type DonwnloadConfigPayload = {
  [Types.AddPath]: {
    path: Path;
  };
  [Types.Delete]: {
    index: number;
  };
  [Types.InitialData]: {
    data: DirectoryDownloadConfigResponseBody;
    loading: boolean;
  };
  [Types.AddForm]: {
    data: DirectoryDownloadConfig;
  };
  [Types.SelectPath]: {
    path: string;
  };
  [Types.SetLoading]: {
    loading: boolean;
  };
};
export type DonwnloadConfigActions = ActionMap<DonwnloadConfigPayload>[keyof ActionMap<DonwnloadConfigPayload>];

export const DownloadConfigReducer = (
  state: DownloadConfigContextData,
  action: DonwnloadConfigActions
): DownloadConfigContextData => {
  const { data } = state;
  switch (action.type) {
    case Types.AddPath: {
      const paths = [...data.paths, action.payload.path];
      const result = Object.assign({}, data, { paths });
      return { ...state, data: result };
    }

    case Types.Delete: {
      const paths = [...data.paths];
      if (paths[action.payload.index].id) {
        //ใส่ flag delete
        paths[action.payload.index].delete = true;
        const deletedData = [...state.deletedData, paths[action.payload.index]];

        const filterPaths = paths.filter((v) => !v.delete);
        const result = Object.assign({}, data, { paths: filterPaths });
        return { ...state, data: result, deletedData };
      }
      // ลบออกจาก array
      paths.splice(action.payload.index, 1);
      const result = Object.assign({}, data, { paths });
      return { ...state, data: result };
    }

    case Types.InitialData:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
      };

    case Types.AddForm: {
      const configData = action.payload.data;
      const result = Object.assign({}, data, configData);
      console.log('add form', result);
      return { ...state, data: result };
    }

    case Types.SelectPath: {
      return { ...state, path: action.payload.path };
    }
    case Types.SetLoading: {
      return { ...state, loading: action.payload.loading };
    }

    default:
      return state;
  }
};
