import {
  DeleteOutlined,
  FolderAddOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Action,
  DirectoryServiceParam,
  DirectoryServiceResponseBody,
  FolderType,
  IDirectoryList,
  IDirectoryTree,
} from '@core/@models/DirectoryServiceModel';
import { LanguageService } from '@core/services/language.service';
import { useDirectoryDataFetchingPagination } from '@helpers/use-directory-data-fetching-pagination';
import { convertToDateTimeShort } from '@helpers/utils';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Key, SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmModal, ModalCmp } from '@components/ModalCmp';
import { useDropdownContext } from '@contexts/DropdownProvider';
import { useSearchContext } from '@contexts/SearchProvider';
import { CustomService } from '@core/services/custom.service';
import { useModalManagement } from '@helpers/use-modal-management';
import { useSearch } from '@helpers/use-search';
import { CheckboxProps } from 'antd/lib/checkbox';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Subject } from 'rxjs';
import { actions } from './ActionMenu/DropDownToggle';
import { FolderForm } from './ActionMenu/FolderForm';
import { UploadFileForm } from './ActionMenu/UploadFileForm';
import { direct$, DirectoryTreeSubject, subject$ } from './DirectoryTreePage';
import { MoreButton } from './MoreButton';
import { NotificationType, useNotification } from '@helpers/use-notification';
import { finalize } from 'rxjs/operators';

const { RangePicker } = DatePicker;

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const DirectoryListSubject$ = new Subject<{
  folderId: Key;
  key: Key;
  fetchFolder?: boolean;
}>();

export const DirectoryList: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { open } = useNotification();
  const [fileLoading, setFileLoading] = useState(false);
  const lang = LanguageService.getLanguage();
  const [id, setId] = useState<number | null>(null);
  const [folderType, setFolderType] = useState<FolderType>('NORMAL');
  const [actionPermission, setActionPermission] = useState<string[]>([]);
  const [subFolders, setSubFolders] = useState<IDirectoryTree[]>([]);
  const [selectedAction, setSelectedAction] = useState<Action | undefined>();
  const [dataTable, setDataTable] = useState<DirectoryServiceResponseBody[]>(
    []
  );
  const { visible, hideModal, showModal } = useModalManagement();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { handleQueryParam, queryParam } = useSearchContext();

  const {
    data,
    loading,
    pagination,
    sortOrder,
    handleTableChange,
    setOffset,
    resetTableConfig,
    resetCurrentPage,
  } = useDirectoryDataFetchingPagination(queryParam, '/file-services/select');

  const {
    handleSearchChange,
    handleDateChange,
    handleSearch,
    handleReset,
    search,
    date,
  } = useSearch();

  const relativeKeyRef = useRef<React.Key>('');
  const parentIdRef = useRef<React.Key>('');
  const selectedRowKeysRef = useRef<Key[]>([]);
  const { setItem } = useDropdownContext();

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const fileId = urlSearchParams.get('fileId') || '';

  useEffect(() => {
    const subsription = direct$.subscribe({
      next: (val: string | undefined) => {
        if (!val) return;
        setSelectedRowKeys([+fileId]);
      },
    });
    return () => subsription.unsubscribe();
  }, [fileId]);

  useEffect(() => {
    const subsription = subject$.subscribe({
      next: ({
        folderId,
        folders,
        key,
        folderType,
        actions,
      }: DirectoryTreeSubject) => {
        relativeKeyRef.current = key;
        parentIdRef.current = `${key}`.split('-').pop() || '';
        handleReset();
        resetTableConfig();

        setId(folderId as number);
        setFolderType(folderType);
        setActionPermission(actions);
        setSubFolders(folders);
        setOffset(folders.length);

        handleQueryParam({ folderId: folderId as number });
      },
    });

    return () => subsription.unsubscribe();
  }, []);

  useEffect(() => {
    setDataTable(convertDataTable(subFolders));
  }, [data]);

  const getFileNameView = (record: any): React.ReactElement => (
    <>
      <img
        style={{ marginRight: '10px' }}
        height={20}
        src={
          record.link
            ? `${process.env.PUBLIC_URL}/images/icon/chrome.svg`
            : record.folderType !== undefined
            ? `${process.env.PUBLIC_URL}/images/icon/folder.svg`
            : process.env.PUBLIC_URL +
              `/images/icon/${record.name.substr(
                record.name.lastIndexOf('.') + 1,
                record.name.length
              )}.svg`
        }
        onError={(e: any) =>
          e.target.setAttribute(
            'src',
            `${process.env.PUBLIC_URL}/images/icon/unknown.svg`
          )
        }
      />
      {record.name}
    </>
  );

  const getDateModifiedView = (value: string): React.ReactElement => (
    <>{convertToDateTimeShort(value, lang)}</>
  );

  const getDropDownToggleView = (record: {
    ref: HTMLButtonElement | null;
    data: DirectoryServiceResponseBody;
  }) => {
    const relativeKey = `${relativeKeyRef.current}`;
    const { id, parentId, link, name, actions } = record.data as IDirectoryList;
    const data = {
      id,
      actions,
      parentId,
      link,
      name,
      relativeKey,
      fetchData: forceFetchData,
      handleDownloadFile: downloadFile,
    };
    const result = Object.assign({}, record, { data });
    setItem(result);
  };

  const getSortParam = (fieldName: string): string => {
    if (!queryParam.sort) return `${fieldName},${SortDirection.ASC}`;
    const [field, sortDirection] = queryParam.sort.split(',');
    // ถ้ากด sort ที่ column เดิม และมี sortDirection เป็น asc จะเปลี่ยน sort param เป็น desc
    // และถ้ากด sort ที่ column เดิม แต่มี sortDirection เป็น desc จะเปลี่ยน sort param เป็น asc
    // และถ้ากด sort โดยเปลี่ยน column จะเปลี่ยน sort param เป็น asc ก่อนเสมอ
    return field === fieldName && sortDirection === SortDirection.ASC
      ? `${fieldName},${SortDirection.DESC}`
      : `${fieldName},${SortDirection.ASC}`;
  };

  const columns: ColumnsType<DirectoryServiceResponseBody> = [
    {
      title: t('name'),
      dataIndex: 'name',
      width: '40%',
      render: (value, record) => getFileNameView(record),
      sorter: true,
      sortOrder: sortOrder.field === 'name' ? sortOrder.order : null,
      onHeaderCell: () => {
        return {
          onClick: () => {
            handleQueryParam({ folderId: id, sort: getSortParam('name') });
          },
        };
      },
    },
    {
      title: t('description'),
      dataIndex: 'description',
      width: '25%',
      sorter: true,
      sortOrder: sortOrder.field === 'description' ? sortOrder.order : null,
      onHeaderCell: () => {
        return {
          onClick: () => {
            handleQueryParam({
              folderId: id,
              sort: getSortParam('description'),
            });
          },
        };
      },
    },
    {
      title: t('modifiedDate'),
      dataIndex: 'modified',
      width: '14%',
      render: getDateModifiedView,
      sorter: true,
      sortOrder: sortOrder.field === 'modified' ? sortOrder.order : null,
      onHeaderCell: () => {
        return {
          onClick: () => {
            handleQueryParam({
              folderId: id,
              sort: getSortParam('modifyDate'),
            });
          },
        };
      },
    },
    {
      title: t('size'),
      dataIndex: 'size',
      width: '12%',
    },
    {
      className: 'last-column',
      width: '5%',
      render: (_, record: any) => {
        if (record.folderType === undefined)
          return <MoreButton {...record} onClick={getDropDownToggleView} />;
      },
    },
  ];

  const rowSelection: TableRowSelection<DirectoryServiceResponseBody> = {
    columnWidth: '46px',
    selectedRowKeys,
    onChange: (selectedRowKeys: Key[]) => {
      selectedRowKeysRef.current = [...selectedRowKeys];
      console.log('selectedRowKeys', selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (
      record: DirectoryServiceResponseBody
    ): Partial<Omit<CheckboxProps, 'checked' | 'defaultChecked'>> => {
      const { folderType } = record as IDirectoryList;
      const isFolder = folderType !== undefined;
      const canNotDelete = !record.actions.includes('delete');
      const isDisabled = isFolder || canNotDelete;
      return {
        disabled: isDisabled,
      };
    },
  };

  const handleDelete = () => {
    const onDeleteFile = () => {
      const multipleId = selectedRowKeysRef.current.join(',');
      const folderId = parentIdRef.current as number;
      CustomService.deleteData(`/file-services/file/${multipleId}`).subscribe({
        next: () => {
          forceFetchData({ folderId, key: relativeKeyRef.current });
        },
      });
    };
    if (selectedRowKeysRef.current.length > 0)
      ConfirmModal({ onOk: onDeleteFile });
  };

  const handleSelectRow = (record: any) => {
    return {
      onClick: () => {
        if (record.link) {
          window.open(record.link, '_blank');
        } else if (record.size === '') {
          DirectoryListSubject$.next({ folderId: record.id, key: record.key });
        } else {
          downloadFile(record.id, record.name);
        }
      },
    };
  };

  const handleCalendarChange = (
    values: any,
    formatString: [string, string]
  ) => {
    const [begin, end] = formatString;
    if ((begin && end) || (!begin && !end)) {
      resetCurrentPage();
      handleDateChange(values, formatString);
      handleQueryParam({ begin: begin, end: end });
    }
  };

  const filterByNameDescription = (
    folders: DirectoryServiceResponseBody[],
    q: DirectoryServiceParam
  ): DirectoryServiceResponseBody[] => {
    if (!q.search || !q.search.trim()) return folders;
    const invalidCharacters = ['.', '?', '+', '(', ')', '/'];
    let temp = q.search;
    for (let i = 0; i < q.search.length; i++) {
      if (invalidCharacters.includes(q.search.charAt(i))) {
        temp = temp.replaceAll(q.search.charAt(i), `\\${q.search.charAt(i)}`);
      }
    }
    let regEx: any;
    try {
      regEx = new RegExp(`^${temp.replaceAll('*', '.*')}$`);
      return (folders = folders.filter((d) => {
        return (
          regEx.test(d.name.toLowerCase()) ||
          regEx.test(d.description.toLowerCase())
        );
      }));
    } catch (error) {
      return folders;
    }
  };

  const filterByModifyDate = (
    folders: DirectoryServiceResponseBody[],
    q: DirectoryServiceParam
  ): DirectoryServiceResponseBody[] => {
    if (!q.begin || !q.end) return folders;
    return (folders = folders.filter(
      (d) =>
        dayjs(d.modified).isAfter(dayjs(`${q.begin}`).startOf('days')) &&
        dayjs(d.modified).isBefore(dayjs(`${q.end}`).endOf('days'))
    ));
  };

  const sortByOrder = (
    folders: any[],
    q: DirectoryServiceParam
  ): DirectoryServiceResponseBody[] => {
    if (!q.sort) return folders;
    const [fieldName, sortDirection] = q.sort.split(',');
    const r = sortDirection === SortDirection.ASC ? 1 : -1;
    if (fieldName === 'modifyDate') {
      folders.sort((a, b) =>
        dayjs(a.modified).isAfter(dayjs(b.modified))
          ? 1 * r
          : dayjs(b.modified).isAfter(dayjs(a.modified))
          ? -1 * r
          : 0
      );
    } else {
      folders.sort((a, b) =>
        a[fieldName].toLowerCase() > b[fieldName].toLowerCase()
          ? 1 * r
          : b[fieldName].toLowerCase() > a[fieldName].toLowerCase()
          ? -1 * r
          : 0
      );
    }
    return folders;
  };

  const removeChildren = (
    folders: DirectoryServiceResponseBody[]
  ): DirectoryServiceResponseBody[] => {
    return folders.map((f: any) => {
      delete f.children;
      return f;
    });
  };

  const convertDataTable = (folders: any[]): DirectoryServiceResponseBody[] => {
    if (pagination.current === undefined) return [];
    const page = pagination.current;

    folders = filterByNameDescription(folders, queryParam);
    folders = filterByModifyDate(folders, queryParam);
    folders = sortByOrder(folders, queryParam);
    folders = removeChildren(folders);
    setOffset(folders.length);

    if (page * 15 <= folders.length) {
      return folders.slice((page - 1) * 15, page * 15);
    } else if ((page - 1) * 15 <= folders.length) {
      return folders
        .slice((page - 1) * 15, folders.length)
        .concat(data.slice(0, page * 15 - folders.length));
    } else {
      const index = 15 * Math.ceil(folders.length / 15) - folders.length;
      return data.slice(index, index + 15);
    }
  };

  const forceFetchData = ({
    folderId,
    key,
  }: {
    folderId: number;
    key: Key;
  }) => {
    DirectoryListSubject$.next({
      folderId: folderId ? folderId : id ? id : '',
      key: key,
      fetchFolder: selectedAction === Action.CreateFolder,
    });
  };

  const downloadFile = (id: number, link: string) => {
    setFileLoading(true);
    CustomService.downloadFile(`/file-services/download/${id}`)
      .pipe(finalize(() => setFileLoading(false)))
      .subscribe({
        next: (result: Blob) => {
          const blob = new Blob([result]);
          FileSaver.saveAs(blob, link);
        },
        error: () => {
          open({
            type: NotificationType.ERROR,
            description: t('common:downloadFailed'),
            disableDate: true,
          });
        },
      });
  };

  const setButton = (
    icon: ReactElement,
    act: Action,
    func: () => void,
    tooltip: string
  ) => {
    const actionIds = actionPermission.map((a) => actions[a].id);
    return (
      <Space style={{ marginTop: '4px' }}>
        <Tooltip title={t(`actions.${tooltip}`)}>
          <Button
            icon={icon}
            data-testid={`${act}-button`}
            disabled={!id || !actionIds.includes(act)}
            type="link"
            size="small"
            onClick={() => {
              setSelectedAction(act);
              func();
            }}
          />
        </Tooltip>
      </Space>
    );
  };

  const onHideModal = () => {
    hideModal();
    setSelectedAction(undefined);
  };

  return (
    <div className="directory-list">
      <Card bordered={false} className="search-form">
        <Row>
          <Col flex="65">
            <Space
              style={{
                marginTop: '3px',
                color: 'rgba(0, 0, 0, 0.4)',
                fontWeight: 'bold',
              }}
            >
              {t('files')}
            </Space>
          </Col>
          <Col flex="20">
            <Input.Search
              data-testid={'name-desc-search-input'}
              size="small"
              style={{ width: '200px', marginRight: '6px', marginTop: '3px' }}
              placeholder={`${t('button.search')}...`}
              onChange={handleSearchChange}
              onSearch={() => {
                if (search || (!search && queryParam.search)) {
                  resetCurrentPage();
                  handleSearch();
                }
              }}
              value={search}
            />
          </Col>
          <Col flex="20">
            <RangePicker
              data-testid={'modified-date-picker'}
              size="small"
              style={{ width: '240px', marginRight: '6px', marginTop: '3px' }}
              placeholder={[`${t('begin')}...`, `${t('end')}...`]}
              ranges={{
                Today: [moment(), moment()],
              }}
              onChange={handleCalendarChange}
              onCalendarChange={handleCalendarChange}
              value={date}
            />
          </Col>
          {setButton(
            <UploadOutlined />,
            Action.UploadFile,
            showModal,
            'uploadFile'
          )}
          {setButton(
            <FolderAddOutlined />,
            Action.CreateFolder,
            showModal,
            'create folder'
          )}
          {setButton(
            <DeleteOutlined />,
            Action.DeleteMultipleFiles,
            handleDelete,
            'delete'
          )}
        </Row>
      </Card>
      {id && (
        <>
          <Table
            sortDirections={['ascend', 'descend', 'ascend']}
            className="selectable-table no-padding"
            data-testid="directory-table"
            size="small"
            scroll={{ y: 500 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataTable}
            loading={loading || fileLoading}
            pagination={pagination}
            onRow={handleSelectRow}
            onChange={(p, _, s) =>
              handleTableChange(
                p,
                s as SorterResult<DirectoryServiceResponseBody>
              )
            }
          />
          <ModalCmp
            visible={visible}
            title={
              selectedAction === Action.UploadFile
                ? t('actions.uploadFile')
                : t('actions.create folder')
            }
            handleCancel={onHideModal}
            width={600}
          >
            {selectedAction === Action.UploadFile && (
              <UploadFileForm
                parentId={id}
                relativeKey={`${relativeKeyRef.current}`}
                handleCancel={onHideModal}
                fetchData={forceFetchData}
              />
            )}
            {selectedAction === Action.CreateFolder && (
              <FolderForm
                isDynamicFolder={
                  !!actionPermission.find((v) =>
                    ['create_dynamic', 'edit_dynamic'].includes(v)
                  )
                }
                parentId={id}
                relativeKey={`${relativeKeyRef.current}`}
                handleCancel={onHideModal}
                fetchData={forceFetchData}
                folderType={folderType}
              />
            )}
          </ModalCmp>
        </>
      )}
      {!id && (
        <Row style={{ height: '500px' }}>
          <span>{t('pleaseChooseFolder')}</span>
        </Row>
      )}
    </div>
  );
};
