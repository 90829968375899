import { useSearchContext } from '@contexts/SearchProvider';
import {
  DeleteMultipleFilesParam,
  DirectoryServiceResponseBody,
} from '@core/@models/DirectoryServiceModel';
import { ApiService } from '@core/services/api.service';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { finalize } from 'rxjs/operators';

export interface FetchingDataPagination {
  data: DirectoryServiceResponseBody[] | undefined;
  loading: boolean;
  fetchData: (queryParam: DeleteMultipleFilesParam) => void;
  setData: Dispatch<SetStateAction<DirectoryServiceResponseBody[] | undefined>>;
}

export const useMultiDeleteDataFetching = (
  url: string
): FetchingDataPagination => {
  const apiService = React.useMemo(() => new ApiService(url), []);
  const [data, setData] = useState<
    DirectoryServiceResponseBody[] | undefined
  >();
  const { queryParam } = useSearchContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!queryParam.name) return;
    fetchData();
  }, [queryParam]);

  const fetchData = () => {
    setLoading(true);
    apiService
      .getDataPagable<DirectoryServiceResponseBody[]>(queryParam)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DirectoryServiceResponseBody[]) => {
          setData([...result]);
        },
      });
  };
  return { data, loading, fetchData, setData };
};
