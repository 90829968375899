import { CalendarOutlined, FileTextOutlined } from '@ant-design/icons';
import { ModalCmp } from '@components/ModalCmp';
import {
  AttentionViewBody,
  AttentionViewResponse,
} from '@core/@models/AttentionModel';
import { CustomService } from '@core/services/custom.service';
import { LanguageService } from '@core/services/language.service';
import { convertToDateShort } from '@helpers/utils';
import { Button, Card, Col, List, Row, Tag } from 'antd';
import { AttentionPreview } from 'pages/attention/AttentionPreview';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const prop = {
  gutter: 16,
  column: 4,
};

export const AttentionView: React.FC = () => {
  const { t } = useTranslation();
  const [viewVisible, setViewVisible] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const handleClick = (reqId: number | null) => setId(reqId);
  const lang = LanguageService.getLanguage();
  const [publicAttention, setPublicAttention] = useState<AttentionViewBody[]>(
    []
  );
  const [memberAttention, setMemberAttention] = useState<AttentionViewBody[]>(
    []
  );

  const isExistsData = publicAttention.length > 1 || memberAttention.length > 1;
  useEffect(() => {
    let mounted = true;
    if (isExistsData) return;
    if (mounted) {
      getData();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const showViewModal = () => setViewVisible(true);
  const hideViewModal = () => setViewVisible(false);

  const getData = () => {
    CustomService.getData<AttentionViewResponse>(`/attentions/view`).subscribe({
      next: (result: AttentionViewResponse) => {
        if (!result) return;
        setPublicAttention(result.forPublic);
        setMemberAttention(result.forMember);
      },
    });
  };

  const setColumn = (
    header: string,
    dataSource: AttentionViewBody[],
    paddingRight?: number
  ): React.ReactElement => (
    <Col span={12} style={{ paddingRight: paddingRight }}>
      <Card style={{ borderRadius: 8 }} bodyStyle={{ padding: 20 }}>
        <List
          {...prop}
          header={
            <div style={{ display: 'inline-flex' }}>
              <FileTextOutlined
                style={{ marginRight: '5px', marginTop: '5px' }}
              />
              <h3 style={{ fontWeight: 600 }}>{header}</h3>
            </div>
          }
          itemLayout="horizontal"
          dataSource={dataSource}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
            showTotal: (total) => t('common:totalItem', { total }),
          }}
          size="small"
          renderItem={(item) => (
            <div
              onClick={() => {
                handleClick(item.id);
                showViewModal();
              }}
            >
              <List.Item>
                <List.Item.Meta
                  title={item.headLine}
                  description={
                    <>
                      <CalendarOutlined style={{ marginRight: '5px' }} />
                      <span style={{ fontSize: '12px' }}>
                        {convertToDateShort(item.publishBegin, lang)}
                      </span>
                      <Tag
                        color="red"
                        visible={item.new}
                        style={{ marginLeft: '10px', fontWeight: 700 }}
                      >
                        NEW !!
                      </Tag>
                    </>
                  }
                />
              </List.Item>
            </div>
          )}
        />
      </Card>
    </Col>
  );

  return (
    <>
      <Row>
        {setColumn(t('common:attentionForAllUsers'), publicAttention, 10)}
        {setColumn(t('common:attentionForMember'), memberAttention)}
      </Row>
      <ModalCmp
        visible={viewVisible}
        title={t('common:preview')}
        width={1000}
        handleCancel={hideViewModal}
      >
        <AttentionPreview id={id}>
          <Row className="button-set">
            <Button
              style={{ width: '80px' }}
              type="primary"
              ghost
              htmlType="button"
              onClick={hideViewModal}
            >
              {t('common:button.close')}
            </Button>
          </Row>
        </AttentionPreview>
      </ModalCmp>
    </>
  );
};
