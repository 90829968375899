import { DeleteOutlined } from '@ant-design/icons';
import { ButtonAdd } from '@components/ButtonAdd';
import { ConfirmModal } from '@components/ModalCmp';
import { useAuthContext } from '@contexts/AuthProvider';
import { DirectoryDownloadConfigResponseBody } from '@core/@models/DirectoryDownloadConfigModel';
import { DataResponseBody } from '@core/@models/Types';
import { CustomService } from '@core/services/custom.service';
import { useDataFetchingPagination } from '@helpers/use-data-fetching-pagination';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ButtonProps } from 'antd/lib/button';
import { customLayoutBtnAdd } from 'pages/download-config/DownloadConfigList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const CONFIG_DOWNLOAD_FORM = '/directory-download-config/form';

export const ConfigList = <
  T extends DataResponseBody
>(): React.ReactElement => {
  const { isReadOnly } = useAuthContext();
  const { t } = useTranslation();
  const {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  } = useDataFetchingPagination('/directory-download-services/config/list');
  const history = useHistory();

  const showConfirmModal = (configName: string) =>
    ConfirmModal({ onOk: () => onDelete(configName) });

  const onDelete = (configName: string) => {
    CustomService.deleteData(
      `/directory-download-services/config?configName=${configName}`
    ).subscribe({
      next: () => fetchData(),
    });
  };

  const getPathView = (
    record: DirectoryDownloadConfigResponseBody
  ): React.ReactElement => (
    <>
      {record.paths.map((p) => (
        <p key={p.path} style={{ margin: '0px' }}>
          {p.path}
        </p>
      ))}
    </>
  );

  const getDeleteButtonView = (
    record: DataResponseBody
  ): React.ReactElement => (
    <Tooltip title={t('common:button.delete')}>
      <Button
        type="link"
        style={{ height: '20px' }}
        icon={<DeleteOutlined />}
        size="small"
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          event.stopPropagation();
          const r = record as DirectoryDownloadConfigResponseBody;
          showConfirmModal(r.configName);
        }}
        disabled={isReadOnly}
      />
    </Tooltip>
  );

  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('common:loginName'),
      dataIndex: 'loginName',
      width: '10%',
    },
    {
      title: t('common:role'),
      dataIndex: 'roleName',
      width: '12%',
    },
    {
      title: t('common:configName'),
      dataIndex: 'configName',
      width: '20%',
    },
    {
      title: t('common:localPath'),
      dataIndex: 'localPath',
      width: '26%',
    },
    {
      title: t('common:path'),
      render: (_, record: DataResponseBody) =>
        getPathView(record as DirectoryDownloadConfigResponseBody),
      width: '26%',
    },
    {
      title: 'Action',
      width: '6%',
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  const handleSelectRow = (record: DataResponseBody) => {
    return {
      onClick: () => {
        const r = record as DirectoryDownloadConfigResponseBody;
        goToForm(`${r.configName}`);
      },
    };
  };

  const goToForm = (path: string) => {
    history.push(`${CONFIG_DOWNLOAD_FORM}/${path}`);
  };

  const buttonProps: ButtonProps = {
    onClick: () => goToForm(''),
    disabled: isReadOnly,
  };

  return (
    <div
      style={
        isReadOnly
          ? undefined
          : { backgroundColor: 'white', borderRadius: '8px' }
      }
    >
      <div style={isReadOnly ? undefined : { padding: '10px 10px 0px' }}>
        <ButtonAdd
          buttonProps={buttonProps}
          layoutBtnAdd={isReadOnly ? undefined : customLayoutBtnAdd}
        />
      </div>
      <Table
        size="small"
        className={isReadOnly ? 'unselectable-table' : 'selectable-table'}
        pagination={pagination}
        onRow={isReadOnly ? undefined : handleSelectRow}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};
