import { Button } from 'antd';
import { DataNode } from 'antd/lib/tree';
import React, { useRef } from 'react';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type MoreButtonProps = DataNode & {
  onClick?: (item: { ref: HTMLButtonElement | null; data: DataNode }) => void;
};

export const MoreButton = React.memo<MoreButtonProps>(
  ({ onClick, ...props }) => {
    const ref = useRef<HTMLButtonElement>(null);
    const onHandleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation();
      onClick && onClick({ ref: ref.current, data: props });
    };
    return (
      <span>
        <MoreBtn
          role="more-btn"
          type="link"
          ref={ref}
          onClick={onHandleClick}
          icon={<EditOutlined />}
          name="more-btn"
        />
      </span>
    );
  },
  () => true
);

MoreButton.displayName = 'MoreButton';

const MoreBtn = styled(Button)`
  float: right;
  opacity: 1;
  padding: 0;
  margin: 0;
  padding-bottom: 2px;
  transition: font-size 0.3s;
  & svg {
    font-size: 15px;
    font-weight: 800;
  }
`;
