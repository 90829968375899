import { ButtonSet } from '@components/ButtonSet';
import {
  convertDataType,
  modifiedData,
  renderName,
  usePermissionContext,
} from '@contexts/PermissionProvider';
import {
  ColumsField,
  DirectoryPermissionRequest,
  DirectoryPermissionResponse,
  OptionType,
  PermissionTable,
  PermisssionOf,
} from '@core/@models/DirectoryPermissionModel';
import { CustomService } from '@core/services/custom.service';
import { Types } from '@reducers/PermissionReducer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
import { FooterForm } from '../Permissions/FooterForm';
import { PermissionForm } from './PermissionForm';
import { RootFooterForm } from './RootFooterForm';

interface PermissionConfigData {
  users: OptionType[];
  roles: OptionType[];
  companies: OptionType[];
}

const defaultConfigData = {
  users: [],
  permissionTypes: [],
  roles: [],
  companies: [],
};

interface PermissionProps {
  handleCancel: () => void;
  id?: React.Key;
  isRoot?: boolean;
}

export const Permissions: React.FC<PermissionProps> = ({ id, ...props }) => {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [configData, setConfigData] = useState<PermissionConfigData>(
    defaultConfigData
  );
  const { state, dispatch } = usePermissionContext();
  const path = props.isRoot
    ? `/file-services/root/${id}/permissions`
    : `/file-services/folder/${id}/permissions`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    CustomService.getData<DirectoryPermissionResponse>(path)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DirectoryPermissionResponse) => {
          initialConfigData(result);
          initialData(result);
        },
      });
  };

  const initialConfigData = (result: DirectoryPermissionResponse) => {
    const {
      users: userList = [],
      companies: companyList = [],
      roles: roleList = [],
    } = result;
    const usersForm = userList.map((u) => ({
      label: `${u.loginName} - ${u.nameTh} ${u.surnameTh}`,
      value: `${u.id}`,
    }));
    const companiesForm = companyList.map((c) => ({
      label: `${c.code} - ${c.nameTh}`,
      value: `${c.id}`,
    }));
    const rolesForm = roleList.map((r) => ({
      label: r.name,
      value: `${r.id}`,
    }));
    setConfigData({
      roles: rolesForm,
      users: usersForm,
      companies: companiesForm,
    });
  };

  const initialData = (result: DirectoryPermissionResponse) => {
    const {
      userPermissions: u = [],
      ownerPermissions: o = [],
      companyPermissions: c = [],
      rolePermissions: r = [],
      permissionTypes: permissionList = [],
      companyRoles: companyRoleList = [],
      roles: roleList = [],
    } = result;

    const permissionTypes = permissionList.map((p: string) => ({
      label: t(`${p}`),
      value: p,
    }));
    const companyRoles = companyRoleList.map((cr) => ({
      label: cr.value,
      value: `${cr.key}`,
    }));
    const rolesForm = roleList.map((ro) => ({
      label: ro.name,
      value: `${ro.id}`,
    }));
    const permissionTable: PermissionTable = {
      owners: convertDataType(
        o.map((v) => ({ ...v, permissionOf: PermisssionOf.Users }))
      ),
      users: convertDataType(
        u.map((v) => ({ ...v, permissionOf: PermisssionOf.Users }))
      ),
      companies: convertDataType(
        c.map((v) => ({ ...v, permissionOf: PermisssionOf.Companies }))
      ),
      roles: convertDataType(
        r.map((v) => ({ ...v, permissionOf: PermisssionOf.Roles }))
      ),
    };

    dispatch({
      type: Types.Initial,
      payload: {
        data: permissionTable,
        permissionTypes,
        companyRoles,
        roles: rolesForm,
      },
    });
  };

  const users: ColumsField = {
    title: 'username',
    dataIndex: 'key',
    width: '70%',
    editable: true,
    options: configData.users,
    permissionOf: PermisssionOf.Users,
    handlable: true,
    render: renderName,
  };

  const owners: ColumsField = {
    title: 'username',
    dataIndex: 'key',
    width: '70%',
    editable: true,
    options: configData.users,
    permissionOf: PermisssionOf.Owners,
    handlable: false,
    render: renderName,
  };
  const companies: ColumsField = {
    title: 'company name',
    dataIndex: 'key',
    width: '70%',
    editable: true,
    options: configData.companies,
    permissionOf: PermisssionOf.Companies,
    handlable: true,
    render: renderName,
  };
  const roles: ColumsField = {
    title: 'role name',
    dataIndex: 'key',
    width: '70%',
    editable: true,
    options: configData.roles,
    permissionOf: PermisssionOf.Roles,
    handlable: true,
    render: renderName,
  };

  const columsFields = {
    users,
    companies,
    roles,
    owners,
  };

  const handleSubmit = () => {
    //TODO refactor move to reducer
    const data: DirectoryPermissionRequest = {
      userPermissions: [
        ...modifiedData(state.dataSource[PermisssionOf.Users]),
        ...state.deletedData[PermisssionOf.Users],
      ],
      companyPermissions: [
        ...modifiedData(state.dataSource[PermisssionOf.Companies]),
        ...state.deletedData[PermisssionOf.Companies],
      ],
      rolePermissions: [
        ...modifiedData(state.dataSource[PermisssionOf.Roles]),
        ...state.deletedData[PermisssionOf.Roles],
      ],
    };
    dispatch({ type: Types.ClearDeletedItem });
    CustomService.createData<DirectoryPermissionRequest, null>(
      `/file-services/folder/${id}/permissions`,
      data
    ).subscribe({
      next: () => {
        fetchData();
      },
    });
  };

  const renderFooter = (key: keyof PermissionTable) => {
    if (props.isRoot) {
      if (key === PermisssionOf.Roles) {
        return <FooterForm {...columsFields[key]} />;
      } else if (key !== PermisssionOf.Owners) {
        return <RootFooterForm {...columsFields[key]} />;
      }
    } else {
      if (key !== PermisssionOf.Owners) {
        return <FooterForm {...columsFields[key]} />;
      }
    }
  };

  return (
    <>
      {Object.keys(state.dataSource).map((v) => {
        const key = v as keyof PermissionTable;
        return (
          <PermissionForm
            key={key}
            loading={loading}
            title={t(`Permission for ${key}`)}
            columns={columsFields[key]}
            isRoot={props.isRoot || false}
          >
            {renderFooter(key)}
          </PermissionForm>
        );
      })}
      <ButtonSet
        handleCancel={props.handleCancel}
        loading={false}
        onClick={handleSubmit}
      />
    </>
  );
};
