import { usePermissionSearchContext } from '@contexts/PermissionSearchProvider';
import { Types } from '@reducers/PermissionSearchReducer';
import { Button, Form } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 14 },
};
const buttonProps: ButtonProps = {
  type: 'primary',
  style: { justifyContent: 'center', width: '30%' },
};
export interface RootPermissionSearch {
  size: number | undefined;
  page: number | undefined;
  sort?: string;
}

export interface CompanyRequest extends RootPermissionSearch {
  name?: string;
  symbol?: string;
}

export interface UserRequest extends RootPermissionSearch {
  name?: string;
  roleId?: string;
  companyType?: string;
}

export type RootPermissionQueryParam = UserRequest | CompanyRequest;
export const Search: React.FC<{ isPermissionOfUser: boolean }> = ({
  children,
  isPermissionOfUser,
}) => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const { dispatch } = usePermissionSearchContext();
  const handleSearch = (val: CompanyRequest | UserRequest) => {
    const path = isPermissionOfUser
      ? '/file-services/root/user'
      : '/file-services/root/company';
    dispatch({
      type: Types.SEARCH_RESULT,
      payload: { search: { queryParam: val, path } },
    });
  };

  return (
    <Form {...layout} form={form} onFinish={handleSearch}>
      {children}
      <Form.Item {...tailLayout}>
        <Button name={`btn-search`} htmlType="submit" {...buttonProps}>
          {t('button.search')}
        </Button>
      </Form.Item>
    </Form>
  );
};
