import { PermissionSearchProvider } from '@contexts/PermissionSearchProvider';
import { PermissionTable } from '@core/@models/DirectoryPermissionModel';
import React from 'react';
import { AddForm } from './AddForm';

type RootFooterFormProps = {
  permissionOf: keyof PermissionTable;
};

export const RootFooterForm: React.FC<RootFooterFormProps> = ({ ...props }) => {
  return (
    <PermissionSearchProvider>
      <AddForm permissionOf={props.permissionOf} />
    </PermissionSearchProvider>
  );
};
