import { IDirectoryTree } from '@core/@models/DirectoryServiceModel';
import { CustomService } from '@core/services/custom.service';
import { useTreeManagement } from '@helpers/use-tree-management';
import { Popover, Row, Spin } from 'antd';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import React, { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
import { directoryPreviewSearchSubject$ } from './DirectoryPreviewSearch';
import {
  DirectoryPreviewUserListSubject,
  directoryPreviewUserListSubject$,
} from './DirectoryPreviewUserList';

export interface HandleUpdateTreeData {
  folderId: Key;
  key: Key;
  callback?: () => void;
}

export const DirectoryTreePage: React.FC = () => {
  const { t } = useTranslation();
  const [treeData, setTreeData] = useState<IDirectoryTree[]>([]);
  const [userId, setUserId] = useState<Key>();

  const onLoadData = ({ key, children, ...rest }: EventDataNode) => {
    console.log('onLoadData');
    const { id: folderId } = rest as IDirectoryTree;
    setLoading(true);
    return new Promise<void>((resolve) => {
      if (children) {
        setLoading(false);
        resolve();
        return;
      }
      getDataById({
        folderId,
        key,
        callback: resolve,
      });
    });
  };

  const {
    treeRef,
    cacheDataRef,
    cacheGetDataRef$,
    folderIdRef,
    loading,
    prevSelectedKeyRef,
    setLoading,
    addRelativeKey,
    updateTreeData,
    getData$,
    handleOnClick,
  } = useTreeManagement('/directory-previews/expand', onLoadData);

  useEffect(() => {
    const subsription = directoryPreviewUserListSubject$.subscribe({
      next: ({ userId: previewUserId }: DirectoryPreviewUserListSubject) => {
        setUserId(previewUserId);
        getExpandFolder(previewUserId);
      },
    });

    return () => subsription.unsubscribe();
  }, []);

  useEffect(() => {
    const subsription = directoryPreviewSearchSubject$.subscribe({
      next: () => {
        setUserId(undefined);
        setTreeData([]);
      },
    });

    return () => subsription.unsubscribe();
  }, []);

  const resetTreeRef = () => {
    if (treeRef.current) {
      treeRef.current.state.expandedKeys = [];
      treeRef.current.state.loadedKeys = [];
      treeRef.current.state.selectedKeys = [];

      cacheDataRef.current = undefined;
      cacheGetDataRef$.current = undefined;
      prevSelectedKeyRef.current = '';
      folderIdRef.current = '';
    }
  };

  const getExpandFolder = (previewUserId: Key) => {
    setLoading(true);
    CustomService.getData<IDirectoryTree[]>(
      `/directory-previews/expand?userId=${previewUserId}`
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (data) => {
          const node = addRelativeKey({ data });
          setTreeData(node);
          resetTreeRef();
        },
      });
  };

  const titleRender = (data: DataNode) => {
    const {
      description,
      fileLimitSize,
      name,
      permissionType,
    } = data as IDirectoryTree;
    const content = `${description} (File limit size: ${fileLimitSize})`;
    return (
      <>
        <Popover
          destroyTooltipOnHide={{ keepParent: false }}
          content={<span style={{ fontSize: '12px' }}>{content}</span>}
        >
          {name}{' '}
          {permissionType === 'READ_WRITE' ? (
            <span style={{ color: 'red' }}>[RW]</span>
          ) : (
            <span style={{ color: 'green' }}>[R]</span>
          )}
        </Popover>
      </>
    );
  };

  const getDataById = ({ folderId, key, callback }: HandleUpdateTreeData) => {
    getData$(folderId, userId).subscribe({
      next: (data: IDirectoryTree[] | null) => {
        if (!data) return;
        const node = addRelativeKey({ parentKey: key, data });
        setTreeData((origin) => updateTreeData(origin, key, node));
        callback && callback();
      },
    });
  };

  const onSelect = (selectedKeys: React.Key[]) => {
    const key = [...selectedKeys].shift() || '';
    console.log('onSelect', selectedKeys, key);
    prevSelectedKeyRef.current = key;
  };

  return (
    <div className="custom-tree" style={{ overflowY: 'hidden' }}>
      <div className="header-preview">{t('common:preview')}</div>
      {userId && treeData.length !== 0 && (
        <Spin spinning={loading}>
          <DirectoryTree
            className="directory-tree"
            ref={(ref) => (treeRef.current = ref)}
            loadData={onLoadData}
            treeData={treeData}
            titleRender={titleRender}
            onSelect={onSelect}
            onClick={handleOnClick}
            blockNode={true}
            expandAction={false}
          />
        </Spin>
      )}
      {userId && treeData.length === 0 && (
        <Row style={{ height: 630 }}>
          <span>No Data</span>
        </Row>
      )}
      {!userId && (
        <Row style={{ height: 630 }}>
          <span>{t('common:selectItemForDetail')}</span>
        </Row>
      )}
    </div>
  );
};
