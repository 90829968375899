import { IMenu, ISubMenu, useAuthContext } from '@contexts/AuthProvider';
import { LanguageService } from '@core/services/language.service';
import { Col, Divider, Layout, Menu, Row, Select } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import circle from 'assets/icon/circle.png';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import { backToSETPortal } from '@helpers/utils';
import { useTranslation } from 'react-i18next';
import { AuthService } from '@core/services/auth.service';
import { CustomService } from '@core/services/custom.service';
import { Role } from '@core/@models/UserModel';

const { Sider } = Layout;

export const SiderMenu: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { siderMenus, roleId, defaultMenu, setDefaultMenu } = useAuthContext();
  const [latestOpenKeys, setLatestOpenKeys] = useState<string[]>([]);
  const lang = LanguageService.getLanguage();
  const nameKey = lang === 'th' ? 'nameTh' : 'nameEn';
  const { t, i18n } = useTranslation(['common']);
  const { pathname } = useLocation();
  const history = useHistory();
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    CustomService.getData<Role[]>('/users/role').subscribe({
      next: (response) => setRoles(response),
    });
  }, []);

  const getTitleView = (title: string) => (
    <Row>
      <Col flex="1">
        <img src={circle} style={{ marginBottom: 4 }} />
      </Col>
      <Col flex="11">{title}</Col>
      <Col flex="1">
        <Divider type="vertical" />
      </Col>
    </Row>
  );

  const checkAccessTime = (menu: IMenu, subMenu: ISubMenu) => {
    const { id: menuId } = menu;
    const { id: subMenuId } = subMenu;
    setDefaultMenu({ menuId: [`${menuId}`], subMenuId: [`${subMenuId}`] });
  };

  const onOpenChange = (openKeys: React.Key[]) => {
    setDefaultMenu({
      menuId: openKeys as string[],
      subMenuId: [`${defaultMenu.subMenuId}`],
    });
  };

  const openKeys = useMemo(() => {
    if (defaultMenu?.menuId?.[0] === '') {
      return [`${siderMenus?.menus?.[0]?.id}`];
    } else if (collapsed) {
      return latestOpenKeys;
    } else {
      return defaultMenu.menuId;
    }
  }, [defaultMenu, siderMenus]);

  useEffect(() => {
    if (collapsed) setLatestOpenKeys(openKeys);
  }, [openKeys, collapsed]);

  const roleOptions = useMemo(() => {
    return roles.map((r) => ({
      value: `${r.id}`,
      label: i18n.language === 'th' ? r.descriptionTh : r.descriptionEn,
    }));
  }, [i18n.language, roles]);

  const onRoleChange = (value: string) => {
    AuthService.setRoleId(value);
    if (pathname === '/') window.location.replace('/');
    else history.push('/');
  };

  return (
    <>
      <Sider
        width={256}
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth="0"
        style={{ paddingBottom: 40, userSelect: 'none' }}
      >
        <RoleCard>
          File Service
          <Select
            style={{ width: '214px', fontWeight: 500 }}
            value={AuthService.getRoleId() ?? ''}
            options={roleOptions}
            onChange={onRoleChange}
          />
        </RoleCard>
        <Menu
          mode="inline"
          className="side-menu"
          openKeys={openKeys}
          defaultOpenKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={defaultMenu.subMenuId}
        >
          {!collapsed &&
            siderMenus?.menus.map((menu) => {
              return (
                <SubMenu key={menu.id} icon={menu.icon} title={menu[nameKey]}>
                  {menu.subMenus.map((subMenu) => {
                    return (
                      <Menu.Item key={subMenu.id} title={subMenu[nameKey]}>
                        <NavLink
                          to={`${subMenu.path}/?menuId=${menu.id}&subMenuId=${subMenu.id}&roleId=${roleId}&readOnly=${subMenu.readOnly}`}
                          onClick={() => checkAccessTime(menu, subMenu)}
                        >
                          {getTitleView(subMenu[nameKey])}
                        </NavLink>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            })}
        </Menu>
      </Sider>
      <BackButton
        className={`back-to-set-portal${collapsed ? '-collapse' : ''}`}
        onClick={() => backToSETPortal(process.env.REACT_APP_ENV)}
      >
        {!collapsed && (
          <BackLabel>
            <BackIcon />
            {t('button.backToSETPortal')}
          </BackLabel>
        )}
      </BackButton>
    </>
  );
};

const BackButton = styled.div`
  bottom: 0px;
  position: fixed;
  line-height: 38px;
  background-color: white;
  color: #009fc2;
  &:hover {
    color: #00afd6;
    cursor: pointer;
  }
`;
const BackLabel = styled.div`
  border-top: 1px solid #00000010;
  margin: 0px 20px;
`;
const BackIcon = styled(LeftOutlined)`
  margin-right: 8px;
`;
const RoleCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: linear-gradient(180deg, #fafafa 0%, #f5f5f5 100%);
  font-weight: 700;
  padding: 6px 24px;
  color: rgba(0, 0, 0, 0.85);
`;
