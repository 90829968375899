import React, { useState } from 'react';
import { DropDownToggleMemorize } from './DropDownToggle';
export enum ActionMenuType {
  Move = 'move',
  Copy = 'copy',
}
export type ActionMenuProps = {
  source: React.Key | undefined;
  action: ActionMenuType;
  parentId: React.Key;
  relativeKey: React.Key;
  isFolder: boolean;
};
export const ActionMenu: React.FC = () => {
  const [action, setAction] = useState<ActionMenuProps | undefined>();
  const handleAction = (result: ActionMenuProps) => setAction(result);

  return <DropDownToggleMemorize handleAction={handleAction} action={action} />;
};
