import { Button, Form, Select, Tooltip } from 'antd';
import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  DirectoryPermissionType,
  OptionType,
  PermissionTable,
  PermissionTableField,
} from '@core/@models/DirectoryPermissionModel';
import { filterOption } from '@helpers/utils';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { AddPermission, UpdatePermission } from '@reducers/PermissionReducer';

const { Item } = Form;
type FooterFormProps = {
  permissionOf: keyof PermissionTable;
  options: OptionType[];
};

export const FooterForm: React.FC<FooterFormProps> = ({
  permissionOf,
  ...props
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { state, dispatch } = usePermissionContext();

  const onFinish = (val: { [key: string]: OptionType & { key: string } }) => {
    const { dataSource } = state;
    const { key, label } = val[`ownerId-${permissionOf}`];
    const record = dataSource[permissionOf].find((d) => d.key === key);
    const { value: permissionType } = val[`permissionType-${permissionOf}`];
    const [loginName, fullName] = label.split('-').map((v) => v.trim());
    const data: PermissionTableField = {
      ownerId: +key,
      key,
      loginName,
      fullName,
      deletable: true,
      permissionType: permissionType as DirectoryPermissionType,
    };
    if (record?.permissionId) {
      data.permissionId = record?.permissionId;
    }
    const index = dataSource[permissionOf].findIndex((d) => d.key === key);
    const isAddExistData = index > -1;
    if (isAddExistData) {
      const newData = [...dataSource[permissionOf]];
      newData.splice(index, 1, data);
      dispatch(UpdatePermission(newData, permissionOf));
    } else {
      dispatch(AddPermission(data, permissionOf));
    }
    form.resetFields();
  };
  return (
    <Form
      form={form}
      style={{ display: 'flex' }}
      id={`footer-form-${permissionOf}`}
      onFinish={onFinish}
    >
      <Item
        name={`ownerId-${permissionOf}`}
        style={{ margin: '0 15px 0 0', padding: 0, width: 395 }}
        rules={[
          {
            required: state.editingKey == '',
            message: t('common:errorMessage.required', {
              labelName: t(`common:${permissionOf}`),
            }),
          },
        ]}
      >
        <Select
          labelInValue
          optionFilterProp="options"
          showSearch
          options={props.options}
          filterOption={filterOption}
          placeholder={t(`common:${permissionOf}`)}
        />
      </Item>
      <Item
        name={`permissionType-${permissionOf}`}
        style={{ margin: '0 10px 0 0', width: 130 }}
        rules={[
          {
            required: state.editingKey == '',
            message: t('common:errorMessage.required', {
              labelName: t(`common:permissionType`),
            }),
          },
        ]}
      >
        <Select
          optionFilterProp="options"
          options={state.permissionTypes}
          labelInValue
          placeholder={t(`common:permissionType`)}
        />
      </Item>
      <Item style={{ margin: '0 0 0 10px' }}>
        <Tooltip title={t('common:button.add')}>
          <Button
            name={`btn-add-${permissionOf}`}
            type="link"
            htmlType="submit"
            size="large"
            icon={<PlusCircleOutlined />}
            disabled={state.editingKey !== ''}
            style={{ padding: 0, height: 30 }}
          />
        </Tooltip>
      </Item>
    </Form>
  );
};
