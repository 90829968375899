import { ButtonSet } from '@components/ButtonSet';
import { DirectoryServiceLinkRequest } from '@core/@models/DirectoryServiceModel';
import { ApiService } from '@core/services/api.service';
import { Form, Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

const { Item } = Form;
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 16 },
};

interface LinkFormProps {
  handleCancel: () => void;
  parentId?: React.Key;
  id?: React.Key;
  fetchData?: (param: { folderId: number; key: React.Key }) => void;
  relativeKey?: string;
}

export const LinkForm: React.FC<LinkFormProps> = ({
  id,
  parentId,
  ...props
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const apiService = useMemo(() => new ApiService('/file-services/link'), []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    getDataById();
  }, []);

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, DirectoryServiceLinkRequest>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DirectoryServiceLinkRequest) => {
          form.setFieldsValue(result);
        },
      });
  };

  const onFinish = (value: DirectoryServiceLinkRequest) => {
    const data = { ...value, id, parentId };
    const action$ = id
      ? apiService.updateData(data)
      : apiService.createData(data);
    setLoading(true);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        props.fetchData &&
          props.fetchData({
            folderId: parentId as number,
            key: props.relativeKey || '',
          });
        props.handleCancel();
      },
    });
  };

  return (
    <Form
      form={form}
      name="folder-form"
      {...formItemLayout}
      onFinish={onFinish}
      autoComplete="off"
      preserve={false}
    >
      <Item
        name="name"
        label={t('common:Link name')}
        rules={[{ required: true }, { max: 100 }]}
      >
        <Input placeholder={t('common:Link name')} />
      </Item>
      <Item
        name="description"
        label={t('common:Link description')}
        rules={[{ required: true }, { max: 200 }]}
      >
        <Input placeholder={t('common:Link description')} />
      </Item>
      <Item
        name="link"
        label={t('common:url')}
        rules={[{ required: true, type: 'url' }, { max: 200 }]}
      >
        <Input placeholder={t('common:url')} />
      </Item>

      <ButtonSet handleCancel={props.handleCancel} loading={loading} />
    </Form>
  );
};
