import { ISiderMenu, useAuthContext } from '@contexts/AuthProvider';
import { AuthService } from '@core/services/auth.service';
import { CustomService } from '@core/services/custom.service';
import { Spin } from 'antd';
import * as React from 'react';
import { ComponentType, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { insertColon } from './utils';

export function withCheckAccessTime<P>(
  Component: ComponentType<P>
): React.FC<P> {
  return function withCheckAccessTimes(props: P): JSX.Element {
    const [canAccess, setCanAccess] = useState<boolean>();
    const {
      defaultMenu: { menuId, subMenuId },
    } = useAuthContext();

    const sideMenuStorage = AuthService.getSideMenus() || '';

    const siderMenus: ISiderMenu = sideMenuStorage
      ? JSON.parse(sideMenuStorage)
      : undefined;

    useEffect(() => {
      let mounted = true;
      if (!mounted) return;

      callAccessTime();

      return () => {
        mounted = false;
      };
    }, [subMenuId]);

    const callAccessTime = () => {
      CustomService.getData<boolean>(
        '/users/menu/check-access',
        undefined
      ).subscribe({
        next: (response: boolean) => {
          setCanAccess(response);
        },
      });
    };

    const getNotifyAccessTime = (): {
      title: string;
      accessTimes: string[];
    } => {
      const menu = siderMenus?.menus.find((m) => m.id === +menuId[0]);
      const subMenu = menu?.subMenus.find((s) => s.id === +subMenuId[0]);
      const accessTimes =
        subMenu?.times.map((time) => {
          return time
            ? `[ ${insertColon(time.from)}-${insertColon(time.to)} ]`
            : '';
        }) || [];
      return { title: 'common:permissionDeny', accessTimes };
    };

    if (canAccess === undefined) {
      return <AcessTimeLoading size="large" />;
    }
    return canAccess ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/error/access',
          state: getNotifyAccessTime(),
        }}
      />
    );
  };
}

const AcessTimeLoading = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
