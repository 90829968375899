import { Card, Row, Col, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DirectoryPreviewConfigForm,
  DirectoryPreviewSearchProps,
} from '@core/@models/DirectoryPreviewModel';
import { useSearch } from '@helpers/use-search';
import { filterOption } from '@helpers/utils';
import { CustomService } from '@core/services/custom.service';
import { Subject } from 'rxjs';
import { Label, Wrapper } from '@components/Search';
import { SearchButton } from '@components/SearchButton';

interface DirectoryPreviewConfigOptions {
  marketScopes: { value: string; label: string }[];
  companyRoles: { value: string; label: string }[];
}

const configForm: DirectoryPreviewConfigOptions = {
  marketScopes: [],
  companyRoles: [],
};

export const directoryPreviewSearchSubject$ = new Subject();

export const DirectoryPreviewSearch: React.FC = () => {
  const { t } = useTranslation();
  const [
    configOptions,
    setconfigOptions,
  ] = useState<DirectoryPreviewConfigOptions>(configForm);
  const {
    handleLoginNameChange,
    handleMarketScopeChange,
    handleCompanyNameChange,
    handleCompanyTypeChange,
    handleSearch,
    handleReset,
    loginName,
    marketScope,
    companyName,
    companyType,
  } = useSearch();

  useEffect(() => {
    getConfigForm();
  }, []);

  const initialConfigData = (result: DirectoryPreviewConfigForm) => {
    const { marketScopes: s, companyRoles: c } = result;
    const marketScopes = s.map((m) => ({ value: m, label: m }));
    const companyRoles = c.map((r) => ({ value: r.key, label: r.value }));
    setconfigOptions({ marketScopes, companyRoles });
  };

  const getConfigForm = () => {
    CustomService.getData<DirectoryPreviewConfigForm>(
      '/directory-previews/config-form'
    ).subscribe({
      next: (result: DirectoryPreviewConfigForm) => {
        initialConfigData(result);
      },
    });
  };

  const getInputSearch = (
    fieldName: string,
    onChangeFunction: React.ChangeEventHandler<HTMLInputElement>,
    value: string
  ) => {
    return (
      <Col flex="100">
        <Wrapper>
          <Label>{t(`common:${fieldName}`)}</Label>
          <Input
            placeholder={`${t(`common:${fieldName}`)}...`}
            name="name"
            onChange={onChangeFunction}
            allowClear={true}
            value={value}
          />
        </Wrapper>
      </Col>
    );
  };

  const getDropdownSearch = (
    fieldName: string,
    onChangeFunction: (value: string) => void,
    value: string,
    options: any
  ) => {
    return (
      <Col flex="100">
        <Wrapper>
          <Label>{t(`common:${fieldName}`)}</Label>
          <Select
            style={{ width: '100%' }}
            allowClear
            showSearch
            filterOption={filterOption}
            onChange={onChangeFunction}
            options={options}
            value={value || undefined}
            placeholder={`${t('common:selectAll')}...`}
          ></Select>
        </Wrapper>
      </Col>
    );
  };

  return (
    <Card
      className="search-form"
      title={t('common:filter')}
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton
          handleSearch={() => {
            directoryPreviewSearchSubject$.next();
            handleSearch();
          }}
          handleReset={handleReset}
        />
      }
    >
      <Row>
        {getInputSearch('loginName', handleLoginNameChange, loginName)}
        {getDropdownSearch(
          'marketScope',
          handleMarketScopeChange,
          marketScope,
          configOptions.marketScopes
        )}
        {getInputSearch('companyName', handleCompanyNameChange, companyName)}
        {getDropdownSearch(
          'companyType',
          handleCompanyTypeChange,
          companyType,
          configOptions.companyRoles
        )}
      </Row>
    </Card>
  );
};
