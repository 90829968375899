import { useDropdownContext } from '@contexts/DropdownProvider';
import { IDirectoryTree } from '@core/@models/DirectoryServiceModel';
import { Popover, Spin } from 'antd';
import { DataNode } from 'antd/lib/tree';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import { MoreButton } from 'pages/directory-service/MoreButton';
import React, { useEffect, useState } from 'react';

type DirectoryTreePageProps = {
  loading: boolean;
  data: IDirectoryTree[];
  fetchExpandFolder: () => void;
};
export const DirectoryTreePage: React.FC<DirectoryTreePageProps> = ({
  loading,
  data,
  ...props
}) => {
  const [treeData, setTreeData] = useState<IDirectoryTree[]>([]);
  const { setItem } = useDropdownContext();

  useEffect(() => {
    updateTreeData();
  }, [data]);

  const updateTreeData = () => {
    const node = addRelativeKey();
    setTreeData(node);
  };

  const addRelativeKey = (): IDirectoryTree[] => {
    return data.map((d) => ({
      ...d,
      title: '',
      key: `${d.id}`,
    }));
  };

  const onSelectNode = (node: {
    ref: HTMLButtonElement | null;
    data: DataNode;
  }) => {
    const { id, folderType, key } = node.data as IDirectoryTree;
    const dataNode = {
      id,
      actions: [
        'root_edit',
        'root_delete',
        'root_permission',
        'root_assign_owner',
      ],
      folderType,
      relativeKey: `${key}`,
      fetchData: props.fetchExpandFolder,
    };
    const result = Object.assign({}, node, { data: dataNode });
    setItem(result);
  };

  const titleRender = (dataNode: DataNode) => {
    const { description, fileLimitSize, name } = dataNode as IDirectoryTree;
    const content = `${description} (File limit size: ${fileLimitSize})`;
    return (
      <>
        <Popover
          destroyTooltipOnHide={{ keepParent: false }}
          content={<span style={{ fontSize: '12px' }}>{content}</span>}
        >
          {name}
        </Popover>
        <MoreButton {...dataNode} onClick={onSelectNode} />
      </>
    );
  };

  return (
    <div className="custom-tree">
      <Spin spinning={loading}>
        <DirectoryTree
          className="directory-tree"
          treeData={treeData}
          titleRender={titleRender}
          blockNode={true}
        />
      </Spin>
    </div>
  );
};
