import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FolderAddOutlined,
  FormOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  LockOutlined,
  ScissorOutlined,
  SnippetsOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { ConfirmModal, ModalCmp } from '@components/ModalCmp';
import { useDropdownContext } from '@contexts/DropdownProvider';
import { PermissionProvider } from '@contexts/PermissionProvider';
import { SearchProvider } from '@contexts/SearchProvider';
import {
  Action,
  DeleteMultipleFilesParam,
} from '@core/@models/DirectoryServiceModel';
import { CustomService } from '@core/services/custom.service';
import { useModalManagement } from '@helpers/use-modal-management';
import Tippy from '@tippyjs/react/headless';
import { Button } from 'antd';
import { RootFolderForm } from 'pages/directory-management/RootFolderForm';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ActionMenuProps, ActionMenuType } from '.';
import { AssignOwnerForm } from './AssignOwnerForm';
import { DeleteMulipleFilesForm } from './DeleteMulipleFilesForm';
import { FolderForm } from './FolderForm';
import { LinkForm } from './LinkForm';
import { Permissions } from './Permissions';
import { PropertiesView } from './PropertiesView';
import { UploadFileForm } from './UploadFileForm';

const iconProps = { style: { color: '#FCB034' } };

export const actions: {
  [key: string]: {
    id: number;
    icon: React.ReactElement;
    title: string;
    width?: number;
  };
} = {
  create_dynamic: {
    id: 1,
    icon: <FolderAddOutlined {...iconProps} />,
    title: 'create folder',
  },
  create: {
    id: 1,
    icon: <FolderAddOutlined {...iconProps} />,
    title: 'create folder',
  },
  upload: {
    id: 2,
    icon: <UploadOutlined {...iconProps} />,
    title: 'uploadFile',
  },
  link: { id: 3, icon: <LinkOutlined {...iconProps} />, title: 'create link' },
  multiple_delete: {
    id: 4,
    icon: <DeleteOutlined {...iconProps} />,
    title: 'multipleDelete',
    width: 1000,
  },
  edit: { id: 5, icon: <FormOutlined {...iconProps} />, title: 'edit' },
  edit_dynamic: { id: 5, icon: <FormOutlined {...iconProps} />, title: 'edit' },
  delete: { id: 6, icon: <DeleteOutlined {...iconProps} />, title: 'delete' },
  copy: { id: 7, icon: <CopyOutlined {...iconProps} />, title: 'copy' },
  cut: { id: 8, icon: <ScissorOutlined {...iconProps} />, title: 'cut' },
  paste: { id: 9, icon: <SnippetsOutlined {...iconProps} />, title: 'paste' },
  permission: {
    id: 10,
    icon: <LockOutlined {...iconProps} />,
    title: 'permission',
    width: 660,
  },
  assign_owner: {
    id: 11,
    icon: <LockOutlined {...iconProps} />,
    title: 'assignOwner',
    width: 660,
  },
  properties: {
    id: 12,
    icon: <InfoCircleOutlined {...iconProps} />,
    title: 'properties',
  },
  open: { id: 13, icon: <LinkOutlined {...iconProps} />, title: 'open' },
  download: {
    id: 14,
    icon: <DownloadOutlined {...iconProps} />,
    title: 'download',
  },
  root_edit: {
    id: 15,
    icon: <FormOutlined {...iconProps} />,
    title: 'edit root folder',
  },
  root_delete: {
    id: 16,
    icon: <DeleteOutlined {...iconProps} />,
    title: 'delete',
  },
  root_permission: {
    id: 17,
    icon: <LockOutlined {...iconProps} />,
    title: 'permission',
    width: 660,
  },
  root_assign_owner: {
    id: 18,
    icon: <LockOutlined {...iconProps} />,
    title: 'assignOwner',
    width: 660,
  },
};

const FOLDER = 'folder';
const FILE = 'file';
const LINK = 'link';
const DEFAULT_MODAL_WIDTH = 600;

export interface DropDownToggleProps {
  handleAction: (action: ActionMenuProps) => void;
  action?: ActionMenuProps;
}

export const DropDownToggle: React.FC<DropDownToggleProps> = ({ ...props }) => {
  const { t } = useTranslation(['common']);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { item, setItem } = useDropdownContext();
  const hideTippy = () => setItem(undefined);
  const hideAll = () => {
    hideModal();
    hideTippy();
    setSelectedId(null);
  };

  const modalTitle = useRef('');
  const directoryType = item?.data?.folderType ? FOLDER : FILE;

  const isDynamicFolder = !!item?.data?.actions.find((v) =>
    ['create_dynamic', 'edit_dynamic'].includes(v)
  );

  const handleOpenLink = (url: string | undefined) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  const handleAction = (id: number) => {
    switch (id) {
      case Action.Open:
        handleOpenLink(item?.data?.link);
        break;
      case Action.Download: {
        if (!item?.data) {
          return;
        }
        const { handleDownloadFile, id: folderId = '', name = '' } = item.data;
        if (!handleDownloadFile) {
          return;
        }
        handleDownloadFile(+folderId, name);

        hideTippy();
        break;
      }
      case Action.Delete:
      case Action.RootDelete:
        handleDelete();
        break;
      case Action.Copy:
        handleCopyCut(ActionMenuType.Copy);
        break;
      case Action.Cut:
        handleCopyCut(ActionMenuType.Move);
        break;
      case Action.Paste:
        handlePaste();
        break;
      default:
        setSelectedId(id);
        showModal();
    }
  };

  const handleCopyCut = (action: ActionMenuType) => {
    if (!item?.data) return;
    const { id, parentId = 0, relativeKey } = item.data || {};
    props.handleAction({
      source: id,
      action,
      parentId: parentId,
      relativeKey: relativeKey,
      isFolder: 'folderType' in item.data,
    });
    hideTippy();
  };

  const handlePaste = () => {
    if (props.action?.source) {
      handleEditData(props.action.source, item?.data?.id);
    }
    hideTippy();
  };

  const handleEditData = (source?: React.Key, destination?: React.Key) => {
    const { parentId = 0, action, isFolder, relativeKey: sourceKey } =
      props.action || {};
    CustomService.createData<null, null>(
      `/file-services/${action}/${source}/to/${destination}`,
      null
    ).subscribe({
      next: () => {
        if (!item) return;
        const { id = 0, relativeKey } = item.data || {};
        if (action === ActionMenuType.Move && isFolder) {
          const arr = `${sourceKey}`.split('-');
          const sourceRelativeKey = arr.splice(0, arr.length - 1).join('-');
          forceFetchData(+parentId, sourceRelativeKey);
        } else {
          forceFetchData(+parentId, relativeKey);
        }
        forceFetchData(+id, relativeKey);
      },
    });
  };

  const forceFetchData = (folderId: number, key: React.Key) =>
    item?.data && item.data.fetchData && item.data.fetchData({ folderId, key });

  const setAction = item?.data?.actions.map((action) => (
    <MenuButton
      data-testid={actions[action].title}
      key={actions[action].id}
      type="link"
      icon={actions[action].icon}
      disabled={actions[action].id === Action.Paste && !props.action?.source}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalTitle(actions[action]);
        handleAction(actions[action].id);
      }}
    >
      {t(`actions.${actions[action].title}`)}
    </MenuButton>
  ));

  const setModalTitle = ({
    title,
    id,
  }: {
    id: number;
    icon: React.ReactElement;
    title: string;
  }) => {
    modalTitle.current = id === Action.Edit ? `${title} ${folderType}` : title;
  };

  const handleDelete = () => {
    const onDelete = () => {
      if (!item) return;
      const { id, parentId = 0, relativeKey } = item.data;
      const arr = relativeKey.split('-');
      const parentKey = arr.splice(0, arr.length - 1).join('-');
      CustomService.deleteData(
        `/file-services/${directoryType}/${id}`
      ).subscribe({
        next: () => {
          const key = directoryType === FOLDER ? parentKey : relativeKey;
          forceFetchData(+parentId, key);
        },
      });
    };
    ConfirmModal({ onOk: onDelete });
  };
  const fileType = item?.data?.link ? LINK : FILE;
  const folderType = item?.data?.folderType ? FOLDER : fileType;

  const preparedData = {
    ...item?.data,
    id:
      selectedId === Action.CreateFolder ||
      selectedId === Action.UploadFile ||
      selectedId === Action.CreateLink
        ? undefined
        : item?.data.id,
    parentId: selectedId === Action.Edit ? item?.data.parentId : item?.data.id,
    handleCancel: hideAll,
    folderType: item?.data.folderType,
  };

  const modalWidth =
    Object.values(actions).find((v) => v.id === selectedId)?.width ||
    DEFAULT_MODAL_WIDTH;

  const handleOnClickOutside = () => {
    if (modalRef.current) return;
    hideTippy();
  };

  const modalProps = {
    visible,
    title: t(`actions.${modalTitle.current}`),
    handleCancel: hideModal,
    width: modalWidth,
    ref: modalRef,
  };

  const initialParam: DeleteMultipleFilesParam = {
    name: null,
    begin: null,
    end: null,
    includeSubfolder: false,
  };

  const LoadComponent = () => {
    switch (selectedId) {
      case Action.CreateFolder:
        return (
          <FolderForm {...preparedData} isDynamicFolder={isDynamicFolder} />
        );
      case Action.CreateLink:
        return <LinkForm {...preparedData} />;
      case Action.UploadFile:
        return <UploadFileForm {...preparedData} />;
      case Action.Edit: {
        switch (folderType) {
          case FOLDER:
            return (
              <FolderForm {...preparedData} isDynamicFolder={isDynamicFolder} />
            );
          case LINK:
            return <LinkForm {...preparedData} />;
          case FILE:
            return <UploadFileForm {...preparedData} />;
          default:
            throw new Error('Can not load component!!!');
        }
      }
      case Action.Properties:
        return <PropertiesView {...preparedData} />;
      case Action.DeleteMultipleFiles:
        return (
          <SearchProvider<DeleteMultipleFilesParam> initialParam={initialParam}>
            <DeleteMulipleFilesForm {...preparedData} />
          </SearchProvider>
        );
      case Action.EditRootFolder:
        return <RootFolderForm {...preparedData} />;
    }
  };

  return (
    <>
      <Tippy
        zIndex={100}
        visible={item !== undefined}
        onClickOutside={handleOnClickOutside}
        arrow={false}
        interactive={true}
        placement="bottom"
        offset={[80, -10]}
        appendTo={document.querySelector('div.custom-tree') || document.body}
        reference={item?.ref}
        render={(attrs) => <Panel {...attrs}>{setAction}</Panel>}
      />
      {selectedId && (
        <ModalCmp {...modalProps}>
          {LoadComponent()}
          <PermissionProvider>
            {selectedId === Action.Permission && (
              <Permissions {...preparedData} isRoot={false} />
            )}
            {selectedId === Action.AssignOwner && (
              <AssignOwnerForm {...preparedData} isRoot={false} />
            )}
            {selectedId === Action.RootPermission && (
              <Permissions {...preparedData} isRoot={true} />
            )}
            {selectedId === Action.AssignRootOwner && (
              <AssignOwnerForm {...preparedData} isRoot={true} />
            )}
          </PermissionProvider>
        </ModalCmp>
      )}
    </>
  );
};

export const DropDownToggleMemorize = React.memo(DropDownToggle);

const MenuButton = styled(Button)`
  display: block;
  height: 32px;
  width: 100%;
  padding: 0 0 0 7px;
  margin: 0;
  text-align: left;
  color: #231f20;
  border-radius: 0;
  font-size: 13px;
  transition: background-color 0.3s;
  :hover {
    background: #fff3dfb2;
    color: #231f20 !important;
  }
  :disabled {
    :hover {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
`;

const Panel = styled.div`
  background: #ffffff;
  width: 150px;
  border: 1px solid #fcb03480;
  box-sizing: border-box;
  border-radius: 2px;
`;
