import { ButtonSet } from '@components/ButtonSet';
import {
  DownloadConfigRequest,
  DownloadConfigResponseBody,
  File,
} from '@core/@models/DownloadConfigModel';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { NotificationType, useNotification } from '@helpers/use-notification';
import { Checkbox, Col, Form, Input, Row, Spin, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { Key, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

const { TabPane } = Tabs;
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 17,
  },
};
interface DownloadConfigFormProps {
  configName: string | null;
  handleCancel: () => void;
  fetchData: () => void;
}

export const DownloadConfigForm: React.FC<DownloadConfigFormProps> = ({
  configName,
  handleCancel,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);
  const { open } = useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[][]>([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DownloadConfigResponseBody>();
  const apiService = useMemo(
    () => new ApiService('/download-services/config'),
    []
  );

  useEffect(() => {
    getConfigForm(configName);
  }, []);

  const getConfigForm = (reqConfigName: string | null) => {
    const url = `/download-services/config${
      reqConfigName ? `?configName=${reqConfigName}` : ''
    } `;
    setLoading(true);
    CustomService.getData<DownloadConfigResponseBody>(url)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DownloadConfigResponseBody) => {
          initialForm(result);
          setData(result);
        },
      });
  };

  const initialForm = (result: DownloadConfigResponseBody) => {
    const keys: Key[][] = [];
    form.setFieldsValue({ configName: result.configName });
    result.fileGroups.forEach((fileGroup, index) => {
      form.setFieldsValue({
        [`localPath${fileGroup.name}`]: fileGroup.localPath,
        [`includeSubfolder${fileGroup.name}`]: fileGroup.includeSubfolder,
      });
      keys[index] = fileGroup.files
        .map((f) => (f.checked ? f.name : ''))
        .filter((f) => f);
      setSelectedRowKeys(keys);
    });
  };

  const onFinish = (formData: any) => {
    if (!isSelectedFilesValid()) {
      open({
        type: NotificationType.ERROR,
        description: 'Item of file is required',
        disableDate: true,
      });
      return;
    }
    const request = getRequest(formData);
    setLoading(true);
    const action$ = apiService.createData(request);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData();
        handleCancel();
      },
    });
  };

  const isSelectedFilesValid = (): boolean => {
    let isValid = false;
    selectedRowKeys.forEach((s) => {
      if (!isValid) isValid = s.length !== 0;
    });
    return isValid;
  };

  const getRequest = (formData: any): DownloadConfigRequest | undefined => {
    if (!data) return undefined;
    const fileGroups = data.fileGroups.map((g, index) => {
      const files = g.files.map((f) => ({
        ...f,
        checked: selectedRowKeys[index].includes(f.name),
      }));
      return {
        ...g,
        localPath:
          formData[`localPath${g.name}`] !== undefined
            ? formData[`localPath${g.name}`]
            : g.localPath,
        includeSubfolder:
          formData[`includeSubfolder${g.name}`] !== undefined
            ? formData[`includeSubfolder${g.name}`]
            : g.includeSubfolder,
        files: files,
      };
    });
    return {
      configName: formData.configName,
      fileGroups: fileGroups,
    };
  };

  const columns: ColumnsType<File> = [
    {
      title: t('name'),
      dataIndex: 'name',
      width: '30%',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: t('description'),
      dataIndex: 'description',
      width: '60%',
      sorter: {
        compare: (a, b) => a.description.localeCompare(b.description),
      },
      className: 'last-column',
    },
  ];

  const getRowSelection = (index: number): TableRowSelection<File> => {
    return {
      columnWidth: '15px',
      selectedRowKeys: selectedRowKeys[index],
      onChange: (selectedKeys: Key[]) => {
        selectedRowKeys[index] = [...selectedKeys];
        setSelectedRowKeys([...selectedRowKeys]);
      },
    };
  };

  return (
    <Form
      form={form}
      name="download-config-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <div className="download-service">
        <Form.Item
          {...layout}
          name="configName"
          label={t('name')}
          rules={[{ required: true, max: 32 }]}
          style={{ marginBottom: '10px' }}
        >
          <Input placeholder={t('name')} />
        </Form.Item>
        <Spin spinning={loading}>
          {data?.fileGroups.length !== 0 && (
            <Tabs defaultActiveKey={data?.fileGroups[0]?.name}>
              {data?.fileGroups.map((fileGroup, index) => (
                <TabPane
                  tab={`${fileGroup.name} (${fileGroup.aliasName})`}
                  key={fileGroup.name}
                >
                  <Row style={{ marginLeft: '20px' }}>
                    <Col flex="7">
                      <Form.Item
                        name={`localPath${fileGroup.name}`}
                        label={t('localPath')}
                        rules={[{ max: 200 }]}
                      >
                        <Input placeholder={t('localPath')} />
                      </Form.Item>
                    </Col>
                    <Col flex="3">
                      <Form.Item
                        style={{ marginLeft: '20px' }}
                        name={`includeSubfolder${fileGroup.name}`}
                        valuePropName="checked"
                        colon={false}
                      >
                        <Checkbox>{t('includeSubdirectory')}</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Table
                    size="small"
                    className="unselectable-table"
                    pagination={{
                      pageSize: 15,
                      showSizeChanger: false,
                      showTotal: (total) => t('totalItem', { total }),
                    }}
                    columns={columns}
                    dataSource={fileGroup.files}
                    rowSelection={getRowSelection(index)}
                    rowKey="name"
                    sortDirections={['ascend', 'descend', 'ascend']}
                  ></Table>
                </TabPane>
              ))}
            </Tabs>
          )}
          {(!data || data.fileGroups.length === 0) && (
            <Row style={{ height: '300px' }}>
              {!loading && <span>No Data</span>}
            </Row>
          )}
        </Spin>
      </div>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
