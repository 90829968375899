import { PageNotFound } from '@components/PageNotFound';
import { PermissionDeny } from '@components/PermissionDeny';
import { DefaultLayout } from '@layouts/DefaultLayout';
import { HomeLayout } from '@layouts/HomeLayout';
import { Layouts } from '@layouts/Layouts';
import { PermissionLayout } from '@layouts/PermissionLayout';
import { Home } from 'pages/Home';
import { DirectoryDownloadConfig } from 'pages/directory-download-config';
import { ConfigPage } from 'pages/directory-download-config/ConfigPage';
import { DirectoryManagement } from 'pages/directory-management';
import { DirectoryPreview } from 'pages/directory-preview';
import { DirectoryService } from 'pages/directory-service';
import { DownloadConfig } from 'pages/download-config';
import { DownloadService } from 'pages/download-service';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CheckAccessTimeRoute from './CheckAccssTimeRoute';

const routes = [
  {
    path: '/download-service',
    exact: true,
    component: DownloadService,
  },
  {
    path: '/download-config',
    exact: true,
    component: DownloadConfig,
  },
  {
    path: '/directory-service',
    exact: true,
    component: DirectoryService,
  },
  {
    path: '/directory-management',
    exact: true,
    component: DirectoryManagement,
  },
  {
    path: '/directory-preview',
    exact: true,
    component: DirectoryPreview,
  },
  {
    path: '/directory-download-config',
    exact: true,
    component: DirectoryDownloadConfig,
  },
  {
    path: '/directory-download-config/form/:configName',
    exact: true,
    component: ConfigPage,
  },
  {
    path: '/directory-download-config/form/',
    exact: true,
    component: ConfigPage,
  },
];

export const Routers: React.FC = () => (
  <Switch>
    <Route path={['/auth', '/']} exact>
      <Layouts layout={HomeLayout} isPublic={true}>
        <Route component={Home} />
      </Layouts>
    </Route>

    <Layouts layout={DefaultLayout} isPublic={true}>
      {routes.map((r) => {
        return (
          <CheckAccessTimeRoute
            key={r.path}
            path={r.path}
            component={r.component}
            exact={r.exact}
          />
        );
      })}
      <Route path={'/error/access'} exact component={PermissionDeny} />
    </Layouts>

    <Route path="*">
      <Layouts layout={PermissionLayout} isPublic={true}>
        <Route component={PageNotFound} />
      </Layouts>
    </Route>
  </Switch>
);

export default routes;
