import { usePermissionSearchContext } from '@contexts/PermissionSearchProvider';
import {
  CompanyPermission,
  PermissionTableField,
  PermisssionOf,
  UserPermission,
} from '@core/@models/DirectoryPermissionModel';
import { Pagable } from '@core/@models/DirectoryServiceModel';
import { ApiService } from '@core/services/api.service';
import { LanguageService } from '@core/services/language.service';
import { SelectedItem } from '@reducers/PermissionSearchReducer';
import { Space, Table } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
import { CompanyRequest, UserRequest } from '.';

export interface RootSearchResponse extends Pagable {
  content: Array<UserPermissionQuery | CompanyPermissionQuery>;
}

type UserPermissionQuery = UserPermission & {
  id: number;
};

type CompanyPermissionQuery = CompanyPermission & {
  id: number;
};

type ResultTableProps = Partial<TableProps<PermissionTableField>> & {
  hideModal: () => void;
  isPermissionOfUser: boolean;
};

type CustomSorter = {
  field: React.Key;
  order: SortOrder;
};
const DEFAULT_SORT_FIELD = 'fullName';
const DEFAULT_SORT_TYPE = 'ascend';

export const ResultTable: React.FC<ResultTableProps> = ({
  hideModal,
  isPermissionOfUser,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const timeRef = useRef<NodeJS.Timeout>();
  const {
    state: { search },
    dispatch,
  } = usePermissionSearchContext();

  const [data, setData] = useState<PermissionTableField[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    showSizeChanger: false,
    showTotal: (total) => t('common:totalItem', { total }),
  });

  useEffect(() => {
    if (!search.queryParam) return;
    const sorter: CustomSorter = {
      field: DEFAULT_SORT_FIELD,
      order: DEFAULT_SORT_TYPE,
    };
    const sort = getSortDescription(sorter);
    const param = Object.assign({}, { ...search.queryParam, sort });
    fetchData(param);
  }, [search.queryParam]);

  useEffect(() => {
    return () => {
      if (!timeRef.current) return;
      clearTimeout(timeRef.current);
    };
  }, []);

  const getSortDescription = (sorter: CustomSorter): string => {
    let sorterField = `${sorter.field}`;
    if (sorter.field === DEFAULT_SORT_FIELD) {
      if (isPermissionOfUser) {
        sorterField = lang === 'th' ? 'nameTh' : 'nameEn';
      } else {
        sorterField = lang === 'th' ? 'nameThai' : 'name';
      }
    }

    const abbrSortType = sorter.order?.substring(0, sorter.order.length - 3);
    const sort = `${sorterField},${abbrSortType}`;
    return sort;
  };

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    _: Record<string, React.Key[] | null>,
    sorter:
      | SorterResult<PermissionTableField>
      | SorterResult<PermissionTableField>[]
  ) => {
    const { current: page, pageSize: size } = tablePagination;
    if (Array.isArray(sorter)) return;
    const sortDescription: CustomSorter = {
      field: (sorter.field || DEFAULT_SORT_FIELD) as React.Key,
      order: sorter.order || DEFAULT_SORT_TYPE,
    };
    const sort = getSortDescription(sortDescription);
    setPagination(Object.assign({}, pagination, tablePagination));
    const param = Object.assign({}, { ...search.queryParam, page, size, sort });
    fetchData(param);
  };

  const apiService = React.useMemo(() => new ApiService(search.path), [
    search.path,
  ]);

  const transformData = (
    requestData: UserPermissionQuery | CompanyPermissionQuery
  ) => {
    const permissionOf = isPermissionOfUser
      ? PermisssionOf.Users
      : PermisssionOf.Companies;

    const newData = {
      ...requestData,
      permissionOf,
    };
    const result: PermissionTableField = {
      ...newData,
      key: `${newData.id}`,
      ownerId: newData.id,
      fullName: '',
      deletable: true,
    };
    switch (newData.permissionOf) {
      case PermisssionOf.Users: {
        const d = newData as UserPermissionQuery;
        const fullNameTh = `${d.nameTh} ${d.surnameTh}`;
        const fullNameEn = `${d.nameEn} ${d.surnameEn}`;

        result.fullName =
          lang === 'th' || requestData.nameEn === null
            ? fullNameTh
            : fullNameEn;
        break;
      }
      case PermisssionOf.Companies: {
        const d = newData as CompanyPermissionQuery;
        result.fullName = lang === 'th' ? `${d.nameTh} ` : `${d.nameEn}`;
        break;
      }
    }
    return result;
  };

  const fetchData = (queryParam: UserRequest | CompanyRequest) => {
    if (!search.path) return;
    setLoading(true);
    apiService
      .getDataPagable<RootSearchResponse>(queryParam)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: RootSearchResponse) => {
          const { content: content, totalElements: total } = result;
          const permissionTabledField = content.map(transformData);
          setData(permissionTabledField);
          const { page: current, size: pageSize } = queryParam;
          setPagination({ ...pagination, current, pageSize, total });
        },
      });
  };

  const handleOnClickRow = (record: PermissionTableField) => {
    dispatch(SelectedItem(record));
    timeRef.current = setTimeout(() => hideModal(), 1000);
  };

  const renderAction = (
    _: unknown,
    record: PermissionTableField
  ): React.ReactNode => {
    return (
      <Space size="small" style={{ marginRight: 5 }}>
        <a onClick={() => handleOnClickRow(record)}>{t('button.select')}</a>
      </Space>
    );
  };

  const addRenderAction: TableProps<PermissionTableField> = {
    columns: [
      ...(props.columns || []),
      {
        title: 'Action',
        align: 'center',
        className: 'last-column',
        width: 10,
        render: renderAction,
      },
    ],
  };

  return (
    <Table
      {...addRenderAction}
      size="small"
      className="selectable-table"
      id="result-table"
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={pagination}
      sortDirections={['ascend', 'descend', 'ascend']}
    />
  );
};
