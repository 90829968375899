import { finalize } from 'rxjs/operators';
import { convertByteToMB } from '@helpers/utils';
import { ButtonSet } from '@components/ButtonSet';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { ApiService } from '@core/services/api.service';
import React, { useEffect, useMemo, useState } from 'react';
import { UploadFileResponse } from '@core/@models/DirectoryServiceModel';
import { CustomService } from '@core/services/custom.service';
import { NotificationType, useNotification } from '@helpers/use-notification';
import { Form, Input, Upload, Button, Checkbox, Select, Row, Col } from 'antd';

export interface UploadFileFormProps {
  id?: React.Key;
  parentId?: React.Key;
  handleCancel: () => void;
  fetchData?: (param: { folderId: number; key: React.Key }) => void;
  relativeKey?: string;
}
export interface UploadFileForm {
  name: string;
  description: string;
  sendEmail: boolean;
  attachment: {
    file: File;
  };
  sendBccEmail: string;
}

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 18,
  },
};

export const UploadFileForm: React.FC<UploadFileFormProps> = ({
  id,
  parentId,
  relativeKey,
  handleCancel,
  fetchData,
}) => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const { open } = useNotification();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [loading, setLoading] = useState(false);
  const [isShowSendMailOption, setIsShowSendMailOption] = useState(false);
  const apiService = useMemo(() => new ApiService('/file-services/file'), []);

  useEffect(() => {
    form.setFieldsValue({ sendBccEmail: 'To' });
    if (!id) return;
    apiService
      .getDataById<string, UploadFileResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: UploadFileResponse) => {
          setLoading(true);
          setForm(result);
        },
      });
  }, [id]);

  const setForm = (result: UploadFileResponse) => {
    const file: File = new File([], result.name);
    const { name } = file;
    const uid = '';
    setFileList([{ ...file, name, uid }]);
    form.setFieldsValue({ ...result, sendBccEmail: 'To' });
  };

  const showErrorMessage = (maxSize: string) => {
    open({
      type: NotificationType.ERROR,
      description: t('errorMessage.fileMaxSize', { maxSize }),
      disableDate: true,
    });
  };

  const props = {
    beforeUpload: (file: UploadFile<File>) => {
      CustomService.getDataById<number>(
        `/file-services/folder/${parentId}/fileLimitSize`
      ).subscribe({
        next: (rootFolderSize: number) => {
          const isUnlimitSize = rootFolderSize === 0;
          const isMoreThan100MB = convertByteToMB(file.size) > 100;
          if (isUnlimitSize) {
            if (isMoreThan100MB) {
              showErrorMessage('100MB');
              return false;
            }
          } else {
            if (file.size / 1024 > rootFolderSize) {
              showErrorMessage(`${rootFolderSize}KB`);
              return false;
            }
          }
          fileList[0] = file;
          setFileList([...fileList]);
        },
      });

      if (!form.getFieldValue('name')) {
        form.setFieldsValue({
          name: file.name,
        });
      }
      return false;
    },
    fileList: fileList,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const onFinish = (data: UploadFileForm) => {
    const requestData = transformData(data);
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    setLoading(true);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        setLoading(true);
        fetchData &&
          fetchData({
            folderId: parentId as number,
            key: relativeKey || '',
          });
        handleCancel();
      },
    });
  };

  const transformData = (data: UploadFileForm): FormData => {
    const formData = new FormData();
    formData.append(
      'data',
      new File(
        [
          JSON.stringify({
            id: id,
            parentId: parentId,
            name: data.name ? data.name : data.attachment.file.name,
            description: data.description,
            sendEmail: data.sendEmail,
            sendBccEmail: data.sendEmail ? data.sendBccEmail === 'Bcc' : false,
          }),
        ],
        'data.json',
        {
          type: 'application/json',
        }
      )
    );

    if (data.attachment) {
      formData.append('attachFile', data.attachment.file);
    }
    return formData;
  };

  return (
    <Form
      {...layout}
      form={form}
      data-testid="upload-file-form"
      onFinish={onFinish}
      autoComplete="off"
      preserve={false}
      onClick={(e) => e.stopPropagation()}
    >
      <Form.Item
        label={t('file')}
        name="attachment"
        rules={[
          {
            required: id === undefined,
            message: t('errorMessage.pleaseSelectFile'),
          },
        ]}
      >
        <Upload {...props}>
          <Button
            icon={<UploadOutlined />}
            type="primary"
            ghost
            style={{ width: '120px' }}
            className="custom-btn"
          >
            {t('selectFile')}
          </Button>
        </Upload>
      </Form.Item>
      {id && (
        <Form.Item
          name="name"
          label={t('name')}
          rules={[{ required: true }, { max: 100 }]}
        >
          <Input placeholder={t('name')} />
        </Form.Item>
      )}
      <Form.Item
        name="description"
        label={t('description')}
        rules={[{ required: true }, { max: 200 }]}
      >
        <Input placeholder={t('description')} />
      </Form.Item>
      <Row style={{ marginLeft: '30px' }}>
        <Col flex="8">
          <Form.Item
            label=" "
            colon={false}
            name="sendEmail"
            valuePropName="checked"
          >
            <Checkbox
              onChange={() => setIsShowSendMailOption(!isShowSendMailOption)}
            >
              {t('sendEmail')}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col flex="2">
          <Form.Item colon={false} name="sendBccEmail">
            <Select
              disabled={!isShowSendMailOption}
              style={{ width: 80 }}
              options={[
                { label: 'To', value: 'To' },
                { label: 'Bcc', value: 'Bcc' },
              ]}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
