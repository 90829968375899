import { Button, Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { usePermissionContext } from '@contexts/PermissionProvider';
import {
  defaultItem,
  usePermissionSearchContext,
} from '@contexts/PermissionSearchProvider';
import { AddPermission, UpdatePermission } from '@reducers/PermissionReducer';
import { Types as TypesSearch } from '@reducers/PermissionSearchReducer';
import {
  DirectoryPermissionType,
  PermissionTable,
  PermissionTableField,
  PermisssionOf,
} from '@core/@models/DirectoryPermissionModel';
import { useTranslation } from 'react-i18next';
import { ButtonAdd } from '@components/ButtonAdd';
import { useModalManagement } from '@helpers/use-modal-management';
import { ModalCmp } from '@components/ModalCmp';
import { ResultTable } from './Search/ResultTable';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { CompanyForm } from './Search/CompanyForm';
import { UserForm } from './Search/UserForm';
import { SearchOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';
import { Search } from './Search';

const layoutBtnAdd = {
  display: 'flex',
  margin: '0 15px 0 0',
  width: 395,
};
const { Item } = Form;

export const AddForm: React.FC<{ permissionOf: keyof PermissionTable }> = ({
  permissionOf,
}) => {
  const { t } = useTranslation(['common']);
  const { visible, hideModal, showModal } = useModalManagement();
  const [form] = Form.useForm();
  const {
    state: { item },
    dispatch,
  } = usePermissionSearchContext();

  useEffect(() => {
    setForm();
  }, [item]);

  const {
    state: { dataSource, editingKey, permissionTypes },
    dispatch: dispatchPermission,
  } = usePermissionContext();

  const setForm = () => {
    if (!item) return;
    const data = {
      [`fullName-${permissionOf}`]: item.fullName,
      [`permissionType-${permissionOf}`]: undefined,
    };
    form.setFieldsValue(data);
  };

  const onFinish = (val: {
    [key: string]: string | DirectoryPermissionType;
  }) => {
    const permissionType = val[
      `permissionType-${permissionOf}`
    ] as DirectoryPermissionType;
    const data = { ...item, permissionType };
    const index = dataSource[permissionOf].findIndex((d) => d.key === item.key);
    const isAddExistData = index > -1;
    if (isAddExistData) {
      const newData = [...dataSource[permissionOf]];
      newData.splice(index, 1, data);
      dispatchPermission(UpdatePermission(newData, permissionOf));
    } else {
      dispatchPermission(AddPermission(data, permissionOf));
    }
    dispatch({
      type: TypesSearch.SELECTED_ITEM,
      payload: { item: defaultItem },
    });
  };

  const isPermissionOfUser: boolean = [
    PermisssionOf.Users,
    PermisssionOf.Owners,
  ].includes(permissionOf as PermisssionOf);

  const userColumns: ColumnsType<PermissionTableField> = [
    {
      title: t('loginName'),
      dataIndex: 'loginName',
      width: 70,
      sorter: true,
    },
    {
      title: t('name'),
      dataIndex: 'fullName',
      width: 100,
      sorter: true,
    },
  ];

  const companyColumns: ColumnsType<PermissionTableField> = [
    {
      title: t('symbol'),
      dataIndex: 'code',
      width: 50,
      sorter: true,
    },
    {
      title: t('name'),
      dataIndex: 'fullName',
      width: 100,
      sorter: true,
    },
  ];

  const tableProps: TableProps<PermissionTableField> = {
    columns: isPermissionOfUser ? userColumns : companyColumns,
  };

  const searchTitle = t(`button.search-${permissionOf}`);

  const buttonAddProps: ButtonProps = {
    type: 'dashed',
    style: { width: '98%' },
    icon: <SearchOutlined />,
    onClick: showModal,
  };

  const modalProps = {
    visible,
    title: searchTitle,
    handleCancel: hideModal,
    width: 650,
  };
  const serachForm = isPermissionOfUser ? <UserForm /> : <CompanyForm />;

  const clearSearchResult = () => {
    dispatch({
      type: TypesSearch.CLEAR_SEARCH,
    });
    hideModal();
  };

  const closeBtnProps: ButtonProps = {
    style: { width: 100 },
    type: 'primary',
    ghost: true,
    htmlType: 'button',
    onClick: clearSearchResult,
  };

  return (
    <>
      <Form
        form={form}
        style={{ display: 'flex', height: 40 }}
        onFinish={onFinish}
      >
        <Item
          name={`fullName-${permissionOf}`}
          rules={[
            {
              required: editingKey == '',
              message: t('errorMessage.required', {
                labelName: t(`${permissionOf}`),
              }),
            },
          ]}
        >
          {item.key ? (
            <Input
              style={{ margin: '0 15px 0 0', width: 395 }}
              readOnly={true}
            />
          ) : (
            <ButtonAdd layoutBtnAdd={layoutBtnAdd} buttonProps={buttonAddProps}>
              {searchTitle}
            </ButtonAdd>
          )}
        </Item>
        <Item
          name={`permissionType-${permissionOf}`}
          rules={[
            {
              required: editingKey == '',
              message: t('common:errorMessage.required', {
                labelName: t(`common:permissionType`),
              }),
            },
          ]}
        >
          <Select
            style={{ margin: 0, width: 130 }}
            options={permissionTypes}
            placeholder={t(`common:permissionType`)}
          ></Select>
        </Item>
        <Item style={{ margin: '0 0 0 5px' }}>
          <Button
            name={`btn-add-${permissionOf}`}
            type="link"
            htmlType="submit"
            size="large"
            icon={<PlusCircleOutlined />}
            disabled={editingKey !== ''}
            style={{ padding: 0, height: 30 }}
          />
        </Item>
      </Form>
      <ModalCmp {...modalProps}>
        <Search isPermissionOfUser={isPermissionOfUser}>{serachForm}</Search>
        <ResultTable
          isPermissionOfUser={isPermissionOfUser}
          hideModal={clearSearchResult}
          {...tableProps}
        />
        <div style={{ textAlign: 'right', marginTop: 10 }}>
          <Button data-testid="close" {...closeBtnProps}>
            {t('common:button.close')}
          </Button>
        </div>
      </ModalCmp>
    </>
  );
};
