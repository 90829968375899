import { ConfirmModal } from '@components/ModalCmp';
import { useSearchContext } from '@contexts/SearchProvider';
import {
  DeleteMulipleFilesRequest,
  DirectoryServiceResponseBody,
} from '@core/@models/DirectoryServiceModel';
import { CustomService } from '@core/services/custom.service';
import { LanguageService } from '@core/services/language.service';
import { useMultiDeleteDataFetching } from '@helpers/use-multi-delete-data-fetching';
import { useSearch } from '@helpers/use-search';
import { convertToDateShort } from '@helpers/utils';
import { Button, Checkbox, DatePicker, Form, Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

const { RangePicker } = DatePicker;

export interface DeleteMulipleFilesFormProps {
  id?: React.Key;
  parentId?: React.Key;
  handleCancel: () => void;
  fetchData?: (param: { folderId: number; key: React.Key }) => void;
  relativeKey?: string;
}
export interface DeleteMulipleFilesForm {
  name: string;
  description: string;
  attachment: {
    file: File;
  };
}

export interface FileResponse {
  id: number;
  name: string;
  description: string;
  parentId: number;
}

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 14,
  },
};

export const DeleteMulipleFilesForm: React.FC<DeleteMulipleFilesFormProps> = ({
  id,
  relativeKey,
  handleCancel,
  fetchData,
}) => {
  const { queryParam } = useSearchContext();
  const { data, loading, setData } = useMultiDeleteDataFetching(
    `/file-services/folder/${id}/multiple-delete`
  );
  const lang = LanguageService.getLanguage();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    handleNameChange,
    handleCalendarChange,
    handleDateChange,
    handleIncludeSubfolderChange,
    handleSearch,
    date,
    name,
    includeSubfolder,
  } = useSearch();

  const onFinish = () => {
    ConfirmModal({ onOk: onDelete });
  };

  const onDelete = () => {
    const { begin, end } = queryParam;
    const requestData: DeleteMulipleFilesRequest = {
      name,
      begin,
      end,
      includeSubfolder,
    };
    const action$ = CustomService.deleteData(
      `/file-services/folder/${id}/multiple-delete`,
      requestData
    );
    setDeleteLoading(true);
    action$.pipe(finalize(() => setDeleteLoading(false))).subscribe({
      next: () => {
        setDeleteLoading(true);
        fetchData &&
          fetchData({
            folderId: id as number,
            key: relativeKey || '',
          });
        handleCancel();
      },
    });
  };

  const getDateModifiedView = (value: string): React.ReactElement => (
    <>{convertToDateShort(value, lang)}</>
  );

  const columns: ColumnsType<DirectoryServiceResponseBody> = [
    {
      title: t('common:name'),
      dataIndex: 'name',
      width: 100,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: t('common:path'),
      dataIndex: 'path',
      width: 100,
      sorter: {
        compare: (a, b) => a.path.localeCompare(b.path),
      },
    },
    {
      title: t('common:description'),
      dataIndex: 'description',
      width: 100,
      sorter: {
        compare: (a, b) => a.description.localeCompare(b.description),
      },
    },
    {
      title: t('common:modifiedDate'),
      dataIndex: 'modified',
      width: 80,
      render: getDateModifiedView,
      sorter: {
        compare: (a, b) =>
          moment(a.modified).unix() - moment(b.modified).unix(),
      },
    },
    {
      title: t('common:size'),
      dataIndex: 'size',
      width: 50,
      className: 'last-column',
    },
  ];

  const onSearch = () => {
    if (!name) setData(undefined);
    handleSearch();
  };

  return (
    <Form
      {...layout}
      form={form}
      name="delete-multiple-files-form"
      onFinish={onFinish}
      autoComplete="off"
      preserve={false}
      onClick={(e) => e.stopPropagation()}
    >
      <Form.Item name="name" label={t('common:name')}>
        <Input
          style={{ width: '250px' }}
          placeholder={`${t('common:name')}...`}
          onChange={handleNameChange}
          value={name}
        />
      </Form.Item>
      <Form.Item name="modifiedDate" label={t('common:modifiedDate')}>
        <RangePicker
          style={{ width: '250px' }}
          placeholder={[`${t('common:begin')}...`, `${t('common:end')}...`]}
          ranges={{
            Today: [moment(), moment()],
          }}
          onChange={handleDateChange}
          onCalendarChange={handleCalendarChange}
          value={date}
        />
      </Form.Item>
      <Form.Item
        label=" "
        name="includeSubFolder"
        valuePropName="checked"
        colon={false}
      >
        <Checkbox
          onChange={handleIncludeSubfolderChange}
          checked={includeSubfolder}
        >
          <span style={{ marginRight: '5px' }}>
            {t('common:includeSubFolder')}
          </span>
        </Checkbox>
        <Button
          type="primary"
          onClick={onSearch}
          className="custom-btn"
          size="small"
        >
          {t('common:button.search')}
        </Button>
      </Form.Item>
      <Table
        className="unselectable-table"
        style={{ marginBottom: '24px' }}
        data-testid="file-list"
        size="small"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          showTotal: (total) => t('common:totalItem', { total }),
        }}
        sortDirections={['ascend', 'descend', 'ascend']}
      ></Table>
      <>
        <Button
          style={{ width: '80px' }}
          type="primary"
          ghost
          onClick={handleCancel}
          className="custom-btn"
        >
          {t('common:button.close')}
        </Button>
        <Button
          disabled={!data || !data.length}
          style={{ width: '80px', float: 'right' }}
          type="primary"
          htmlType="submit"
          loading={loading || deleteLoading}
          className="custom-btn"
        >
          {t('common:button.delete')}
        </Button>
      </>
    </Form>
  );
};
