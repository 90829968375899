import {
  DownloadConfigProver,
  useDownloadConfigContext,
} from '@contexts/DownloadConfigProverder';
import { Button, Col, Form, Layout, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigForm } from './ConfigForm';
import { ServerPathForm } from './ServerPathForm';
import { ServerPathTreePage } from './ServerPathTreePage';

const { Sider, Content } = Layout;

export const ConfigPage: React.FC = () => {
  return (
    <DownloadConfigProver>
      <ConfigPageLayout />
    </DownloadConfigProver>
  );
};

const ConfigPageLayout: React.FC = () => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const { state } = useDownloadConfigContext();
  const handleSave = () => {
    form.submit();
  };

  return (
    <Spin spinning={state.loading}>
      <Layout className="directory-download-config">
        <Row style={{ backgroundColor: 'white', padding: '0px 8px 8px' }}>
          <Col flex="auto">
            <Button
              type="primary"
              style={{ float: 'right', width: 80 }}
              onClick={handleSave}
              htmlType="button"
              className="custom-btn"
            >
              {t('button.save')}
            </Button>
          </Col>
        </Row>
        <Layout style={{ borderTop: '1px solid #FCB03480' }}>
          <Sider
            theme={'light'}
            width={500}
            style={{ borderRight: '1px solid #FCB03480' }}
          >
            <Row className="current-path-form">
              <Col flex="auto">
                <ServerPathForm />
              </Col>
            </Row>
            <Row>
              <Col flex="auto">
                <ServerPathTreePage />
              </Col>
            </Row>
          </Sider>
          <Layout style={{ width: 500 }}>
            <Content style={{ backgroundColor: 'white' }}>
              <ConfigForm form={form} />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  );
};
