import { PermissionTableField } from '@core/@models/DirectoryPermissionModel';
import {
  PermissionSearchActions,
  PermissionSearchReducer,
} from '@reducers/PermissionSearchReducer';
import {
  CompanyRequest,
  UserRequest,
} from 'pages/directory-service/ActionMenu/Permissions/RootFooterForm/Search';
import React, { useReducer } from 'react';

export interface PermissionSearchContextData {
  item: PermissionTableField;
  search: {
    path: string;
    queryParam: CompanyRequest | UserRequest | null;
  };
}
export type Dispatch = (action: PermissionSearchActions) => void;
export type PermissionSearchContextProps = {
  state: PermissionSearchContextData;
  dispatch: Dispatch;
};
export const PermissionSearchContext = React.createContext<
  PermissionSearchContextProps | undefined
>(undefined);

export const defaultItem: PermissionTableField = {
  key: '',
  permissionType: 'READ_WRITE',
  fullName: '',
  deletable: true,
};
export const initailState = {
  item: defaultItem,
  search: { path: '', queryParam: null },
};

export const usePermissionSearchContext = (): PermissionSearchContextProps => {
  const permissionSearchContext = React.useContext(PermissionSearchContext);
  if (!permissionSearchContext) {
    throw new Error(
      'usePermissionSearchContext must be used within the PermissionSearchContext.Provider'
    );
  }
  return permissionSearchContext;
};

export const PermissionSearchProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(PermissionSearchReducer, initailState);
  const value = { state, dispatch };
  return (
    <PermissionSearchContext.Provider value={value}>
      {children}
    </PermissionSearchContext.Provider>
  );
};
