import { DropdownProvider } from '@contexts/DropdownProvider';
import { IDirectoryTree } from '@core/@models/DirectoryServiceModel';
import { CustomService } from '@core/services/custom.service';
import { Col, Layout, Row } from 'antd';
import { ActionMenu } from 'pages/directory-service/ActionMenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
import { DirectoryTreePage } from './DirectoryTreePage';
import { RootFolderPage } from './RootFolderPage';

export const DirectoryManagement: React.FC = () => {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IDirectoryTree[]>([]);

  useEffect(() => {
    fetchExpandFolder();
  }, []);

  const fetchExpandFolder = () => {
    CustomService.getData<IDirectoryTree[]>(`/file-services/expand`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response) => {
          setData(response);
        },
      });
  };

  return (
    <DropdownProvider>
      <Layout>
        <Row className="current-path" style={{ height: 48, paddingTop: 8 }}>
          <Col flex="11" style={{ paddingTop: 4 }}>
            {t('Root Directory')}:
          </Col>
          <Col flex="84" style={{ marginTop: 4 }}>
            /scp/dat/directory_service
          </Col>
          <Col flex="5">
            <RootFolderPage fetchData={fetchExpandFolder} />
          </Col>
        </Row>
        <DirectoryTreePage
          loading={loading}
          data={data}
          fetchExpandFolder={fetchExpandFolder}
        />
      </Layout>
      <ActionMenu />
    </DropdownProvider>
  );
};
