import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
import { ApiService } from '@core/services/api.service';
import { Button, Form, Row } from 'antd';
import { convertToDateTimeShort } from '@helpers/utils';
import { LanguageService } from '@core/services/language.service';
import { PropertiesResponse } from '@core/@models/DirectoryServiceModel';

export interface PropertiesViewProps {
  id?: React.Key;
  handleCancel: () => void;
}

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 10,
  },
};

const propertiesName = [
  'name',
  'description',
  'type',
  'permissionType',
  'createDate',
  'modifiedDate',
  'creator',
  'size',
];

export const PropertiesView: React.FC<PropertiesViewProps> = ({
  id,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const apiService = useMemo(
    () => new ApiService('/file-services/properties/'),
    []
  );
  const [data, setData] = useState<any>({});
  const lang = LanguageService.getLanguage();

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    apiService
      .getDataById<string, PropertiesResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: PropertiesResponse) => {
          setData(result);
        },
      });
  }, [id]);

  return (
    <Form
      {...layout}
      className="properties-view"
      onFinish={handleCancel}
      preserve={false}
      onClick={(e) => e.stopPropagation()}
    >
      {propertiesName.map((p) => {
        if (p !== 'size' || (p === 'size' && data['type'] === 'FILE')) {
          return (
            <Form.Item key={p} label={t(`common:${p}`)}>
              {p === 'createDate' || p === 'modifiedDate'
                ? convertToDateTimeShort(data[p], lang)
                : p === 'permissionType'
                ? t(`common:${data[p]}`)
                : data[p]}
            </Form.Item>
          );
        }
      })}
      <Row className="button-set">
        <Button
          ghost
          loading={loading}
          style={{ width: '80px' }}
          type="primary"
          htmlType="submit"
          className="custom-btn"
        >
          {t('common:button.close')}
        </Button>
      </Row>
    </Form>
  );
};
