import { PermissionContextData } from '@contexts/PermissionProvider';
import {
  PermissionTable,
  PermissionTableField,
  OptionType,
} from '@core/@models/DirectoryPermissionModel';

const defaultPermission = {
  owners: [],
  users: [],
  companies: [],
  roles: [],
};
export const initailState = {
  dataSource: defaultPermission,
  deletedData: defaultPermission,
  editingKey: '',
  permissionTypes: [],
  companyRoles: [],
  roles: [],
};

export type Dispatch = (action: PermissionActions) => void;

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type PermissionPayload = {
  [Types.Initial]: {
    data: PermissionTable;
    permissionTypes: OptionType[];
    companyRoles: OptionType[];
    roles: OptionType[];
  };
  [Types.SetPermissionTypes]: {
    data: OptionType[];
  };
  [Types.Delete]: {
    param: { key: string; permissionOf: keyof PermissionTable };
  };
  [Types.Update]: {
    data: PermissionTableField[];
    permissionOf: keyof PermissionTable;
  };
  [Types.Add]: {
    data: PermissionTableField;
    permissionOf: keyof PermissionTable;
  };
  [Types.ClearDeletedItem]: undefined;
  [Types.ClearEditingKey]: undefined;
  [Types.SetEditingKey]: { key: string };
};

export type PermissionActions = ActionMap<PermissionPayload>[keyof ActionMap<PermissionPayload>];

export enum Types {
  Initial = 'INITIAL_PERMISSION',
  Delete = 'DELETE_PERMISSION',
  Add = 'ADD_PERMISSION',
  Update = 'UPDATE_PERMISSION',
  SetDeletedItem = 'SET_DELETED_ITEM',
  ClearDeletedItem = 'CLEAR_DELETED_ITEM',
  SetEditingKey = 'SET_EDITING_KEY',
  ClearEditingKey = 'CLEAR_EDITING_KEY',
  SetPermissionTypes = 'SET_PERMISSION_TYPES',
}

export const PermissionReducer = (
  state: PermissionContextData,
  action: PermissionActions
): PermissionContextData => {
  const { dataSource: data } = state;
  const newData: PermissionTable = { ...data };

  switch (action.type) {
    case Types.Initial:
      return {
        ...state,
        dataSource: action.payload.data,
        permissionTypes: action.payload.permissionTypes,
        companyRoles: action.payload.companyRoles,
        roles: action.payload.roles,
      };

    case Types.SetPermissionTypes:
      return { ...state, permissionTypes: action.payload.data };

    case Types.Delete: {
      const remainingRecord: PermissionTableField[] = newData[
        action.payload.param.permissionOf
      ].filter(
        (item: PermissionTableField) => action.payload.param.key !== item.key
      );
      const dataSource = Object.assign({}, data, {
        [action.payload.param.permissionOf]: remainingRecord,
      });

      const deletedItem = newData[action.payload.param.permissionOf]
        .filter(
          (item) => action.payload.param.key === item.key && item.permissionId
        )
        .map((item) => ({ ...item, ownerId: undefined, delete: true }));
      const deletedData = Object.assign({}, state.deletedData, {
        [action.payload.param.permissionOf]: [
          ...state.deletedData[action.payload.param.permissionOf],
          ...deletedItem,
        ],
      });
      return { ...state, dataSource, deletedData, editingKey: '' };
    }

    case Types.ClearDeletedItem:
      return { ...state, deletedData: defaultPermission };

    case Types.Update: {
      const dataSource = Object.assign({}, data, {
        [action.payload.permissionOf]: action.payload.data,
      });
      return { ...state, dataSource, editingKey: '' };
    }

    case Types.ClearEditingKey:
      return { ...state, editingKey: '' };

    case Types.SetEditingKey:
      return { ...state, editingKey: action.payload.key || '' };

    case Types.Add: {
      const dataSource = Object.assign({}, data, {
        [action.payload.permissionOf]: [
          ...data[action.payload.permissionOf],
          action.payload.data,
        ],
      });
      return { ...state, dataSource };
    }
    default:
      return state;
  }
};

export const AddPermission = (
  data: PermissionTableField,
  permissionOf: keyof PermissionTable
): PermissionActions => ({
  type: Types.Add,
  payload: { data, permissionOf },
});

export const UpdatePermission = (
  data: PermissionTableField[],
  permissionOf: keyof PermissionTable
): PermissionActions => ({
  type: Types.Update,
  payload: { data, permissionOf },
});

export const DeletePermission = (param: {
  key: string;
  permissionOf: keyof PermissionTable;
}): PermissionActions => ({
  type: Types.Delete,
  payload: { param },
});
