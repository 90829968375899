import {
  DirectoryPermissionType,
  OptionType,
  PermissionTableField,
  UserPermission,
} from '@core/@models/DirectoryPermissionModel';
import { Table, Button, Form, Select, Space, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { filterOption } from '@helpers/utils';
import {
  PermissionFormProps,
  usePermissionContext,
} from '@contexts/PermissionProvider';
import {
  AddPermission,
  DeletePermission,
  Types,
  UpdatePermission,
} from '@reducers/PermissionReducer';
import { NotificationType, useNotification } from '@helpers/use-notification';

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: string;
  record: UserPermission;
  index: number;
  children: React.ReactNode;
  options: OptionType[];
}
const { Item } = Form;

type FormField = {
  key: string;
  permissionType: DirectoryPermissionType;
};

export const PermissionForm: React.FC<PermissionFormProps> = ({
  loading,
  children,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const { open } = useNotification();

  const { state, dispatch } = usePermissionContext();
  const dataSource = state.dataSource[props.columns.permissionOf];
  const isEditing = (record: PermissionTableField): boolean =>
    record?.key === state.editingKey;

  const cancel = () => dispatch({ type: Types.ClearEditingKey });

  const edit = (record: PermissionTableField) => {
    form.setFieldsValue({ ...record });
    dispatch({
      type: Types.SetEditingKey,
      payload: { key: record.key },
    });
  };

  const save = (key: string) => {
    const permissionOf = props.columns.permissionOf;
    const row = form.getFieldsValue() as FormField;
    const newData = [...dataSource];
    const record = newData.find((item) => key === item.key);
    if (!record) return;
    const { value: ownerId = 0, label = '' } =
      props.columns.options.find((v) => v.value === row.key) || {};

    const [loginName, fullName] = label.split('-').map((v) => v.trim());
    const data: PermissionTableField = {
      ownerId: +ownerId,
      key: row.key,
      loginName,
      fullName,
      deletable: true,
      permissionType: row.permissionType,
    };
    const isUpdateSamePerson = key === row.key;
    if (isUpdateSamePerson) {
      if (record?.permissionId) {
        data.permissionId = record?.permissionId;
      }
      const index = newData.findIndex((item) => key === item.key);
      newData.splice(index, 1, data);
      dispatch(UpdatePermission(newData, permissionOf));
    } else {
      if (isExistData(newData, row.key)) return;
      const param = { key, permissionOf };
      dispatch(DeletePermission(param));
      dispatch(AddPermission(data, permissionOf));
    }
  };

  const isExistData = (data: PermissionTableField[], key: string): boolean => {
    const index = data.findIndex((d) => d.key === key && d.deletable);
    if (index > -1) {
      open({
        type: NotificationType.ERROR,
        description: 'มีรายการนี้อยู่แล้วในระบบ ไม่สามารถเพิ่มได้อีก',
        disableDate: true,
      }); //TODO:
      return true;
    }
    return false;
  };

  const deleteRecord = (key: string) => {
    const param = { key, permissionOf: props.columns.permissionOf };
    dispatch(DeletePermission(param));
  };

  const actionPanel = (record: PermissionTableField): React.ReactElement => {
    const editable = isEditing(record);
    const editAction = record.deletable && (
      <Space align="baseline" size={0}>
        {!props.isRoot && (
          <Tooltip title={t('actions.edit')}>
            <Button
              type="link"
              size="small"
              disabled={state.editingKey !== ''}
              icon={<FormOutlined />}
              onClick={() => edit(record)}
            />
          </Tooltip>
        )}
        <Tooltip title={t('actions.delete')}>
          <Button
            type="link"
            size="small"
            disabled={state.editingKey !== ''}
            icon={<DeleteOutlined />}
            onClick={() => deleteRecord(record.key)}
          />
        </Tooltip>
      </Space>
    );
    return editable ? (
      <Space align="baseline" size={0}>
        <Button
          type="link"
          size="small"
          icon={<SaveOutlined />}
          onClick={() => save(record.key)}
        />
        <Button
          type="link"
          size="small"
          icon={<CloseCircleOutlined />}
          onClick={cancel}
        />
      </Space>
    ) : (
      <>{editAction}</>
    );
  };

  const renderPermissionName = (value: DirectoryPermissionType) => {
    const permissionName = state.permissionTypes.find(
      (p) => p.value === `${value}`
    )?.label;
    return <>{permissionName}</>;
  };

  const permissionType = {
    title: 'permission',
    dataIndex: 'permissionType',
    width: '20%',
    editable: true,
    options: state.permissionTypes,
    render: renderPermissionName,
  };

  const actionColumn = [
    {
      width: '15%',
      dataIndex: '',
      title: '',
      options: '',
      editable: false,
      render: (_: unknown, record: PermissionTableField) => actionPanel(record),
    },
  ];

  const includePermissionTypes = [props.columns, permissionType];

  const columns = props.columns.handlable
    ? [...includePermissionTypes, ...actionColumn]
    : includePermissionTypes;

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: PermissionTableField) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        options: col.options,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    children: cellChildren,
    options,
    ...restProps
  }: EditableCellProps) => (
    <td {...restProps}>
      {editing ? (
        <Item name={dataIndex} style={{ margin: 0 }}>
          <Select
            optionFilterProp="options"
            options={options}
            showSearch
            filterOption={filterOption}
          />
        </Item>
      ) : (
        cellChildren
      )}
    </td>
  );

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  return (
    <Form form={form} component={false}>
      <Table
        size="small"
        className="permission-form"
        loading={loading}
        components={components}
        dataSource={dataSource}
        columns={mergedColumns}
        pagination={false}
        footer={() => children}
        title={() => props.title}
        locale={{
          emptyText: (
            <span className="empty-text">{t('No grant permission')}</span>
          ),
        }}
      />
    </Form>
  );
};
