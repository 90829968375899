import { useDownloadConfigContext } from '@contexts/DownloadConfigProverder';
import { IDirectoryTree } from '@core/@models/DirectoryServiceModel';
import { useTreeManagement } from '@helpers/use-tree-management';
import { Types } from '@reducers/DownloadConfigReducer';
import { Spin } from 'antd';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import {
  HandleUpdateTreeData,
  SEPERATOR,
} from 'pages/directory-service/DirectoryTreePage';
import React, { useEffect, useState } from 'react';

export const ServerPathTreePage: React.FC = () => {
  const [treeData, setTreeData] = useState<IDirectoryTree[]>([]);
  const { dispatch } = useDownloadConfigContext();

  useEffect(() => {
    fetchExpandFolder();
  }, []);

  const titleRender = (data: DataNode) => {
    const { name } = data as IDirectoryTree;
    return <span>{name}</span>;
  };

  const handleSelectPath = (path: string) => {
    dispatch({ type: Types.SelectPath, payload: { path } });
  };

  const fetchExpandFolder = () => {
    getExpandFolder$('').subscribe({
      next: (data) => {
        if (!data) return;
        const node = addRelativeKey({ data });
        setTreeData(node);
      },
    });
  };

  const onLoadData = ({ key, children, ...rest }: EventDataNode) => {
    const { id: folderId } = rest as IDirectoryTree;
    setLoading(true);
    const path = getCurrentPath(treeData, key, SEPERATOR);
    handleSelectPath(`${SEPERATOR}${path}`);
    return new Promise<void>((resolve) => {
      if (children) {
        setLoading(false);
        resolve();
        return;
      }
      getDataById({
        folderId,
        key,
        callback: resolve,
      });
    });
  };

  const {
    treeRef,
    loading,
    setLoading,
    addRelativeKey,
    updateTreeData,
    getData$,
    getExpandFolder$,
    handleOnClick,
    getCurrentPath,
    onSelect,
  } = useTreeManagement('/directory-download-services/expand', onLoadData);

  const getDataById = ({ folderId, key, callback }: HandleUpdateTreeData) => {
    getData$(folderId).subscribe({
      next: (data: IDirectoryTree[] | null) => {
        if (!data) return;
        const node = addRelativeKey({ parentKey: key, data });
        setTreeData((origin) => updateTreeData(origin, key, node));
        callback && callback();
      },
    });
  };

  return (
    <div className="custom-tree">
      <Spin spinning={loading}>
        <DirectoryTree
          ref={(ref) => (treeRef.current = ref)}
          className="directory-tree"
          treeData={treeData}
          blockNode={true}
          titleRender={titleRender}
          expandAction={false}
          loadData={onLoadData}
          onClick={handleOnClick}
          onSelect={onSelect}
        />
      </Spin>
    </div>
  );
};
