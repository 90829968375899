import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface IButtonSet {
  handleCancel: () => void;
  loading: boolean;
  onClick?: () => void;
  disabled?: boolean;
}
export const ButtonSet: React.FC<IButtonSet> = ({
  handleCancel,
  loading,
  ...props
}: IButtonSet) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        style={{ width: '80px' }}
        data-testid="close"
        type="primary"
        ghost
        className="custom-btn"
        htmlType="button"
        onClick={handleCancel}
      >
        {t('common:button.close')}
      </Button>
      <Button
        style={{ width: '80px', float: 'right' }}
        data-testid="save"
        type="primary"
        htmlType="submit"
        className="custom-btn"
        loading={loading}
        {...props}
      >
        {t('common:button.save')}
      </Button>
    </>
  );
};
