import {
  AttentionPublishResponse,
  AttentionPulishFile,
} from '@core/@models/AttentionModel';
import { Card, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { CustomService } from '@core/services/custom.service';
import { AttentionDownloadFile } from 'pages/attention-approve/AttentionDownloadFile';

export interface AttentionApproveFormProps {
  id: number | null;
}

export interface AttentionApproveFormState {
  headLine: string;
  content: string;
  attaches: AttentionPulishFile[];
}

export const AttentionPreview: React.FC<AttentionApproveFormProps> = ({
  id,
  children,
}) => {
  const [data, setData] = useState<AttentionApproveFormState>();

  useEffect(() => {
    getDataById();
  }, [id]);

  const getDataById = () => {
    CustomService.getDataById<AttentionPublishResponse>(
      `/attentions/${id}/view`
    ).subscribe({
      next: (result: AttentionPublishResponse) => {
        if (!result) return;
        const { headLine, content, attaches } = result;
        setData({ ...data, headLine, content, attaches });
      },
    });
  };

  return (
    <>
      <Card
        size="small"
        title={data?.headLine}
        style={{
          border: '1px solid #e7ecf1',
          borderRadius: '5px',
          boxShadow: '0px 2px 3px 2px rgb(0 0 0 / 3%)',
        }}
      >
        <Row
          style={{
            display: 'flex',
            alignItems: 'stretch',
          }}
        ></Row>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.content || '',
          }}
        />
        <AttentionDownloadFile
          id={id}
          attaches={data?.attaches}
        ></AttentionDownloadFile>
      </Card>
      {children}
    </>
  );
};
