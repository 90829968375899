import { QuestionCircleOutlined } from '@ant-design/icons';
import { ButtonSet } from '@components/ButtonSet';
import {
  DirectoryConfigForm,
  DirectoryServiceFolderRequest,
  DirectoryServiceFolderResponse,
  FolderType,
} from '@core/@models/DirectoryServiceModel';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { filterOption } from '@helpers/utils';
import { Checkbox, Form, Input, Select, Tag } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

interface DirectoryConfigSelctForm {
  times: { value: number; label: string }[];
  companyRoles: { value: string; label: string }[];
  permissionTypes: { value: string }[];
}

const configForm: DirectoryConfigSelctForm = {
  times: [],
  companyRoles: [],
  permissionTypes: [],
};

interface FolderFormProps {
  handleCancel: () => void;
  parentId?: React.Key;
  id?: React.Key;
  folderType: FolderType | undefined;
  fetchData?: (param: { folderId: number; key: React.Key }) => void;
  relativeKey?: string;
  isDynamicFolder: boolean;
}

const { Item } = Form;
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 16 },
};

export const FolderForm: React.FC<FolderFormProps> = ({
  parentId,
  isDynamicFolder,
  id,
  relativeKey,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [configData, setConfigData] = useState<DirectoryConfigSelctForm>(
    configForm
  );
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const apiService = useMemo(() => new ApiService('/file-services/folder'), []);
  const [currentPattern, setCurrentPattern] = useState<string>('upload');
  const [maxPattern, setMaxPattern] = useState<{
    max: number;
    lastState: string;
  }>({ max: 78, lastState: 'upload' });

  useEffect(() => {
    getConfigForm();
    if (!id) return;
    getDataById();
  }, [id]);

  const getDataById = () => {
    apiService
      .getDataById<string, DirectoryServiceFolderResponse>(`${id}`)
      .subscribe({
        next: (result: DirectoryServiceFolderResponse) => {
          form.setFieldsValue({
            ...result,
            companyRoles:
              result.companyRoles === null ? undefined : result.companyRoles,
          });
          setDisabled(!result.dynamicFolder);
          onChangePattern();
        },
      });
  };

  const initialConfigData = (result: DirectoryConfigForm) => {
    const {
      companyRoles: roles,
      times: data,
      permissionTypes: permissionList,
    } = result;
    const companyRoles = roles.map((c) => ({ value: c.key, label: c.value }));
    const times = data.map((time) => ({ value: time.id, label: time.name }));
    const permissionTypes = permissionList.map((p) => ({
      value: p,
      label: t(`${p}`),
    }));
    setConfigData({ companyRoles, times, permissionTypes });
  };

  const getConfigForm = () => {
    setConfigLoading(true);
    CustomService.getData<DirectoryConfigForm>('/file-services/config-form')
      .pipe(finalize(() => setConfigLoading(false)))
      .subscribe({
        next: (result: DirectoryConfigForm) => {
          initialConfigData(result);
        },
      });
  };

  const onFinish = (value: DirectoryServiceFolderRequest) => {
    const data = { ...value, id, parentId };
    const relativeParentKey = relativeKey?.split('-') || [];
    relativeParentKey.pop();
    const key = id ? relativeParentKey.join('-') : relativeKey || '';
    const action$ = id
      ? apiService.updateData(data)
      : apiService.createData(data);
    setLoading(true);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        props.fetchData &&
          props.fetchData({
            folderId: parentId as number,
            key,
          });
        props.handleCancel();
      },
    });
  };

  const tagRender = (tagRenderProps: {
    label: any;
    value: any;
    disabled: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
  }) => {
    const { label, closable, onClose } = tagRenderProps;

    return (
      <Tag
        color={'#FCB034'}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, borderRadius: 10 }}
      >
        {label}
      </Tag>
    );
  };

  const onChangePattern = (field?: string) => {
    handlePatternMaxLength(field);
    if (!form.getFieldValue('patternUpload')) {
      form.resetFields(['patternTransfer', 'targetUpload', 'timeId']);
      setCurrentPattern('upload');
    } else if (!form.getFieldValue('patternTransfer')) {
      form.resetFields(['targetUpload', 'timeId']);
      setCurrentPattern('transfer');
    } else if (currentPattern !== 'target') {
      if (configData.times[0]?.value) {
        form.setFieldsValue({
          timeId: configData.times[0]?.value,
        });
      }
      setCurrentPattern('target');
    }
  };

  const handlePatternMaxLength = (field?: string) => {
    if (!field) return;
    const uploadL = form.getFieldValue('patternUpload')?.length || 0;
    const transferL = form.getFieldValue('patternTransfer')?.length || 0;
    const targetL = form.getFieldValue('targetUpload')?.length || 0;
    setMaxPattern({
      max: uploadL + transferL + targetL > 148 ? 0 : 148,
      lastState: field,
    });
  };

  const handleDynamicFolder = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setDisabled(!checked);
    form.resetFields([
      'companyRoles',
      'patternName',
      'permissionType',
      'patternUpload',
      'patternTransfer',
      'targetUpload',
      'timeId',
    ]);
    setCurrentPattern('upload');
    setMaxPattern({
      max: 78,
      lastState: 'upload',
    });
    if (checked) {
      form.setFieldsValue({
        permissionType: configData.permissionTypes[0]?.value,
      });
    }
  };

  return (
    <Form
      form={form}
      data-testid="folder-form"
      {...formItemLayout}
      onFinish={onFinish}
      preserve={false}
    >
      <Item
        name="name"
        label={t('Folder name')}
        rules={[{ required: true }, { max: 100 }]}
      >
        <Input placeholder={t('Folder name')} />
      </Item>
      <Item
        name="description"
        label={t('Folder description')}
        rules={[{ required: true }, { max: 200 }]}
      >
        <Input placeholder={t('Folder description')} />
      </Item>
      <Item
        name="patternValidation"
        label={'Pattern validation'}
        rules={[
          {
            max: 80,
            message: t('common:errorMessage.maxLength', {
              max: 80,
              labelName: 'Pattern validation',
            }),
          },
        ]}
        tooltip={{
          title: 'example : $matchPattern $trnf2esbPattern',
          icon: <QuestionCircleOutlined />,
        }}
      >
        <Input
          disabled={isDynamicFolder}
          onChange={() => onChangePattern('upload')}
          placeholder={'Pattern upload'}
        />
      </Item>
      <Item
        name="dynamicFolder"
        label={'Dynamic folder'}
        valuePropName="checked"
      >
        <Checkbox onChange={handleDynamicFolder} disabled={!isDynamicFolder} />
      </Item>
      <Item
        name="companyRoles"
        label={t('common:companyRole')}
        rules={[{ required: !disabled }]}
      >
        <Select
          tagRender={tagRender}
          mode="multiple"
          showArrow
          showSearch
          optionFilterProp="options"
          options={configData.companyRoles}
          filterOption={filterOption}
          loading={configLoading}
          disabled={disabled}
          placeholder={t('common:companyRole')}
        />
      </Item>
      <Item
        name="patternName"
        label={'Pattern name'}
        rules={[{ required: !disabled }, { max: 80 }]}
        tooltip={{
          title:
            'possible param is code, codeTfex, companyId, partiId and companyType => example : Issuer ${code} - ${companyId}',
          icon: <QuestionCircleOutlined />,
        }}
      >
        <Input disabled={disabled} placeholder={'Pattern name'} />
      </Item>
      <Item name="permissionType" label={'Permission type'}>
        <Select
          optionFilterProp="options"
          options={configData.permissionTypes}
          filterOption={filterOption}
          loading={configLoading}
          disabled={disabled}
          placeholder={'Permission type'}
        />
      </Item>
      <Item
        name="patternUpload"
        label={'Pattern upload'}
        rules={[
          {
            max: maxPattern.max,
            message: t('common:errorMessage.maxPattern'),
          },
        ]}
        tooltip={{
          title: 'example : $matchPattern $trnf2esbPattern',
          icon: <QuestionCircleOutlined />,
        }}
      >
        <Input
          disabled={
            disabled || (!maxPattern.max && maxPattern.lastState !== 'upload')
          }
          onChange={() => onChangePattern('upload')}
          placeholder={'Pattern upload'}
        />
      </Item>
      <Item
        name="patternTransfer"
        label={'Pattern transfer'}
        rules={[
          {
            max: maxPattern.max,
            message: t('common:errorMessage.maxPattern'),
          },
        ]}
        tooltip={{
          title: 'example : $matchPattern $trnf2esbPattern',
          icon: <QuestionCircleOutlined />,
        }}
      >
        <Input
          disabled={
            disabled ||
            currentPattern === 'upload' ||
            (!maxPattern.max && maxPattern.lastState !== 'transfer')
          }
          onChange={() => onChangePattern('transfer')}
          placeholder={'Pattern transfer'}
        />
      </Item>
      <Item name="timeId" label={'Time upload'}>
        <Select
          showSearch
          optionFilterProp="options"
          options={configData.times}
          filterOption={filterOption}
          loading={configLoading}
          disabled={
            disabled ||
            currentPattern !== 'target' ||
            (!maxPattern.max && maxPattern.lastState !== 'target')
          }
          placeholder={'Time upload'}
        />
      </Item>
      <Item
        name="targetUpload"
        label={'Target upload'}
        rules={[
          {
            max: maxPattern.max,
            message: t('common:errorMessage.maxPattern'),
          },
        ]}
      >
        <Input
          disabled={
            disabled ||
            currentPattern !== 'target' ||
            (!maxPattern.max && maxPattern.lastState !== 'target')
          }
          onChange={() => onChangePattern('target')}
          placeholder={'Target upload'}
        />
      </Item>
      <ButtonSet
        disabled={!maxPattern.max}
        handleCancel={props.handleCancel}
        loading={loading}
      />
    </Form>
  );
};
