import React, { useEffect, useState } from 'react';
import { Card, Row, Spin, Table, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { CustomService } from '@core/services/custom.service';
import {
  DownloadServiceResponse,
  DownloadServiceSlot,
} from '@core/@models/DownloadServiceModel';
import { finalize } from 'rxjs/operators';
import FileSaver from 'file-saver';
import { AxiosResponse } from 'axios';
import { NotificationType, useNotification } from '@helpers/use-notification';
const { TabPane } = Tabs;

export const DownloadService: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DownloadServiceResponse[]>([]);
  const { open } = useNotification();

  useEffect(() => {
    setLoading(true);
    CustomService.getData<DownloadServiceResponse[]>('/download-services/view')
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DownloadServiceResponse[]) => setData(result),
      });
  }, []);

  const getDownloadView = (
    name: string | '',
    slots: (DownloadServiceSlot | null)[],
    index: number
  ): React.ReactElement => (
    <>
      {slots[index]?.files.map((f) => (
        <p key={f.display} style={{ margin: '0px' }}>
          {f.linkId ? (
            <a onClick={() => downloadFile(name, f.linkId)}>{f.display}</a>
          ) : (
            f.display
          )}
        </p>
      ))}
    </>
  );

  const downloadFile = (name: string, linkId?: number) => {
    setLoading(true);
    CustomService.download(`/download-services/download/${name}/${linkId}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: AxiosResponse<Blob>) => {
          const { data, headers } = result;
          const fileName = headers['content-disposition']
            .split(';')[1]
            .split('=')[1]
            .replace('"', '')
            .replace('"', '');
          const blob = new Blob([data]);
          FileSaver.saveAs(blob, fileName);
        },
        error: () => {
          open({
            type: NotificationType.ERROR,
            description: t('common:downloadFailed'),
            disableDate: true,
          });
        },
      });
  };

  const getColumns = (
    response: DownloadServiceResponse
  ): ColumnsType<(DownloadServiceSlot | null)[]> => {
    const columns: ColumnsType<(DownloadServiceSlot | null)[]> = [];
    const rows = response.rows.length === 0 ? [] : response.rows[0];
    const headers: any[] =
      response.headers.length === 0 ? rows : response.headers;
    headers.forEach((h, index) => {
      if (!h) return [];
      columns.push({
        title: h['description'],
        render: (_, record: (DownloadServiceSlot | null)[]) =>
          getDownloadView(response.name, record, index),
        width: 100,
        className: index === headers.length - 1 ? 'last-column' : undefined,
      });
    });
    return columns;
  };

  return (
    <Card className="download-service">
      <Spin spinning={loading}>
        {data.length !== 0 && (
          <Tabs defaultActiveKey={data[0]?.name}>
            {data.map((d) => (
              <TabPane tab={`${d.aliasName}`} key={d.name}>
                <Table
                  showHeader={d.headers.length !== 0}
                  size="small"
                  className="unselectable-table"
                  pagination={{
                    pageSize: 15,
                    showSizeChanger: false,
                    showTotal: (total) => t('common:totalItem', { total }),
                  }}
                  columns={getColumns(d)}
                  dataSource={d.rows}
                />
              </TabPane>
            ))}
          </Tabs>
        )}
        {data.length === 0 && (
          <Row style={{ height: '500px' }}>
            {!loading && <span>No Data</span>}
          </Row>
        )}
      </Spin>
    </Card>
  );
};
