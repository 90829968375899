import { Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
const { Item } = Form;
export const CompanyForm: React.FC = () => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <Item name="symbol" label={t('symbol')}>
        <Input placeholder={`${t('symbol')}...`} />
      </Item>
      <Item name="name" label={t('name')}>
        <Input placeholder={`${t('name')}...`} />
      </Item>
    </>
  );
};
