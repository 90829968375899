import { ButtonAdd } from '@components/ButtonAdd';
import { ModalCmp } from '@components/ModalCmp';
import { useModalManagement } from '@helpers/use-modal-management';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RootFolderForm } from './RootFolderForm';
type RootFolderPageProps = {
  fetchData: () => void;
};
const layoutBtnAdd = {
  display: 'flex',
};

export const RootFolderPage: React.FC<RootFolderPageProps> = ({ ...props }) => {
  const { t } = useTranslation(['common']);
  const { visible, hideModal, showModal } = useModalManagement();

  const modalProps = {
    visible,
    title: t('actions.create root folder'),
    handleCancel: hideModal,
    width: 650,
  };

  return (
    <>
      <ButtonAdd showModal={showModal} layoutBtnAdd={layoutBtnAdd}>
        {t('button.add')}
      </ButtonAdd>
      <ModalCmp {...modalProps}>
        <RootFolderForm handleCancel={hideModal} fetchData={props.fetchData} />
      </ModalCmp>
    </>
  );
};
