import {
  CheckOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useDownloadConfigContext } from '@contexts/DownloadConfigProverder';
import {
  DirectoryDownloadConfig,
  DirectoryDownloadConfigResponseBody,
  Path,
} from '@core/@models/DirectoryDownloadConfigModel';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { Types } from '@reducers/DownloadConfigReducer';
import { Button, Form, Input, Modal, Space, Table, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

const { Item } = Form;
const formItemLayout = {
  labelCol: {
    lg: {
      span: 6,
    },
  },
};
export interface ConfigFormProps {
  form: FormInstance<DirectoryDownloadConfig>;
}

interface ValidatedField {
  configName: ValidateStatus;
  localPath: ValidateStatus;
}

export const ConfigForm: React.FC<ConfigFormProps> = ({ form }) => {
  const { t } = useTranslation(['common']);
  const { configName } = useParams<{ configName?: string }>();
  const [validatedField, setValidateField] = useState<ValidatedField>({
    configName: '',
    localPath: '',
  });
  const apiService = useMemo(
    () => new ApiService('/directory-download-services/config'),
    []
  );
  const { state, dispatch } = useDownloadConfigContext();
  const { paths } = state.data;
  const history = useHistory();
  const timeRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!configName) {
      dispatch({
        type: Types.SetLoading,
        payload: { loading: false },
      });
      return;
    }
    getDataById();
  }, [configName]);

  useEffect(() => {
    return () => {
      if (!timeRef.current) return;
      clearTimeout(timeRef.current);
    };
  }, []);

  const getDataById = () => {
    CustomService.getData<DirectoryDownloadConfigResponseBody>(
      `/directory-download-services/config?configName=${configName}`
    ).subscribe({
      next: (response: DirectoryDownloadConfigResponseBody) => {
        const paths = response.paths.filter((v) => !v.delete) || [];
        const data = { ...response, paths };
        dispatch({
          type: Types.InitialData,
          payload: { data, loading: false },
        });
        form.setFieldsValue(data);
      },
    });
  };

  const handleDelete = (index: number) => {
    dispatch({ type: Types.Delete, payload: { index } });
  };

  const renderAction = (
    _: unknown,
    record: Path,
    index: number
  ): React.ReactNode => {
    return (
      <Tooltip title={t('button.delete')}>
        <Button
          type="link"
          size="small"
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(index)}
        />
      </Tooltip>
    );
  };

  const renderIncludeSubFolder = (val: boolean): React.ReactNode => {
    return val && <CheckOutlined style={{ color: '#FCB034' }} />;
  };

  const renderPath = (val: string, record: Path): React.ReactNode => {
    return (
      <PathStatus valid={record.validPath}>
        <Space align="start">
          {val}
          <ToolTipInfo valid={record.validPath}>
            <Tooltip
              placement="topLeft"
              title={t('pathUnavailable')}
              color="#e86a6a"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </ToolTipInfo>
        </Space>
      </PathStatus>
    );
  };

  const columns: ColumnsType<Path> = [
    {
      title: t('path'),
      dataIndex: 'path',
      render: renderPath,
    },
    {
      title: t('includeSubdirectory'),
      dataIndex: 'includeSubfolder',
      render: renderIncludeSubFolder,
      align: 'center',
    },
    {
      title: 'Action',
      align: 'center',
      className: 'last-column',
      render: renderAction,
    },
  ];

  const getValidateStatus = (name: string): ValidateStatus => {
    const value = form.getFieldValue(name);
    if (value === undefined) {
      return 'error';
    }
    if (`${value}`.trim().length === 0) {
      return 'error';
    }
    return '';
  };

  const getInvalidField = (fields: ValidatedField) => {
    const errorMsgPaths = t('errorMessage.required', {
      labelName: t('path'),
    });
    const isInvalidPaths = paths.length === 0;
    const errorMsssages = Object.entries(fields).map(([key, value]) =>
      value === 'error'
        ? t('errorMessage.required', { labelName: t(`${key}`) })
        : ''
    );
    const result = isInvalidPaths
      ? [...errorMsssages, errorMsgPaths]
      : errorMsssages;
    return result.filter((v) => !!v).map((v, i) => <div key={i}>- {v}</div>);
  };

  const onFinish = (values: DirectoryDownloadConfig) => {
    const result = {
      configName: getValidateStatus('configName'),
      localPath: getValidateStatus('localPath'),
    };
    setValidateField(result);
    const fields = getInvalidField(result);
    if (fields.length > 0) {
      warning(fields);
      return;
    }
    dispatch({ type: Types.AddForm, payload: { data: values } });
    handleSaveData();
  };

  const goToForm = () => {
    const path = '/directory-download-config';
    history.push(path);
  };

  const handleSaveData = () => {
    const paths = [...state.data.paths, ...state.deletedData];
    const data = Object.assign(
      {},
      state.data,
      { paths },
      form.getFieldsValue()
    );
    apiService.createData(data).subscribe({
      next: () => {
        timeRef.current = setTimeout(() => goToForm(), 5000);
      },
    });
  };

  const onValuesChange = (changedValues: any) => {
    const key = Object.keys(changedValues)[0] as keyof ValidatedField;
    const result = { ...validatedField, ...{ [key]: getValidateStatus(key) } };
    setValidateField(result);
  };

  const warning = (content: React.ReactNode) => {
    Modal.warning({
      title: t('failedSave'),
      content: content,
      okText: t('button.ok'),
      autoFocusButton: null,
      okButtonProps: { style: { float: 'right' } },
    });
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        autoComplete="off"
        className="current-path-form"
        style={{ borderBottom: '0', minHeight: '115px' }}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Item
          name="configName"
          label={t('configName')}
          style={{ marginBottom: 8 }}
          validateStatus={validatedField.configName}
        >
          <Input size="small" placeholder={t('configName')} />
        </Item>
        <Item
          name="localPath"
          label={t('localPath')}
          style={{ marginBottom: 8 }}
          validateStatus={validatedField.localPath}
        >
          <Input size="small" placeholder={t('localPath')} />
        </Item>
      </Form>
      <Table
        size="small"
        columns={columns}
        className="permission-form unselectable-table no-padding"
        style={{ fontSize: '12px' }}
        dataSource={paths}
        rowKey={(record) => record.path}
      />
    </>
  );
};

const PathStatus = styled.span<{ valid: boolean }>`
  color: ${({ valid }) => !valid && '#e86a6a'};
`;

const ToolTipInfo = styled.span<{ valid: boolean }>`
  display: ${({ valid }) => (valid ? 'none' : 'inline-block')};
`;
