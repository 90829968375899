import { PermissionSearchContextData } from '@contexts/PermissionSearchProvider';
import { PermissionTableField } from '@core/@models/DirectoryPermissionModel';
import {
  CompanyRequest,
  UserRequest,
} from 'pages/directory-service/ActionMenu/Permissions/RootFooterForm/Search';
import { ActionMap } from './PermissionReducer';
export enum Types {
  SEARCH_RESULT = 'SEARCH_RESULT',
  SELECTED_ITEM = 'SELECTED_ITEM',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
}
type PermissionSearchPayload = {
  [Types.SEARCH_RESULT]: {
    search: {
      path: string;
      queryParam: CompanyRequest | UserRequest;
    };
  };
  [Types.SELECTED_ITEM]: {
    item: PermissionTableField;
  };
  [Types.CLEAR_SEARCH]: undefined;
};
export type PermissionSearchActions = ActionMap<PermissionSearchPayload>[keyof ActionMap<PermissionSearchPayload>];

export const PermissionSearchReducer = (
  state: PermissionSearchContextData,
  action: PermissionSearchActions
): PermissionSearchContextData => {
  switch (action.type) {
    case Types.SELECTED_ITEM:
      return { ...state, item: action.payload.item };
    case Types.CLEAR_SEARCH:
      return { ...state, search: { path: '', queryParam: null } };
    case Types.SEARCH_RESULT:
      return {
        ...state,
        search: {
          queryParam: action.payload.search.queryParam,
          path: action.payload.search.path,
        },
      };
    default:
      return state;
  }
};

export const SelectedItem = (
  item: PermissionTableField
): PermissionSearchActions => ({
  type: Types.SELECTED_ITEM,
  payload: { item },
});

export const ClearSearch = (): PermissionSearchActions => ({
  type: Types.CLEAR_SEARCH,
});
