import { useDropdownContext } from '@contexts/DropdownProvider';
import {
  FolderType,
  IDirectoryTree,
} from '@core/@models/DirectoryServiceModel';
import { CustomService } from '@core/services/custom.service';
import { useTreeManagement } from '@helpers/use-tree-management';
import { Popover, Spin, Tree } from 'antd';
import { Key } from 'antd/lib/table/interface';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DirectoryListSubject$ } from './DirectoryList';
import { MoreButton } from './MoreButton';

export const subject$ = new Subject<DirectoryTreeSubject>();
export const direct$ = new BehaviorSubject<string | undefined>(undefined);
export interface DirectoryTreeSubject {
  folderId: Key;
  key: Key;
  folders: IDirectoryTree[];
  folderType: FolderType;
  actions: string[];
}

const { DirectoryTree } = Tree;
export const SEPERATOR = '/';

interface DirectoryTreePageProps {
  handleCurrentPath: (path: string) => void;
}

export interface HandleUpdateTreeData {
  folderId: Key;
  key: Key;
  callback?: () => void;
  isEmittedData?: boolean;
}

export const DirectoryTreePage: React.FC<DirectoryTreePageProps> = (props) => {
  const [treeData, setTreeData] = useState<IDirectoryTree[]>([]);
  const [selectedKey, setSelectedKey] = useState<Key>('');
  const [customExpandedKeys, setCustomExpandedKeys] = useState<Key[]>([]);

  const isEmitData = useRef(false);
  const isRefreshData = useRef(false);
  const latestHierachyFolder = useRef<string>('');
  const latestRelativeKey = useRef<string>('');
  const subscription = useRef<Subscription>();

  const { setItem } = useDropdownContext();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const fileId = urlSearchParams.get('fileId') || '';

  const onLoadData = ({ key, children, ...rest }: EventDataNode) => {
    const { id: folderId } = rest as IDirectoryTree;
    setLoading(true);
    return new Promise<void>((resolve) => {
      if (children) {
        setLoading(false);
        resolve();
        return;
      }
      getDataById({
        folderId,
        key,
        callback: resolve,
        isEmittedData: isEmitData.current,
      });
    });
  };

  const {
    treeRef,
    cacheDataRef,
    cacheGetDataRef$,
    loading,
    prevSelectedKeyRef,
    setLoading,
    addRelativeKey,
    updateTreeData,
    getData$,
    addExpandedKeys,
    generateSelectedExpanedNode,
    showSelectedExpanedNode,
    getCurrentPath,
    onSelect,
  } = useTreeManagement('/file-services/expand', onLoadData);

  useEffect(() => {
    fetchData();
    handleSelectedKeyFileManagement();
    return () => {
      if (!subscription.current) {
        return;
      }
      subscription.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!fileId) return;
    getFolderHierachy();
  }, [fileId]);

  useEffect(() => {
    const result = getCurrentPath(treeData, selectedKey);
    props.handleCurrentPath(result);
  }, [selectedKey]);

  const getFolderHierachy = () => {
    CustomService.getDataById<string[]>(
      `/file-services/folder/hierarchy/${fileId}`
    ).subscribe({
      next: (response: string[]) => {
        latestHierachyFolder.current = response[response.length - 1];
        latestRelativeKey.current = response.join('-');
        const relativeKeys = setRelativeKeys(response);
        const { expandedKeys, selectedKeys } = treeRef.current.state;
        onSelect([latestRelativeKey.current]);
        selectedKeys.splice(0, 1, latestRelativeKey.current);
        expandedKeys.push(...expandedKeys, ...relativeKeys);
        setCustomExpandedKeys(relativeKeys);
      },
    });
  };

  const setRelativeKeys = (folderIds: string[]): string[] => {
    const result: string[] = [];
    folderIds.forEach((folderId, i) => {
      if (i === 0) {
        result[i] = `${folderId}`;
      } else {
        result[i] = `${result[i - 1]}-${folderId}`;
      }
    });
    return result;
  };

  const fetchData = () => {
    setLoading(true);
    getData$().subscribe({
      next: (data: IDirectoryTree[] | null) => {
        if (!data) return;
        const node = addRelativeKey({ data });
        setTreeData(node);
      },
    });
  };

  const getDataById = ({
    folderId,
    key,
    callback,
    isEmittedData,
  }: HandleUpdateTreeData) => {
    const isLatestHierachyFolder = folderId === latestHierachyFolder.current;
    getData$(folderId).subscribe({
      next: (data: IDirectoryTree[] | null) => {
        if (!data) return;
        const node = addRelativeKey({ parentKey: key, data });

        if (isEmittedData || isLatestHierachyFolder) {
          const { folderType, actions } = treeRef.current.state.keyEntities[
            key
          ].node;
          subject$.next({ folderId, key, folders: node, folderType, actions });
          if (isLatestHierachyFolder) {
            const result = getCurrentPath(treeData, latestRelativeKey.current);
            props.handleCurrentPath(result);
            direct$.next(fileId);
          }
        }
        setTreeData((origin) => updateTreeData(origin, key, node));
        callback && callback();
      },
    });
  };

  const forceFetchData = ({
    folderId,
    key,
  }: {
    folderId: number;
    key: Key;
  }) => {
    const { expandedKeys, selectedKeys } = treeRef.current.state;
    isRefreshData.current = true;
    if (expandedKeys.indexOf(key) === -1) expandedKeys.push(key);
    cacheDataRef.current = null;
    cacheGetDataRef$.current = null;
    selectedKeys.splice(0, expandedKeys.length + 1, `${key}`);
    const result = getCurrentPath(treeData, key);
    props.handleCurrentPath(result);
    getDataById({
      folderId,
      key,
      isEmittedData: true,
    });
  };

  const handleOnSelect = (selectedKeys: React.Key[]) => {
    onSelect(selectedKeys);
    const key = [...selectedKeys].shift() || '';
    const result = getCurrentPath(treeData, key);
    props.handleCurrentPath(result);
  };

  const handleSelectedKeyFileManagement = () => {
    subscription.current = DirectoryListSubject$.subscribe({
      next: ({
        folderId,
        key,
        fetchFolder,
      }: {
        folderId: Key;
        key: Key;
        fetchFolder?: boolean;
      }) => {
        if (fetchFolder) {
          cacheDataRef.current = null;
          cacheGetDataRef$.current = null;
        }
        isRefreshData.current = true;
        const { expandedKeys, selectedKeys } = treeRef.current.state;
        expandedKeys.push(key);
        selectedKeys.splice(0, expandedKeys.length + 1, `${key}`);
        setSelectedKey(key);
        generateSelectedExpanedNode(expandedKeys, key);
        getDataById({ folderId, key, isEmittedData: true });
      },
    });
  };

  const handleOnClickTree = (
    _: React.MouseEvent<HTMLSpanElement>,
    node: EventDataNode
  ) => {
    const { expandedKeys, selectedKeys } = treeRef.current.state;
    const currSelctedKey = node.key;

    addExpandedKeys(expandedKeys, selectedKeys, currSelctedKey);

    const prevSelectedKey = prevSelectedKeyRef.current;
    isEmitData.current =
      currSelctedKey !== prevSelectedKey ||
      (currSelctedKey === prevSelectedKey && isRefreshData.current);
    //กรณีcreate folder เมื่อกดที่ node เดิมต้อง emit data ออกไป
    if (currSelctedKey === prevSelectedKey && isRefreshData.current) {
      isRefreshData.current = false;
    }

    showSelectedExpanedNode(expandedKeys, currSelctedKey, node);
  };

  const onSelectNode = (node: {
    ref: HTMLButtonElement | null;
    data: DataNode;
  }) => {
    const { id, actions, folderType } = node.data as IDirectoryTree;
    const { flattenNodes } = treeRef.current.state;
    const flattenNode = flattenNodes.find(
      (dataNode: { id: number }) => dataNode.id === id
    );
    const parentId = flattenNode?.parent?.id || id;
    const key = flattenNode?.key;
    const data = {
      id,
      parentId,
      actions,
      folderType,
      relativeKey: `${key}`,
      fetchData: forceFetchData,
    };
    const result = Object.assign({}, node, { data });
    setItem(result);
  };

  const titleRender = (data: DataNode) => {
    const { description, fileLimitSize, name } = data as IDirectoryTree;
    const content = `${description} (File limit size: ${fileLimitSize})`;
    return (
      <>
        <Popover
          destroyTooltipOnHide={{ keepParent: false }}
          content={<span style={{ fontSize: '12px' }}>{content}</span>}
        >
          {name}
        </Popover>
        <MoreButton {...data} onClick={onSelectNode} />
      </>
    );
  };

  const handleOnExpand = (expandedKeys: Key[]) => {
    isEmitData.current = false;
    setCustomExpandedKeys([...expandedKeys]);
  };

  return (
    <div className="custom-tree">
      <Spin spinning={loading}>
        <DirectoryTree
          ref={(ref) => (treeRef.current = ref)}
          className="directory-tree"
          loadData={onLoadData}
          onExpand={handleOnExpand}
          treeData={treeData}
          onSelect={handleOnSelect}
          titleRender={titleRender}
          onClick={handleOnClickTree}
          blockNode={true}
          expandAction={false}
          expandedKeys={customExpandedKeys}
        />
      </Spin>
    </div>
  );
};
