import { useSearchContext } from '@contexts/SearchProvider';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import { useReducer } from 'react';

interface useSearchData {
  date: [moment.Moment | null, moment.Moment | null] | undefined;
  status: string;
  name: string;
  description: string;
  url: string;
  newUrl: string;
  search: string;
  includeSubfolder: boolean;
  loginName: string;
  marketScope: string;
  companyName: string;
  companyType: string;
  handleCalendarChange: (values: any, formatString: [string, string]) => void;
  handleDateChange: (values: any, formatString: [string, string]) => void;
  handleStatusChange: (status: string) => void;
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUrlChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNewUrlChange: (value: string) => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleIncludeSubfolderChange: (event: CheckboxChangeEvent) => void;
  handleLoginNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMarketScopeChange: (value: string) => void;
  handleCompanyNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCompanyTypeChange: (value: string) => void;
  handleSearch: () => void;
  handleReset: () => void;
}

const ACTIONS = {
  UPDATE_STATUS: 'update-status',
  UPDATE_PUBLISH_DATE: 'update-publis-date',
  UPDATE_NAME: 'update-name',
  UPDATE_DESCRIPTION: 'update-description',
  UPDATE_URL: 'update-url',
  UPDATE_NEW_URL: 'update-new-url',
  UPDATE_SEARCH: 'update-search',
  UPDATE_INCLUDE_SUBFOLDER: 'update-include-subfolder',
  UPDATE_LOGIN_NAME: 'update-login-name',
  UPDATE_MARKET_SCOPE: 'update-market-scope',
  UPDATE_COMPANY_NAME: 'update-company-name',
  UPDATE_COMPANY_TYPE: 'update-company-type',
  RESET: 'reset',
};

const initialState: SearchState = {
  begin: null,
  end: null,
  status: '',
  date: [null, null],
  name: '',
  description: '',
  url: '',
  newUrl: '',
  search: '',
  includeSubfolder: false,
  loginName: '',
  marketScope: '',
  companyName: '',
  companyType: '',
};

const reducer = (
  state: SearchState,
  action: {
    type: string;
    status?: string;
    date?: [moment.Moment | null, moment.Moment | null];
    begin?: string;
    end?: string;
    name?: string;
    description?: string;
    url?: string;
    newUrl?: string;
    search?: string;
    includeSubfolder?: boolean;
    loginName?: string;
    marketScope?: string;
    companyName?: string;
    companyType?: string;
  }
) => {
  const {
    type,
    status,
    date,
    begin,
    end,
    name,
    description,
    url,
    newUrl,
    search,
    includeSubfolder,
    loginName,
    marketScope,
    companyName,
    companyType,
  } = action;

  switch (type) {
    case ACTIONS.UPDATE_STATUS: {
      return { ...state, ...{ status: status || '' } };
    }

    case ACTIONS.UPDATE_PUBLISH_DATE: {
      return {
        ...state,
        ...{
          date: date || [null, null],
          begin: begin || null,
          end: end || null,
        },
      };
    }

    case ACTIONS.UPDATE_NAME: {
      return { ...state, ...{ name: name || '' } };
    }

    case ACTIONS.UPDATE_DESCRIPTION: {
      return { ...state, ...{ description: description || '' } };
    }

    case ACTIONS.UPDATE_URL: {
      return { ...state, ...{ url: url || '' } };
    }

    case ACTIONS.UPDATE_NEW_URL: {
      return { ...state, ...{ newUrl: newUrl || '' } };
    }

    case ACTIONS.UPDATE_SEARCH: {
      return { ...state, ...{ search: search || '' } };
    }

    case ACTIONS.UPDATE_INCLUDE_SUBFOLDER: {
      return { ...state, ...{ includeSubfolder: includeSubfolder || false } };
    }

    case ACTIONS.UPDATE_LOGIN_NAME: {
      return { ...state, ...{ loginName: loginName || '' } };
    }

    case ACTIONS.UPDATE_MARKET_SCOPE: {
      return { ...state, ...{ marketScope: marketScope || '' } };
    }

    case ACTIONS.UPDATE_COMPANY_NAME: {
      return { ...state, ...{ companyName: companyName || '' } };
    }

    case ACTIONS.UPDATE_COMPANY_TYPE: {
      return { ...state, ...{ companyType: companyType || '' } };
    }

    case ACTIONS.RESET:
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export interface SearchState {
  begin: string | null;
  end: string | null;
  status: string;
  date: [moment.Moment | null, moment.Moment | null];
  name: string;
  description: string;
  url: string;
  newUrl: string;
  search: string;
  includeSubfolder: boolean;
  loginName: string;
  marketScope: string;
  companyName: string;
  companyType: string;
}

export const useSearch = (): useSearchData => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { queryParam, handleQueryParam } = useSearchContext();
  const handleStatusChange = (status: string): void => {
    dispatch({ type: ACTIONS.UPDATE_STATUS, status: status });
  };

  const handleSearch = () => {
    const param = filterParam(state);
    handleQueryParam({ ...param, page: 1 });
  };

  const handleReset = () => {
    dispatch({ type: ACTIONS.RESET });
  };

  const handleDateChange = (values: any, formatString: [string, string]) => {
    const begin = formatString[0];
    const end = formatString[1];
    dispatch({
      type: ACTIONS.UPDATE_PUBLISH_DATE,
      date: values,
      begin,
      end,
    });
  };

  const handleCalendarChange = (
    values: any,
    formatString: [string, string]
  ) => {
    if (values === null) return;
    if (values[0] && values[1]) {
      const begin = formatString[0];
      const end = formatString[1];
      dispatch({
        type: ACTIONS.UPDATE_PUBLISH_DATE,
        date: [values[0], values[1]],
        begin,
        end,
      });
    }
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({ type: ACTIONS.UPDATE_NAME, name: event.target.value });
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({
      type: ACTIONS.UPDATE_DESCRIPTION,
      description: event.target.value,
    });
  };

  const handleUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({ type: ACTIONS.UPDATE_URL, url: event.target.value });
  };

  const handleNewUrlChange = (value: string): void => {
    dispatch({ type: ACTIONS.UPDATE_NEW_URL, newUrl: value });
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({ type: ACTIONS.UPDATE_SEARCH, search: event.target.value });
  };

  const handleIncludeSubfolderChange = (event: CheckboxChangeEvent) => {
    dispatch({
      type: ACTIONS.UPDATE_INCLUDE_SUBFOLDER,
      includeSubfolder: event.target.checked,
    });
  };

  const handleLoginNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({
      type: ACTIONS.UPDATE_LOGIN_NAME,
      loginName: event.target.value,
    });
  };

  const handleMarketScopeChange = (value: string): void => {
    dispatch({ type: ACTIONS.UPDATE_MARKET_SCOPE, marketScope: value });
  };

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({
      type: ACTIONS.UPDATE_COMPANY_NAME,
      companyName: event.target.value,
    });
  };

  const handleCompanyTypeChange = (value: string): void => {
    dispatch({ type: ACTIONS.UPDATE_COMPANY_TYPE, companyType: value });
  };

  const filterParam = (param: SearchState) => {
    const result: { [key: string]: any } = {};
    _.forIn(param, (value, key) => {
      const keys = Object.keys(queryParam);
      if (keys.includes(key)) {
        result[key] = value === '' || value === false ? null : value;
      }
    });
    return result;
  };

  return {
    date: state.date,
    status: state.status,
    name: state.name,
    description: state.description,
    url: state.url,
    newUrl: state.newUrl,
    search: state.search,
    includeSubfolder: state.includeSubfolder,
    loginName: state.loginName,
    companyName: state.companyName,
    marketScope: state.marketScope,
    companyType: state.companyType,
    handleCalendarChange,
    handleDateChange,
    handleStatusChange,
    handleNameChange,
    handleDescriptionChange,
    handleUrlChange,
    handleNewUrlChange,
    handleSearchChange,
    handleIncludeSubfolderChange,
    handleLoginNameChange,
    handleMarketScopeChange,
    handleCompanyNameChange,
    handleCompanyTypeChange,
    handleSearch,
    handleReset,
  };
};
