import { NotificationType, useNotification } from '@helpers/use-notification';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { CustomService } from './services/custom.service';
import { LanguageService } from './services/language.service';

const { open } = useNotification();

interface ErrorMessage {
  error: string;
  message: string;
  path: string;
  status: string;
}

interface JWTToken {
  token: string;
  refreshToken: string;
}

let refreshTokenInProgress = false;
// Refresh Token Subject tracks the current token, or is null if no token is currently
// available (e.g.  refresh pending).
export const refreshToken$: BehaviorSubject<
  string | null
> = new BehaviorSubject<string | null>(null);

export const axiosRequestConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': LanguageService.getLanguage(),
  },
  proxy: false,
};

export const instance: AxiosInstance = axios.create(axiosRequestConfig);

instance.interceptors.request.use(
  (config) => {
    const jwtToken = AuthService.getToken();
    const roleId = AuthService.getRoleId();
    const menuId = AuthService.getSubMenuId();

    if (jwtToken) {
      config.headers['Authorization'] = `Bearer ${jwtToken}`;
      if (
        ![`${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`].includes(
          config.url || ''
        )
      ) {
        config.headers['x-role-id'] = `${roleId}`;
        config.headers['x-menu-id'] = `${menuId}`;
      }
    }
    return config;
  },
  (error) => {
    console.error('error ', error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    const {
      config: { method = '', url = '' },
    } = response;
    const tokenUrl = [
      '/refreshToken',
      `${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
    ];
    if (tokenUrl.includes(url)) return response;
    handleNotifyMessage(method, NotificationType.SUCCESS);
    return response;
  },
  (error: AxiosError<ErrorMessage>) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const { response, config } = error;
    const { method = '', url = '' } = config;
    const status = response ? response.status : 0;
    if (!method) return Promise.reject(error);
    if ([401, 403].includes(status) && url === '/refreshToken') {
      window.location.replace(process.env.REACT_APP_SETDD_URL || '');
    }
    if (status === 401) {
      return new Promise((resolve) => {
        setRefreshToken(error).subscribe({
          next: () => {
            resolve(instance(error.config));
          },
        });
      });
    }
    handleNotifyMessage(method, NotificationType.ERROR, response?.data.message);
    return Promise.reject(error);
  }
);

const handleNotifyMessage = (
  method: string,
  type: NotificationType,
  message?: string
) => {
  const extMsg =
    type === NotificationType.SUCCESS ? 'Successfully' : 'Failed!!';
  if (['post', 'put'].includes(method || '')) {
    open({ type, description: !message ? `Save ${extMsg}` : message });
  } else if (method === 'delete') {
    open({ type, description: `Delete ${extMsg}` });
  }
};

const setRefreshToken = (error: AxiosError) => {
  if (refreshTokenInProgress) {
    return refreshToken$.pipe(
      filter((result) => result !== null),
      take(1),
      switchMap(() => of(refreshToken$.getValue()))
    );
  } else {
    refreshTokenInProgress = true;
    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
    refreshToken$.next(null);
    AuthService.removeToken();
    return getRefreshToken(error.config).pipe(
      switchMap((response: JWTToken) => {
        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
        // for the next time the token needs to be refreshed
        const { token, refreshToken } = response;
        AuthService.setToken(token);
        AuthService.setRefreshToken(refreshToken);
        refreshTokenInProgress = false;
        refreshToken$.next(token);
        return of(token);
      })
    );
  }
};

const getRefreshToken = (config: AxiosRequestConfig): Observable<JWTToken> => {
  const refreshToken = AuthService.getRefreshToken();
  const roleId = AuthService.getRoleId();
  const menuId = AuthService.getSubMenuId();
  config.headers['Authorization'] = `Bearer ${refreshToken}`;
  config.headers['x-role-id'] = `${roleId}`;
  config.headers['x-menu-id'] = `${menuId}`;
  return CustomService.createData('/refreshToken', null, config.headers);
};

export default instance;
