import { useDownloadConfigContext } from '@contexts/DownloadConfigProverder';
import { Path } from '@core/@models/DirectoryDownloadConfigModel';
import { CustomService } from '@core/services/custom.service';
import { NotificationType, useNotification } from '@helpers/use-notification';
import { Types } from '@reducers/DownloadConfigReducer';
import { Button, Col, Form, Input, message, Row, Space } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { SEPERATOR } from 'pages/directory-service/DirectoryTreePage';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 18 },
};

message.config({
  duration: 5,
  maxCount: 1,
  top: 120,
});

export type ServerPathFormType = {
  currentPath: string;
  fileName: string;
  includeSubfolder: boolean;
  delete: boolean;
};

export const ServerPathForm: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { open } = useNotification();
  const [form] = Form.useForm();
  const { state, dispatch } = useDownloadConfigContext();

  useEffect(() => {
    if (!state.path) return;
    form.setFieldsValue({
      currentPath: state.path,
    });
    return () => {
      message.destroy();
    };
  }, [state.path]);

  const isDuplicatingData = (path: string): boolean => {
    const result = state.data.paths.find((v) => v.path === path);
    return !!result;
  };

  const onFinish = (val: ServerPathFormType) => {
    const fileName = val.fileName ? `${SEPERATOR}${val.fileName}` : '';
    const path: Path = {
      ...val,
      path: `${val.currentPath}${fileName}`,
      validPath: true,
      delete: false,
    };

    if (isDuplicatingData(path.path)) {
      const errorMessage = t('errorMessage.duplicatedDirectoryPath', {
        labelName: t(`${path.path}`),
      });
      open({
        type: NotificationType.ERROR,
        description: errorMessage,
        disableDate: true,
      });
      return;
    }

    CustomService.getData<boolean>(
      `/directory-download-services/validate?path=${path.path}`
    ).subscribe({
      next: (valid: boolean) => {
        path.validPath = valid;
        dispatch({ type: Types.AddPath, payload: { path } });
        form.resetFields();
      },
    });
  };

  return (
    <Form
      form={form}
      name="server-path-form"
      {...formItemLayout}
      onFinish={onFinish}
      autoComplete="off"
      preserve={false}
    >
      <Item label={t('currentPath')} style={{ marginBottom: 8 }}>
        <Row>
          <Col flex="auto">
            <Form.Item
              noStyle
              name="currentPath"
              label={t('currentPath')}
              rules={[{ required: true }, { max: 200 }, { whitespace: true }]}
            >
              <Input.TextArea
                size="small"
                autoSize
                placeholder={t('currentPath')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Item>

      <Item
        name="fileName"
        label={t('fileName')}
        rules={[{ max: 200 }]}
        style={{ marginBottom: 8 }}
      >
        <Input size="small" placeholder={t('fileName')} />
      </Item>
      <Item
        label={t('includeSubdirectory')}
        style={{ display: 'flex', marginBottom: 8 }}
      >
        <Item name="includeSubfolder" valuePropName="checked" noStyle>
          <Checkbox />
        </Item>
        <Space style={{ float: 'right' }}>
          <Button
            className="custom-btn"
            data-testid="add"
            type="primary"
            htmlType="submit"
            style={{ float: 'right', width: 60 }}
            size="small"
          >
            {t('button.add')}
          </Button>
        </Space>
      </Item>
    </Form>
  );
};
