import { usePermissionContext } from '@contexts/PermissionProvider';
import { filterOption } from '@helpers/utils';
import { Form, Input, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
export const UserForm: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { state } = usePermissionContext();
  return (
    <>
      <Item name="name" label={t('loginName')}>
        <Input placeholder={`${t('loginName')}...`} />
      </Item>
      <Item name="roleId" label={t('currentRole')}>
        <Select
          filterOption={filterOption}
          options={state.roles}
          showSearch
          placeholder={`${t('currentRole')}...`}
          allowClear={true}
        />
      </Item>
      <Item name="companyType" label={t('companyRole')}>
        <Select
          placeholder={`${t('companyRole')}...`}
          filterOption={filterOption}
          options={state.companyRoles}
          showSearch
          allowClear={true}
        />
      </Item>
    </>
  );
};
