import { FolderType } from '@core/@models/DirectoryServiceModel';
import React, { useState } from 'react';
export interface DropDownToggleProps {
  id?: React.Key;
  fetchData: (param: { folderId: number; key: React.Key }) => void;
  relativeKey: string;
  parentId?: React.Key;
  folderType?: FolderType;

  actions: string[];
  link?: string;
  name?: string;
  handleDownloadFile?: (id: number, name: string) => void;
}

export interface DropDownDetail {
  ref: HTMLButtonElement | null;
  data: DropDownToggleProps;
}

interface DropdownProviderProps {
  item?: DropDownDetail;
  setItem: (item?: DropDownDetail) => void;
}

export const DropdownContext = React.createContext<
  DropdownProviderProps | undefined
>(undefined);

export const useDropdownContext = (): DropdownProviderProps => {
  const dropdownContext = React.useContext(DropdownContext);
  if (!dropdownContext) {
    throw new Error(
      'useDropdownContext must be used within the DropdownContext.Provider'
    );
  }
  return dropdownContext;
};

export const DropdownProvider: React.FC = ({ children }) => {
  const [item, setItem] = useState<DropDownDetail | undefined>(undefined);

  return (
    <DropdownContext.Provider value={{ item, setItem }}>
      {children}
    </DropdownContext.Provider>
  );
};
