import { useAuthContext } from '@contexts/AuthProvider';
import { SearchProvider } from '@contexts/SearchProvider';
import {
  DownloadConfigQueryParam,
  DownloadConfigResponseBody,
} from '@core/@models/DownloadConfigModel';
import React, { useEffect, useState } from 'react';
import { ConfigList } from './ConfigList';
import { SearchForm } from './SearchForm';

export const DirectoryDownloadConfig: React.FC = () => {
  const {
    isReadOnly,
    defaultMenu: { subMenuId },
  } = useAuthContext();

  const initialQueryParam: DownloadConfigQueryParam = {
    name: null,
    size: 15,
    page: 1,
  };

  const [initialParam, setInitailParam] = useState<DownloadConfigQueryParam>(
    initialQueryParam
  );

  useEffect(() => {
    setInitailParam(initialQueryParam);
  }, [subMenuId]);

  return (
    <SearchProvider<DownloadConfigQueryParam> initialParam={initialParam}>
      {isReadOnly && <SearchForm />}
      <ConfigList<DownloadConfigResponseBody> />
    </SearchProvider>
  );
};
