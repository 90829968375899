import styled from 'styled-components';
import { useDataContext } from '@contexts/DataContext';
import { Card, Col, Input, Row, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { filterOption } from '@helpers/utils';

export const TYPE = {
  INPUT: 'input',
  DROPDOWN: 'dropdown',
};

export interface FieldProperties {
  labelName: string;
  name: string;
  type: string;
  option?: any;
}
export interface SearchFormProps {
  fieldProps: FieldProperties[];
}

export const Search: React.FC<SearchFormProps> = ({ fieldProps }) => {
  const { t } = useTranslation();
  const { onSetQuery } = useDataContext();

  const handleChange = (fieldName: string, event: React.BaseSyntheticEvent) => {
    const { value } = event.target;
    onSetQuery({ [fieldName]: value });
  };

  const handleSelectChange = (fieldName: string, value: SelectValue) => {
    onSetQuery({ [fieldName]: value });
  };

  const handleSearch = (fieldName: string, value: string) => {
    onSetQuery({ [fieldName]: value });
  };

  return (
    <Card bodyStyle={{ padding: '5px 15px 14px 15px' }} className="search-form">
      <Row>
        {fieldProps.map((f) => {
          switch (f.type) {
            case TYPE.INPUT: {
              return (
                <Col flex="100">
                  <Wrapper>
                    <Label>{t(f.labelName)}</Label>
                    <Input.Search
                      data-testid={f.name + 'Search'}
                      placeholder={`${t(f.labelName)}...`}
                      name="name"
                      onChange={(e) => handleChange(f.name, e)}
                      allowClear={true}
                      onSearch={(value) => handleSearch(f.name, value)}
                    />
                  </Wrapper>
                </Col>
              );
            }
            case TYPE.DROPDOWN: {
              return (
                <Col flex="100">
                  <Wrapper>
                    <Label>{t(f.labelName)}</Label>
                    <Select
                      data-testid={f.name + 'Search'}
                      style={{ width: '100%' }}
                      onChange={(e) => handleSelectChange(f.name, e)}
                      placeholder={`${t('common:selectAll')}...`}
                      allowClear
                      options={f.option}
                      showSearch
                      optionFilterProp="options"
                      filterOption={filterOption}
                    ></Select>
                  </Wrapper>
                </Col>
              );
            }
          }
        })}
      </Row>
    </Card>
  );
};

export const Wrapper = styled.div`
  display: 'flex';
  flexdirection: 'column';
  margin: 0px 5px;
`;

export const Label = styled.label`
  font-weight: 500;
`;
