import { DataNode, EventDataNode } from 'antd/lib/tree';

export interface Pagable {
  totalElements: number;
}

export interface DirectoryServiceResponse extends Pagable {
  content: DirectoryServiceResponseBody[];
}

export interface DirectorySerivceListProps {
  queryParam: DirectoryServiceParam;
}

export interface DirectoryServiceResponseBody {
  id: number;
  name: string;
  description: string;
  link: string;
  modified: string;
  size: string;
  ownerType: string;
  permissionType: string;
  actions: string[];
  disabled: boolean;
  path: string;
}

export interface DirectoryServiceParam {
  folderId: number | null;
  search: string | null;
  begin: string | null;
  end: string | null;
  page: number | undefined;
  size: number | undefined;
  sort: string | null;
}

export interface DirectoryServiceSearchProps {
  handleQueryParam: (query: Partial<DirectoryServiceParam>) => void;
}

export interface DirectoryConfigForm {
  companyRoles: { key: string; value: string }[];
  times: { name: string; id: number }[];
  permissionTypes: string[];
}

export type FolderType = 'NORMAL' | 'TEMPLATE' | 'CHILD_TEMPLATE' | 'DYNAMIC';

export interface DirectoryServiceFolderRequest {
  name: string;
  description: string;
  parentId: number;
  dynamicFolder: boolean;
  companyRoles: string[];
  patternName: string;
  permissionType: string;
  patternUpload: string;
  timeId: number | null;
  patternValidation: string;
}

export interface DirectoryServiceFolderResponse
  extends DirectoryServiceFolderRequest {
  id: number;
}

export interface DirectoryServiceLinkRequest {
  name: string;
  description: string;
  parentId: number;
  id: number;
  link: string;
}
export interface IDirectoryTree extends DataNode, EventDataNode {
  id: number;
  name: string;
  description: string;
  fileLimitSize: string;
  permissionType: string;
  ownerType: string;
  actions: string[];
  folderType: FolderType;
  key: React.Key;
  modified: string | null;
}

export interface IDirectoryList extends DirectoryServiceResponseBody {
  parentId: number;
  folderType: FolderType;
}

export enum Action {
  CreateFolder = 1,
  UploadFile,
  CreateLink,
  DeleteMultipleFiles,
  Edit,
  Delete,
  Copy,
  Cut,
  Paste,
  Permission,
  AssignOwner,
  Properties,
  Open,
  Download,
  EditRootFolder,
  RootDelete,
  RootPermission,
  AssignRootOwner,
}

export interface DeleteMultipleFilesParam {
  name: string | null;
  begin: string | null;
  end: string | null;
  includeSubfolder: boolean;
  page?: number;
  size?: number;
}

export interface DeleteMulipleFilesRequest {
  name: string | null;
  begin: string | null;
  end: string | null;
  includeSubfolder: boolean;
}

export interface UploadFileResponse {
  id: number;
  name: string;
  description: string;
  parentId: number;
  sendEmail: boolean;
}

export interface PropertiesResponse {
  name: string;
  description: string;
  type: 'Folder' | 'File';
  permissionType: 'READ_WRITE' | 'READ_ONLY';
  createDate: string;
  modifiedDate: string;
  creator: string;
}
