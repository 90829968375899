import { Card, Row, Col, Input } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch } from '@helpers/use-search';
import { Label, Wrapper } from '@components/Search';
import { SearchButton } from '@components/SearchButton';
import { useAuthContext } from '@contexts/AuthProvider';

export const SearchForm: React.FC = () => {
  const { t } = useTranslation();
  const { handleNameChange, handleSearch, handleReset, name } = useSearch();
  const {
    defaultMenu: { subMenuId },
  } = useAuthContext();

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;
    handleReset();
    return () => {
      mounted = false;
    };
  }, [subMenuId]);

  return (
    <Card
      className="search-form"
      title={t('common:filter')}
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row>
        <Col flex="auto">
          <Wrapper>
            <Label>{t(`common:loginName`)}</Label>
            <Input
              name="name"
              style={{ width: '100%' }}
              placeholder={`${t('common:loginName')}...`}
              onChange={handleNameChange}
              allowClear={true}
              value={name}
            />
          </Wrapper>
        </Col>
      </Row>
    </Card>
  );
};
