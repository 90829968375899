import { QueryParam } from '@core/@models/Types';
import React, { useEffect } from 'react';
import { PropsWithChildren, useState } from 'react';

export interface SearchContextProps<T extends QueryParam> {
  queryParam: T;
  handleQueryParam: (query: T) => void;
}

const SearchContext = React.createContext<SearchContextProps<any> | undefined>(
  undefined
);

const SearchProvider = <T extends QueryParam>({
  children,
  initialParam,
}: PropsWithChildren<{ initialParam: T }>): React.ReactElement => {
  const [queryParam, setQueryParam] = useState<T>(initialParam);

  useEffect(() => {
    setQueryParam(initialParam);
  }, [initialParam]);

  const handleQueryParam = (query: T) => {
    setQueryParam({ ...queryParam, ...query });
  };

  const value = {
    queryParam,
    handleQueryParam,
  };
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

const useSearchContext = () => {
  const searchContext = React.useContext(SearchContext);
  if (!searchContext) {
    throw new Error(
      'useSearchContext must be used within the SearchContext.Provider'
    );
  }
  return searchContext;
};

export { SearchProvider, useSearchContext };
