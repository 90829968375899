export declare type DirectoryPermissionType =
  | 'READ_WRITE'
  | 'READ_ONLY'
  | 'NOT_READ';

export interface DirectoryPermissionDetail {
  permissionId: number;
  permissionType: DirectoryPermissionType;
  deletable: boolean;
  key: string;
}

export interface UserPermission extends DirectoryPermissionDetail {
  loginName: string;
  nameTh: string;
  nameEn: string;
  surnameTh: string;
  surnameEn: string;
  permissionOf: PermisssionOf.Users;
  ownerId?: number;
}

export interface CompanyPermission extends DirectoryPermissionDetail {
  code: string;
  companyType: string;
  nameTh: string;
  nameEn: string;
  permissionOf: PermisssionOf.Companies;
  ownerId?: number;
}

export interface RolePermission extends DirectoryPermissionDetail {
  name: string;
  descriptionTh: string;
  descriptionEn: string;
  permissionOf: PermisssionOf.Roles;
  ownerId?: number;
}

export interface DirectoryConfigData {
  permissionTypes: DirectoryPermissionType[];
  users: Omit<
    UserPermission & { id: number },
    keyof DirectoryPermissionDetail
  >[];
  companies: Omit<
    CompanyPermission & { id: number },
    keyof DirectoryPermissionDetail
  >[];
  roles: Omit<
    RolePermission & { id: number },
    keyof DirectoryPermissionDetail
  >[];
  companyRoles: RoleOptionType[];
}

export type RoleOptionType = { key: string; value: string };
export interface DirectoryPermissionResponse extends DirectoryConfigData {
  ownerPermissions: UserPermission[];
  userPermissions: UserPermission[];
  companyPermissions: CompanyPermission[];
  rolePermissions: RolePermission[];
}

export type PermissionTableOf =
  | UserPermission
  | CompanyPermission
  | RolePermission;

export interface DirectoryPermissionRequestDetail {
  permissionId?: number;
  permissionType: DirectoryPermissionType;
  delete?: boolean;
  ownerId?: number;
}

export interface DirectoryPermissionRequest {
  userPermissions: DirectoryPermissionRequestDetail[];
  companyPermissions: DirectoryPermissionRequestDetail[];
  rolePermissions: DirectoryPermissionRequestDetail[];
}

export interface PermissionTableField {
  key: string;
  permissionType: DirectoryPermissionType;
  fullName: string;
  deletable: boolean;

  permissionId?: number;
  ownerId?: number;
  delete?: boolean;

  loginName?: string;
  code?: string;

  permissionOf?: string;
}

export interface PermissionTable {
  users: PermissionTableField[];
  owners: PermissionTableField[];
  roles: PermissionTableField[];
  companies: PermissionTableField[];
}

export type OptionType = { label: string; value: string };

export type ColumsField = {
  title: string;
  dataIndex: string;
  width: string;
  options: OptionType[];
  editable: boolean;
  permissionOf: keyof PermissionTable;
  handlable: boolean;
  render: (_: unknown, record: PermissionTableField) => React.ReactNode;
};

export enum PermisssionOf {
  Owners = 'owners',
  Users = 'users',
  Roles = 'roles',
  Companies = 'companies',
}
export type RoleSearchResponse = { config: 'roles'; id: number } & Omit<
  RolePermission,
  keyof DirectoryPermissionDetail
>[];

export type UserSearchResponse = { config: 'users'; id: number } & Omit<
  UserPermission,
  keyof DirectoryPermissionDetail
>[];

export type PermissionSearchResponse = RoleSearchResponse | UserSearchResponse;
