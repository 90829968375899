import React from 'react';
import { DirectoryPreviewSearch } from './DirectoryPreviewSearch';
import { DirectoryPreviewQueryParam } from '@core/@models/DirectoryPreviewModel';
import { DirectoryPreviewUserList } from './DirectoryPreviewUserList';
import Layout, { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { DirectoryTreePage } from './DirectoryTreePage';
import { SearchProvider } from '@contexts/SearchProvider';

export const DirectoryPreview: React.FC = () => {
  const initialParam: DirectoryPreviewQueryParam = {
    loginName: null,
    marketScope: null,
    companyName: null,
    companyType: null,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<DirectoryPreviewQueryParam> initialParam={initialParam}>
      <DirectoryPreviewSearch />
      <Layout style={{ marginTop: '20px' }} className="directory-preview">
        <Sider
          theme={'light'}
          width={390}
          style={{ borderRight: '1px solid #FCB03480' }}
        >
          <DirectoryPreviewUserList />
        </Sider>
        <Content style={{ backgroundColor: 'white' }}>
          <DirectoryTreePage />
        </Content>
      </Layout>
    </SearchProvider>
  );
};
