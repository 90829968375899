import { DeleteOutlined } from '@ant-design/icons';
import { ButtonAdd } from '@components/ButtonAdd';
import { ConfirmModal, ModalCmp } from '@components/ModalCmp';
import { useAuthContext } from '@contexts/AuthProvider';
import { DownloadConfigResponseBody } from '@core/@models/DownloadConfigModel';
import { DataResponseBody } from '@core/@models/Types';
import { CustomService } from '@core/services/custom.service';
import { useDataFetchingPagination } from '@helpers/use-data-fetching-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ButtonProps } from 'antd/lib/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadConfigForm } from './DownloadConfigForm';

export const customLayoutBtnAdd = {
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '0px',
};

export const DownloadConfigList: React.FC = () => {
  const {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
  } = useDataFetchingPagination('/download-services/config/list');

  const { isReadOnly } = useAuthContext();
  const { t } = useTranslation(['common']);
  const [configName, setConfigName] = useState<string | null>(null);
  const { visible, hideModal, showModal } = useModalManagement();
  const handleClick = (reqConfigName: string | null) =>
    setConfigName(reqConfigName);

  const showConfirmModal = (reqConfigName: string) =>
    ConfirmModal({ onOk: () => onDelete(reqConfigName) });

  const onDelete = (reqConfigName: string) => {
    CustomService.deleteData(
      `/download-services/config?configName=${reqConfigName}`
    ).subscribe({
      next: () => fetchData(),
    });
  };

  const getGroupView = (groups: any[], field: string): React.ReactElement => (
    <>
      {groups.map((group) =>
        group.files.map((_: any, index: number) => (
          <p key={`${group[field]}${index}`} style={{ margin: '0px' }}>
            {index === 0 ? (
              field !== 'includeSubfolder' ? (
                group[field] || <>&emsp;</>
              ) : (
                getincludeSubfolderView(group[field])
              )
            ) : (
              <>&emsp;</>
            )}
          </p>
        ))
      )}
    </>
  );

  const getincludeSubfolderView = (includeSubfolder: boolean): string =>
    includeSubfolder ? 'Use' : 'Not Use';

  const getItemOfFileView = (record: DataResponseBody): React.ReactElement => (
    <>
      {(record as DownloadConfigResponseBody).fileGroups.map((fileGroup) =>
        fileGroup.files.map((file) => (
          <p key={file.id} style={{ margin: '0px' }}>
            {file.name}
          </p>
        ))
      )}
    </>
  );

  const getDeleteButtonView = (
    record: DataResponseBody
  ): React.ReactElement => (
    <Tooltip title={t('button.delete')}>
      <Button
        type="link"
        style={{ height: '20px' }}
        icon={<DeleteOutlined />}
        size="small"
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          event.stopPropagation();
          const r = record as DownloadConfigResponseBody;
          showConfirmModal(r.configName);
        }}
        disabled={isReadOnly}
      />
    </Tooltip>
  );

  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('loginName'),
      dataIndex: 'loginName',
      width: '13%',
    },
    {
      title: t('role'),
      dataIndex: 'roleName',
      width: '13%',
    },
    {
      title: t('configName'),
      dataIndex: 'configName',
      width: '13%',
    },
    {
      title: t('group'),
      render: (_, record: any) => getGroupView(record.fileGroups, 'name'),
      width: '13%',
    },
    {
      title: t('itemOfFile'),
      render: getItemOfFileView,
      width: '13%',
    },
    {
      title: t('localPath'),
      render: (_, record: any) => getGroupView(record.fileGroups, 'localPath'),
      width: '13%',
    },
    {
      title: t('includeSubdirectory'),
      width: '13%',
      render: (_, record: any) =>
        getGroupView(record.fileGroups, 'includeSubfolder'),
    },
    {
      title: 'Action',
      width: '9%',
      align: 'center',
      className: 'last-column',
      render: getDeleteButtonView,
    },
  ];

  const handleSelectRow = (record: any) => {
    return {
      onClick: () => {
        handleClick(record.configName);
        showModal();
      },
    };
  };

  const buttonProps: ButtonProps = {
    disabled: isReadOnly,
  };

  return (
    <div
      style={
        isReadOnly
          ? undefined
          : { backgroundColor: 'white', borderRadius: '8px' }
      }
    >
      <div style={isReadOnly ? undefined : { padding: '10px 10px 0px' }}>
        <ButtonAdd
          handleClick={() => handleClick(null)}
          showModal={showModal}
          buttonProps={buttonProps}
          layoutBtnAdd={isReadOnly ? undefined : customLayoutBtnAdd}
        />
      </div>
      <Table
        size="small"
        className={isReadOnly ? 'unselectable-table' : 'selectable-table'}
        pagination={pagination}
        onRow={isReadOnly ? undefined : handleSelectRow}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
      />
      <ModalCmp
        visible={visible}
        title={configName ? t('edit') : t('button.add')}
        handleCancel={hideModal}
        width={800}
      >
        <DownloadConfigForm
          configName={configName}
          handleCancel={hideModal}
          fetchData={fetchData}
        />
      </ModalCmp>
    </div>
  );
};
