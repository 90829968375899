import React, { useEffect, useState } from 'react';
import { ButtonSet } from '@components/ButtonSet';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
import { PermissionForm } from './Permissions/PermissionForm';
import { CustomService } from '@core/services/custom.service';
import {
  ColumsField,
  OptionType,
  PermissionTable,
  PermisssionOf,
} from '@core/@models/DirectoryPermissionModel';
import {
  DirectoryAssignOwnerResponse,
  DirectoryAssignOwnerRequest,
} from '@core/@models/DirectoryAssignOwnerModel';
import {
  convertDataType,
  modifiedData,
  renderName,
  usePermissionContext,
} from '@contexts/PermissionProvider';
import { FooterForm } from './Permissions/FooterForm';
import { RootFooterForm } from './Permissions/RootFooterForm';
import { Types } from '@reducers/PermissionReducer';

interface AssignOwnerFormProps {
  handleCancel: () => void;
  id?: React.Key;
  isRoot: boolean;
}

export const AssignOwnerForm: React.FC<AssignOwnerFormProps> = ({
  id,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<OptionType[]>([]);
  const { state, dispatch } = usePermissionContext();

  useEffect(() => {
    fetchData();
  }, []);

  const path = props.isRoot
    ? `/file-services/root/${id}/${PermisssionOf.Owners}`
    : `/file-services/folder/${id}/${PermisssionOf.Owners}`;
  const fetchData = () => {
    setLoading(true);
    CustomService.getData<DirectoryAssignOwnerResponse>(path)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DirectoryAssignOwnerResponse) => {
          initialConfigData(result);
          initialData(result);
        },
      });
  };

  const initialData = (result: DirectoryAssignOwnerResponse) => {
    const permissionTypes = [
      {
        label: t(`READ_WRITE`),
        value: 'READ_WRITE',
      },
    ];
    const { companyRoles: cr = [], roles: ro = [] } = result;
    const companyRoles = cr.map((r) => ({ label: r.value, value: `${r.key}` }));
    const roles = ro.map((r) => ({ label: r.name, value: `${r.id}` }));
    const permissionTable: PermissionTable = {
      owners: convertDataType(
        result.ownerPermissions.map((v) => ({
          ...v,
          permissionOf: PermisssionOf.Users,
        }))
      ),
      users: [],
      companies: [],
      roles: [],
    };
    dispatch({
      type: Types.Initial,
      payload: { data: permissionTable, permissionTypes, companyRoles, roles },
    });
  };

  const initialConfigData = (result: DirectoryAssignOwnerResponse) => {
    const { users: u = [] } = result;
    const userList = u.map((user) => ({
      label: `${user.loginName} - ${user.nameTh} ${user.surnameTh}`,
      value: `${user.id}`,
    }));
    if (userList.length === 0) return;
    setUsers(userList);
  };

  const owners: ColumsField = {
    title: 'username',
    dataIndex: 'key',
    width: '70%',
    editable: true,
    options: users,
    permissionOf: PermisssionOf.Owners,
    handlable: true,
    render: renderName,
  };

  const handleSubmit = () => {
    //TODO: refactor use in reducer
    const data: DirectoryAssignOwnerRequest = {
      ownerPermissions: [
        ...modifiedData(state.dataSource[PermisssionOf.Owners]),
        ...state.deletedData[PermisssionOf.Owners],
      ],
    };
    CustomService.createData<DirectoryAssignOwnerRequest, null>(
      `/file-services/folder/${id}/${PermisssionOf.Owners}`,
      data
    ).subscribe({
      next: () => {
        fetchData();
      },
    });
  };

  const renderFooter = props.isRoot ? (
    <RootFooterForm {...owners} />
  ) : (
    <FooterForm {...owners} />
  );

  return (
    <>
      <PermissionForm
        loading={loading}
        title={t(`Permission for ${PermisssionOf.Owners}`)}
        columns={owners}
        isRoot={props.isRoot || false}
      >
        {renderFooter}
      </PermissionForm>
      <ButtonSet
        handleCancel={props.handleCancel}
        loading={false}
        onClick={handleSubmit}
      />
    </>
  );
};
