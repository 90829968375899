import { DirectoryPreviewUserResponseBody } from '@core/@models/DirectoryPreviewModel';
import { DataResponseBody } from '@core/@models/Types';
import { useDataFetchingPagination } from '@helpers/use-data-fetching-pagination';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';

export interface DirectoryPreviewUserListSubject {
  userId: Key;
}

export const directoryPreviewUserListSubject$ = new Subject<DirectoryPreviewUserListSubject>();

export const DirectoryPreviewUserList: React.FC = () => {
  const { t } = useTranslation();

  const {
    data,
    loading,
    pagination,
    handleTableChange,
  } = useDataFetchingPagination('/directory-previews/user');

  const columns: ColumnsType<DataResponseBody> = [
    {
      title: t('common:name'),
      dataIndex: 'loginName',
      className: 'last-column',
    },
  ];

  const handleSelectRow = (record: DataResponseBody) => {
    return {
      onClick: () => {
        directoryPreviewUserListSubject$.next({
          userId: (record as DirectoryPreviewUserResponseBody).id,
        });
      },
    };
  };

  return (
    <Table
      className="selectable-table no-padding"
      size="small"
      pagination={pagination}
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      onRow={handleSelectRow}
    ></Table>
  );
};
