import { IDirectoryTree } from '@core/@models/DirectoryServiceModel';
import { CustomService } from '@core/services/custom.service';
import { isArray } from 'lodash';
import { SEPERATOR } from 'pages/directory-service/DirectoryTreePage';
import { DataNode, EventDataNode } from 'rc-tree/lib/interface';
import {
  Dispatch,
  Key,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';

export interface TreeManagement {
  treeRef: MutableRefObject<any>;
  cacheDataRef: MutableRefObject<IDirectoryTree[] | null | undefined>;
  cacheGetDataRef$: MutableRefObject<
    Observable<IDirectoryTree[] | null> | null | undefined
  >;
  folderIdRef: MutableRefObject<Key>;
  loading: boolean;
  prevSelectedKeyRef: MutableRefObject<Key>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  addRelativeKey: ({
    parentKey,
    data,
  }: {
    parentKey?: Key;
    data: IDirectoryTree[];
  }) => IDirectoryTree[];
  updateTreeData: (
    list: IDirectoryTree[],
    key: Key,
    children: DataNode[]
  ) => IDirectoryTree[];
  generateSelectedExpanedNode: (
    expandedKeys: React.Key[],
    currentSelctedKey: React.Key
  ) => void;
  getData$: (
    folderId?: number | string,
    userId?: Key
  ) => Observable<IDirectoryTree[] | null>;
  getExpandFolder$: (
    folderId: Key,
    userId?: Key
  ) => Observable<IDirectoryTree[] | null>;
  handleOnClick: (
    _: React.MouseEvent<HTMLSpanElement>,
    node: EventDataNode
  ) => void;
  addExpandedKeys: (
    expandedKeys: Key[],
    selectedKeys: Key[],
    currSelctedKey: Key
  ) => void;
  showSelectedExpanedNode: (
    expandedKeys: Key[],
    currSelctedKey: Key,
    node: EventDataNode
  ) => void;
  getCurrentPath: (
    treeData: IDirectoryTree[],
    key: Key,
    seperator?: string
  ) => string;
  onSelect: (selectedKeys: React.Key[]) => void;
  setPrevSelectedKeyRef: (keys: React.Key) => void;
  setTreeRef: (treeRef: any) => void;
}

export const useTreeManagement = (
  url: string,
  onLoadData: (eventDataNode: EventDataNode) => void
): TreeManagement => {
  const treeRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const prevSelectedKeyRef = useRef<React.Key>('');
  const folderIdRef = useRef<Key>('');
  const cacheDataRef = useRef<IDirectoryTree[] | null>();
  const cacheGetDataRef$ = useRef<Observable<IDirectoryTree[] | null> | null>();

  const getExpandFolder$ = (
    folderId: Key,
    userId?: Key
  ): Observable<IDirectoryTree[] | null> => {
    const urlWithQueryParam = userId
      ? `${url}?folderId=${folderId}&userId=${userId}`
      : `${url}?folderId=${folderId}`;

    return CustomService.getData<IDirectoryTree[]>(urlWithQueryParam).pipe(
      tap((res) => (cacheDataRef.current = res)),
      share(),
      finalize(() => {
        cacheGetDataRef$.current = of(null);
        setLoading(false);
      })
    );
  };

  const addRelativeKey = ({
    parentKey,
    data,
  }: {
    parentKey?: Key;
    data: IDirectoryTree[];
  }): IDirectoryTree[] => {
    if (!isArray(data)) return [];
    return data.map((d) => ({
      ...d,
      title: '',
      key: parentKey ? `${parentKey}-${d.id}` : `${d.id}`,
    }));
  };

  const updateTreeData = (
    list: IDirectoryTree[],
    key: Key,
    children: DataNode[]
  ): IDirectoryTree[] => {
    return list.map((node) => {
      if (node.key === key) {
        node.children = children;
      }
      if (Array.isArray(node.children)) {
        node.children = updateTreeData(
          node.children as IDirectoryTree[],
          key,
          children
        );
      }
      return node;
    });
  };

  const generateSelectedExpanedNode = (
    expandedKeys: React.Key[],
    currentSelctedKey: React.Key
  ) => {
    expandedKeys.splice(
      0,
      expandedKeys.length + 1,
      ...filterKeys(expandedKeys, `${currentSelctedKey}`)
    );

    if (treeRef.current) {
      treeRef.current.state.loadedKeys = filterKeys(
        treeRef.current.state.loadedKeys,
        `${currentSelctedKey}`
      );
    }
  };

  const filterKeys = (keys: Key[], currentSelctedKey: string): Key[] => {
    // filter เอาเฉพาะ currentSelectedKey
    // และเอาเฉพาะ key ที่ไม่ใช่ลูกของ currentSelectedKey เพราะตัวที่เป็นลูกจะ fetch เอาใหม่
    // และเอาเฉพาะตัวที่ไม่ใช่ prevSelectedKey ด้วย เพราะเมื่อมี currentSelectedKey ใหม่จะต้อง collape prevSelectedKey
    // แต่ถ้า currentSelectedKey เป็น Child ของ prevSelectedKey ก็เอา prevSelectedKey ด้วย
    return keys?.filter(
      (v) =>
        `${v}` === currentSelctedKey ||
        (!`${v}`.includes(`${currentSelctedKey}`) &&
          v !== prevSelectedKeyRef.current) ||
        (isChildPrevSelectedKey(currentSelctedKey) &&
          `${v}` === prevSelectedKeyRef.current)
    );
  };

  const isChildPrevSelectedKey = (currentSelctedKey: React.Key): boolean => {
    if (
      !prevSelectedKeyRef.current ||
      prevSelectedKeyRef.current === currentSelctedKey
    )
      return false;
    const prevSelctedNode = new RegExp(`${prevSelectedKeyRef.current}`, 'gm');
    const isChildNode = `${currentSelctedKey}`.search(/-/gm) > -1;
    const isChildPrevNode = `${currentSelctedKey}`.search(prevSelctedNode) > -1;
    return isChildNode && isChildPrevNode;
  };

  const getData$ = (
    folderId: number | string = '',
    userId?: Key
  ): Observable<IDirectoryTree[] | null> => {
    if (folderIdRef.current !== folderId) {
      folderIdRef.current = folderId;
      return getExpandFolder$(folderId, userId);
    }

    if (cacheDataRef.current) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return of(cacheDataRef.current);
    } else if (cacheGetDataRef$.current) {
      return cacheGetDataRef$.current;
    } else {
      if (!folderIdRef.current) {
        folderIdRef.current = folderId;
      }
      return getExpandFolder$(folderId, userId);
    }
  };

  const handleOnClick = (
    _: React.MouseEvent<HTMLSpanElement>,
    node: EventDataNode
  ) => {
    console.log('handleClick');
    const { expandedKeys, selectedKeys } = treeRef.current.state;
    const currSelctedKey = node.key;

    addExpandedKeys(expandedKeys, selectedKeys, currSelctedKey);
    showSelectedExpanedNode(expandedKeys, currSelctedKey, node);
  };

  const addExpandedKeys = (
    expandedKeys: Key[],
    selectedKeys: Key[],
    currSelctedKey: Key
  ) => {
    selectedKeys.splice(0, 1, currSelctedKey);
    if (expandedKeys.indexOf(currSelctedKey) === -1) {
      expandedKeys.push(currSelctedKey);
    }
  };

  const showSelectedExpanedNode = (
    expandedKeys: Key[],
    currSelctedKey: Key,
    node: EventDataNode
  ) => {
    generateSelectedExpanedNode(expandedKeys, currSelctedKey);
    delete node.children;
    // ให้เข้า loadData เฉพาะกรณีที่เคยมี children load เก็บไว้
    // เพื่อป้องกัน loadData 2 ครั้ง เนื่องจากถ้าไม่เคยมี children load เก็บไว้ จะเข้า loadData อยู่แล้ว
    if (treeRef.current?.state.keyEntities[currSelctedKey].node.children) {
      onLoadData(node);
    }
    //currSelctedKey อยู่ใน loadedKeys แต่ไม่มี children จะไม่เข้า onLoadData
    if (
      !node.children &&
      (treeRef.current?.state.loadedKeys || []).includes(currSelctedKey)
    ) {
      onLoadData(node);
    }
  };

  const getCurrentPath = (
    treeData: IDirectoryTree[],
    key: Key,
    seperator = ` ${SEPERATOR} `
  ): string => {
    const traverseKey = key.toString().split('-');
    const treeDataList: IDirectoryTree[] = [...treeData];
    let newList: IDirectoryTree[] = [];
    const result: IDirectoryTree[] = [];
    while (traverseKey.length) {
      const latestKey = (traverseKey.shift() || 0) as number;
      const tempList: IDirectoryTree[] =
        newList.length > 0 ? newList : treeDataList;
      const record = tempList.find((v) => `${v.id}` === `${latestKey}`);
      if (!record) return '';
      result.push(record);
      newList = (record.children as IDirectoryTree[]) || [];
    }
    return result.reduce(
      (acc, curr) =>
        curr.name
          ? acc
            ? `${acc}${seperator}${curr.name}`
            : `${curr.name}`
          : acc,
      ''
    );
  };

  const onSelect = (selectedKeys: React.Key[]) => {
    const key = [...selectedKeys].shift() || '';
    prevSelectedKeyRef.current = key;
  };

  const setPrevSelectedKeyRef = (key: React.Key) => {
    prevSelectedKeyRef.current = key;
  };

  const setTreeRef = (tree: any) => {
    treeRef.current = tree;
  };

  return {
    treeRef,
    cacheDataRef,
    cacheGetDataRef$,
    folderIdRef,
    loading,
    prevSelectedKeyRef,
    setLoading,
    addRelativeKey,
    updateTreeData,
    generateSelectedExpanedNode,
    getData$,
    getExpandFolder$,
    handleOnClick,
    addExpandedKeys,
    showSelectedExpanedNode,
    getCurrentPath,
    onSelect,
    setPrevSelectedKeyRef,
    setTreeRef,
  };
};
