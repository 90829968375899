import {
  ColumsField,
  DirectoryPermissionRequestDetail,
  OptionType,
  PermissionTable,
  PermissionTableField,
  PermissionTableOf,
  PermisssionOf,
} from '@core/@models/DirectoryPermissionModel';
import { LanguageService } from '@core/services/language.service';
import {
  Dispatch,
  initailState,
  PermissionReducer,
} from '@reducers/PermissionReducer';
import React, { useReducer } from 'react';

export interface PermissionFormProps {
  loading: boolean;
  title: string;
  columns: ColumsField;
  permissionTypes?: OptionType[];
  isRoot: boolean;
}

export interface PermissionContextData {
  editingKey: string;
  dataSource: PermissionTable;
  deletedData: PermissionTable;

  permissionTypes: OptionType[];
  companyRoles: OptionType[];
  roles: OptionType[];
}

export type PermissionContextProps = {
  state: PermissionContextData;
  dispatch: Dispatch;
};

export const PermissionContext = React.createContext<
  PermissionContextProps | undefined
>(undefined);

export const PermissionProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(PermissionReducer, initailState);

  const value = { state, dispatch };
  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissionContext = (): PermissionContextProps => {
  const permissionContext = React.useContext(PermissionContext);
  if (!permissionContext) {
    throw new Error(
      'usePermissionContext must be used within the PermissionContext.Provider'
    );
  }
  return permissionContext;
};

export const renderName = (
  _: unknown,
  record: PermissionTableField
): React.ReactNode => {
  return (
    <>
      {record.loginName && (
        <span style={{ width: 100, display: 'inline-block' }}>
          {record.loginName}
        </span>
      )}
      {record.fullName}
    </>
  );
};

export const modifiedData = (
  data: PermissionTableField[]
): DirectoryPermissionRequestDetail[] => {
  const newData = [...data];
  const items = newData
    .filter((item) => item.deletable)
    .map((item) => {
      const isUpdatedRecord = 'permissionId' in item && 'ownerId' in item;
      return isUpdatedRecord ? { ...item, ownerId: undefined } : item;
    });
  return items;
};

export const convertDataType = (
  data: PermissionTableOf[]
): PermissionTableField[] => {
  const lang = LanguageService.getLanguage();
  const newData = [...data];
  return newData.map((d: PermissionTableOf) => {
    const result: PermissionTableField = {
      ...d,
      key: d.deletable ? `${d.ownerId}` : `${d.permissionId}`,
      fullName: '',
    };
    switch (d.permissionOf) {
      case PermisssionOf.Users: {
        const fullNameTh = `${d.nameTh} ${d.surnameTh}`;
        const fullNameEn = `${d.nameEn} ${d.surnameEn}`;
        result.fullName =
          lang === 'th'
            ? fullNameTh
            : d.nameEn === null
            ? fullNameTh
            : fullNameEn;

        break;
      }
      case PermisssionOf.Companies: {
        result.fullName = lang === 'th' ? `${d.nameTh} ` : `${d.nameEn}`;
        break;
      }
      case PermisssionOf.Roles: {
        result.fullName = d.name;
        break;
      }
    }
    return result;
  });
};
