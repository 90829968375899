import { useAuthContext } from '@contexts/AuthProvider';
import { SearchProvider } from '@contexts/SearchProvider';
import { DownloadConfigQueryParam } from '@core/@models/DownloadConfigModel';
import { SearchForm } from 'pages/directory-download-config/SearchForm';
import React from 'react';
import { DownloadConfigList } from './DownloadConfigList';

export const DownloadConfig: React.FC = () => {
  const { isReadOnly } = useAuthContext();

  const initialParam: DownloadConfigQueryParam = {
    name: null,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<DownloadConfigQueryParam> initialParam={initialParam}>
      {isReadOnly && <SearchForm />}
      <DownloadConfigList />
    </SearchProvider>
  );
};
